<template>
  <div class="full-height">
    <div class="container-fluid main-content">
      <div class="row sub-main-header">
        <div class="col-2">
          <div class="title-box-small" style="left:40px; position: absolute; margin-top: -25px;">
            <button
              class="btn btn-outline-dark btn-sm pd-4"
              v-if="previousPole"
              @click="previousPoleClick()"
            >
              <i class="fas fa-long-arrow-left ml-2 mr-2"></i>Previous Pole
            </button>
          </div>
        </div>
        <div class="col-6 title-header" style="text-align: center;">
          <span
            tag="span"
            style="margin-left: 0px !important; font-size: 14px; font-weight: bold !important;  border-bottom: none !important;"
            v-if="selectedPole"
          >{{setPoleName(selectedPole.name)}}</span>
          <button
            class="btn btn-sm pd-4"
            :class="{'btn-success ': isCompleted, 'btn-primary ':  !isCompleted}"
            v-if="selectedPole"
            @click="setPoleComplete()"
            style="margin-left: 20px;"
          >
            <i
              :class="{'fas fa-check-circle ': isCompleted, 'fas fa-times-circle ': !isCompleted}"
              class="mr-2"
              style="margin-right: 10px"
            ></i>
            {{setPoleCompleteText(isCompleted)}}
          </button>
        </div>
        <div class="col-2 title-header" style="text-align: center;">
          <button
            class="btn btn-sm pd-4 btn-primary"
            @click="viewPoleProgress()"
            style="margin-left: 20px;"
          >
            <i class="mr-2 fas fa-align-justify" style="margin-right: 10px"></i>
            View Pole Prograss
          </button>
          <!-- dialog -->
          <custom-dialog :dialogProps="dialogProps">
            <!-- title card -->
            <span slot="card-title" class="captalize">Progress</span>
            <!-- card body -->
            <span slot="card-body">
              <Inspection @clicked="onClickChild" />
            </span>
            <!-- card action buttons -->
            <span slot="card-action">
              <v-btn text @click="dialogProps.dialog = false">Close</v-btn>
            </span>
          </custom-dialog>
        </div>
        <div class="col-2">
          <div class="title-box-small" style="right:40px; position: absolute; margin-top: -25px;">
            <button
              class="btn btn-outline-dark btn-sm pd-4"
              v-if="nextPole"
              @click="nextPoleClick()"
            >
              Next Pole
              <i class="fas fa-long-arrow-right ml-2 mr-2"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="submain-content">
        <div class="container-fluid height-full">
          <div class="row inherit-height">
            <div
              class="col-2 inherit-height box-border-full"
              style="padding-right: 0px; padding-left: 0px;"
            >
              <div>
                <div class="small-title" style="cursor: default; font-weight: bold;">
                  <span class="ml-2">Pole Details</span>
                </div>
                <div class="fluid-container" v-if="selectedPole">
                  <div class="row" style="padding:2px; ">
                    <div class="col-5">
                      <span class="span-light">Name</span>
                    </div>
                    <div class="col-7">
                      <span class="span-bold">{{setPoleName(selectedPole.name)}}</span>
                    </div>
                  </div>
                  <div class="row" style="padding:2px">
                    <div class="col-5">
                      <span class="span-light">Insp. Type</span>
                    </div>
                    <div class="col-7">
                      <select
                        class="form-control form-control-sm"
                        style="width: 100%;"
                        v-if="inspectionsType"
                        @change="changeInspectionType(selectedInspectionsType)"
                        v-model="selectedInspectionsType"
                      >
                        <option
                          v-for="inspectionType in inspectionsType"
                          :key="inspectionType.name"
                          :value="inspectionType"
                        >{{inspectionType.name}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="row mt-1" v-if="this.selectedInspectionsType.name == 'BARE'">
                    <div class="col-5">
                      <span class="span-light">Pole Type</span>
                    </div>
                    <div class="col-7">
                      <select
                        class="form-control form-control-sm"
                        style="width: 100%;"
                        v-if="poleTypes"
                        @change="changePoleType(selectedPoleType)"
                        v-model="selectedPoleType"
                      >
                        <option
                          v-for="poleType in poleTypes"
                          :key="poleType.name"
                          :value="poleType"
                        >{{poleType.name}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="row" style="padding:2px">
                    <div class="col-5">
                      <span class="span-light">Highest Delta T</span>
                    </div>
                    <div class="col-7">
                      <span
                        class="span-bold"
                      >{{Math.ceil(selectedPole.highestDeltaT * 100) / 100}} °C</span>
                    </div>
                  </div>
                  <hr />
                  <div class="row" style="padding-left:2px">
                    <div class="col-5">
                      <span class="span-bold">Span Type</span>
                    </div>
                  </div>
                  <div class="row" style="padding:2px">
                    <div class="col-12">
                      <input
                        type="radio"
                        @change="changeSpanType(selectedSpanType)"
                        v-model="selectedSpanType"
                        value="Span"
                      />
                      <span class="span-light">Span</span>&nbsp; &nbsp; &nbsp;
                      <input
                        type="radio"
                        @change="changeSpanType(selectedSpanType)"
                        v-model="selectedSpanType"
                        value="Under Ground"
                      />
                      <span class="span-light">Under Ground</span>&nbsp; &nbsp; &nbsp;
                      <input
                        type="radio"
                        @change="changeSpanType(selectedSpanType)"
                        v-model="selectedSpanType"
                        value="End Of Line"
                      />
                      <span class="span-light">End Of Line</span>&nbsp; &nbsp; &nbsp;
                    </div>
                  </div>
                  <hr />
                  <div class="row" style="padding:2px">
                    <div class="col-4" style="padding-top: 8px;">
                      <span class="span-bold">Equipments</span>
                    </div>
                    <div class="col-16">
                      <multiselect
                        v-model="selectedEquips"
                        :options="equipments"
                        :multiple="true"
                        :close-on-select="false"
                        :clear-on-select="false"
                        :preserve-search="true"
                        placeholder="Equipments"
                        :preselect-first="false"
                        label="name"
                        track-by="name"
                        style="font-size:10px"
                        @input="updateEquips"
                        :custom-label="customLabelEquips"
                      >
                        <!-- <template slot="selection" slot-scope="{ values, search, isOpen }">
                          <span
                            style="font-size:10px"
                            v-if="values.length &amp;&amp; !isOpen"
                          >{{ values.length }} equipments selected</span>
                          
                        </template>-->
                      </multiselect>
                    </div>

                    <!-- </div> -->
                  </div>
                </div>
              </div>
              <div class="container-fluid mt-2" style="border: 1px solid #f0f0f0; padding:0px;">
                <div class="row">
                  <nav class="col-12 pr-0 pl-0">
                    <div class="nav nav-pills nav-fill" id="nav-tab" role="tablist">
                      <div
                        class="nav-item nav-link align-middle"
                        style="padding: .5rem .16rem; font-size: 12px;"
                        :class="{'active': isVisual}"
                        @click="visualTabClicked"
                        id="nav-home-tab"
                        data-toggle="tab"
                        role="tab"
                        aria-controls="nav-home"
                        aria-selected="true"
                      >
                        <span class="align-middle">
                          <img
                            class="mr-2"
                            style="height: 20px;"
                            src="@/assets//icons-8-full-image.png"
                            alt
                          /> Visual
                        </span>
                      </div>
                      <div
                        class="nav-item nav-link align-middle"
                        style="padding: .5rem .16rem; font-size: 12px;"
                        :class="{'active': isThermal}"
                        @click="thermalTabClicked"
                        id="nav-home-tab"
                        data-toggle="tab"
                        role="tab"
                        aria-controls="nav-home"
                        aria-selected="true"
                      >
                        <span class="align-middle">
                          <img
                            class="mr-2"
                            style="height: 20px;"
                            src="@/assets/icons-8-rgb-circle-color.png"
                            alt
                          /> Thermal
                        </span>
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
              <div
                :class="{'light-items' : !isAllPhotoSelected, 'dark-item': isAllPhotoSelected}"
                @click="allPhotoClicked()"
              >
                <span class="ml-2">
                  All Photo
                  <i
                    class="fas fa-chevron-right mr-2 align-middle"
                    style="float: right; height: 100%; margin-top: 13px;"
                  ></i>
                </span>
              </div>
              <div class="small-title">
                <span class="ml-2">Defect List</span>
                <button
                  class="btn btn-outline-dark btn-sm mr-2 mt-2"
                  style="float: right"
                  @click="addDefectClick()"
                >
                  <i class="fas fa-plus"></i>
                </button>
              </div>
              <!-- <div class="mr-2 mt-2 mb-2 ml-2">
                <button class="btn btn-sm btn-outline-dark width-full" @click="addDefectClick"><i class="fas fa-toolbox mr-2"></i> Add Defect</button>
                <div class="row add-defect-container no-margin" v-if="isShowingDefectList">
                  <div id="severity" v-if="isShowingSeverityList">
                    <div v-if="selectedComponent.defect">
                      <div class="defects-header">{{ selectedComponent.component.name +'('+selectedComponent.defect.name+')' }}</div>
                      <div class="defect" v-for="severity in severities" :key="severity.name">
                        <a href="javascript:void(0)" @click="selectSeverity(severity)">{{ severity.name }}</a>
                      </div>
                    </div>                        
                  </div>
                  <div id="defects">
                    <div v-for="component in components" :key="component.name">
                      <div class="defects-header">{{ component.name }}</div>
                      <div class="defect" v-for="defect in component.defects" :key="defect.name">
                        <a href="javascript:void(0)" @click="selectDefect(component, defect)">{{ defect.name }}</a>
                      </div>
                    </div>              
                  </div>
                  <div id="custom-defect-button">
                    <div class="custom-defect">
                      <a href="javascript:void(0)" @click="customDefectClick()">Custom Defect</a>
                    </div> 
                  </div>
                </div>
              </div>-->
              <!-- <div v-if="defects" :class="{'dark-item': selectedDefectList == defect, 'light-items': selectedDefectList != defect}" v-for="defect in defects" :key="defect.id" @click="defectListSelected(defect)"> -->
              <!-- <span class="ml-2" style="height:100%">{{defect.name}} </span> -->
              <!-- <a class="list-group-item" href="#">
                  <span class="text">{{defect.name}} </span><span class="badge badge-primary aligh-middle" style="float:right">{{defect.severity}}</span>
              </a>-->
              <div class="list-group list-group-flush" v-if="defects" style>
                <div
                  class="list-group-item"
                  style
                  :class="{'selected-side-list': selectedDefectList == defect}"
                  v-for="defect in defects"
                  :key="defect.id"
                  @click="defectListSelected(defect)"
                >
                  <span class="list-group-item-heading">{{defect.component}} ({{defect.name}})</span>
                  <div style="float:right;">
                    <button
                      style="background: none; border: none; color: white;  cursor:pointer; z-index: 9999;"
                      @click="setIsShowDeleteOption(defect)"
                    >
                      <i class="fas fa-ellipsis-v"></i>
                    </button>
                  </div>
                  <span
                    class="badge badge-pill aligh-middle mr-1"
                    :class="severityClass(defect.severity)"
                    style="float:right; margin-top: 0.1rem;"
                  >{{defect.severity}}</span>

                  <div id="delelte-option" v-if="selectedDefectToDelete == defect">
                    <div>
                      <div @click="openDeleteDialog(defect)" class="defects-header">Delete</div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- </div> -->
            </div>
            <div class="col-8 inherit-height">
              <div class="row" style="height: 80%; margin-left: 0px; margin-right: 0px;">
                <div
                  class="col-12 box-border"
                  style="padding-right: 0px;padding-left: 0px; height: 100%;"
                >
                  <div class="fluid-container" style="width: 100%; height: 100%;">
                    <div v-show="selectedImage" class="p1" id="canvasContainer">
                      <!-- <img v-if="selectedImage" :src="selectedImage.directory"> -->
                      <canvas id="canvas"></canvas>

                      <div class="loading-bg" v-if="isLoading">
                        <div style="text-align: -webkit-center;">
                          <!-- <img src="@/assets/tnbloader.gif" class="loading-icon" alt /> -->
                          <h6 v-if="loadingMsg">{{loadingMsg}}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="zoom-main-bg">
                    <div class="zoom-item" @click="zoomIn()">
                      <i class="fa fa-plus height-full"></i>
                    </div>
                    <div class="zoom-item" @click="zoomOut()">
                      <i class="fa fa-minus height-full"></i>
                    </div>
                  </div>
                  <div class="box-overlay" v-if="!isCroping && !selectedDefectList">
                    <!-- <label style="margin: 10px;" class="switch">
                      <input v-model="isEditing" type="checkbox">
                      <span class="slider"></span>
                    </label>-->
                    <button
                      class="btn btn-sm btn-danger"
                      style="margin: 10px; float: right"
                      @click="deleteImage()"
                    >Delete Image</button>
                    <!-- <button
                      class="btn btn-sm btn-outline-light"
                      style="margin: 10px; float: right"
                      v-if="isVisual"
                      @click="setCrop()"
                    >Crop Image</button>-->
                    <!-- <button
                      class="btn btn-sm btn-outline-light"
                      style="margin: 10px; float: left"
                      v-if="isVisual"
                      @click="setTopDown()"
                    >Set as TopDown</button>-->
                    <button
                      class="btn btn-sm btn-outline-light"
                      style="margin: 10px; float: left"
                      v-if="isVisual"
                      @click="setPoleNumberImage()"
                    >Set as Pole Number</button>
                  </div>

                  <div class="box-overlay" v-if="isCroping">
                    <button
                      class="btn btn-sm btn-outline-light"
                      style="margin: 10px;"
                      @click="cancelCroping()"
                    >Cancel</button>
                    <button
                      class="btn btn-sm btn-outline-light"
                      style="margin: 10px; float:right;"
                      @click="saveCropedImage()"
                    >Save</button>
                  </div>
                  <div class="box-overlay" v-if="selectedDefectList && !isCroping && selectedImage">
                    <!-- <button class="btn btn-sm btn-outline-light" style="margin: 10px; float:left;" >Add</button> -->
                    <button
                      class="btn btn-sm btn-outline-light"
                      v-if="selectedImage.type == 'visual'"
                      style="margin: 10px; float:right;"
                      @click="drawBox()"
                    >Add Box</button>
                  </div>
                </div>
                <!-- <div class="col-2 box-border" style="height: 100%">

                </div>-->
              </div>
              <div
                style="height: 22%; padding-left: 0px; padding-right: 0px;"
                class="box-border-full"
              >
                <div
                  style="width: 100%; height: 50%; display:flex; overflow: scroll;  border-bottom: 1px solid #abb4bd !important;"
                >
                  <div class="p-2 height-full" style="text-align: center; font-size: 12px;">
                    <span class="badge badge-pill badge-primary">Images</span>
                  </div>
                  <div v-if="selectedPoleImages && selectedPoleImages.length > 0">
                    <div
                      class="pt-1 pb-1 pr-1 img-thumb-container"
                      v-for="(image, imageIndex) in selectedPoleImages"
                      :key="imageIndex"
                    >
                      <img
                        v-bind:class="{border : isSelectedImage(image.id), 'border-primary' : isSelectedImage(image.id)}"
                        class="img-thumb"
                        :src="setImagePathToShow(image)"
                        @click="selectImage(image.image_id)"
                      />
                      <div class="pl-2 pb-1 pr-1 img-thumb-text-container">
                        <span
                          v-if="(!image.crop_image_name || image.crop_image_name =='null') && !image.imageName"
                        >{{image.name}}</span>
                        <span
                          v-if="image.crop_image_name && image.crop_image_name !='null'"
                        >{{image.crop_image_name}}</span>
                        <span v-if="image.imageName">{{image.imageName}}</span>
                      </div>
                      <div
                        v-if="image.isTopDownImage && image.isTopDownImage!='null'"
                        style="color: white; position: absolute; top: 7px; left: 10px; object-position: center; text-align: center; font-size: 13px;"
                      >
                        <span class="badge badge-pill badge-primary">Top</span>
                      </div>
                      <div
                        v-if="image.isPoleNumber"
                        style="color: white; position: absolute; top: 7px; left: 10px; object-position: center; text-align: center; font-size: 13px;"
                      >
                        <span class="badge badge-pill badge-info">Num</span>
                      </div>
                    </div>
                  </div>
                  <!-- <div
                    v-if="!selectedDefectList"
                    class="pt-1 pb-1 pr-1 img-thumb-container"
                    style="text-align: center"
                    @click="addImages()"
                  >
                    <div class="add-img-thumb">
                      <img src="@/assets/icons8-plus-math-filled-60.png" alt />
                    </div>
                  </div>-->
                  <div
                    v-if="selectedDefectList"
                    class="pt-1 pb-1 pr-1 img-thumb-container"
                    style="text-align: center"
                    @click="addImageForDefect()"
                  >
                    <div class="add-img-thumb">
                      <img src="@/assets/icons8-plus-math-filled-60.png" alt />
                    </div>
                  </div>
                  <h5
                    v-if="selectedPoleImages && selectedPoleImages.length <= 0"
                    style="padding: 10px"
                  >No images</h5>
                </div>
                <div style="height: 50%; width: 100%;">
                  <!-- <div v-if="selectedDefectList" class="container-fluid height-full"> -->
                  <div
                    v-if="selectedDefectList && this.selectedPoleImages.length > 0"
                    class="height-full"
                    style="float: left; display: flex; align-items: center; overflow: scroll; width: 100%;"
                  >
                    <div
                      class="pl-1 pb-1 pr-1 height-full"
                      style="text-align: center; font-size: 12px"
                    >
                      <span class="badge badge-pill badge-primary">SnapShots</span>
                    </div>
                    <div
                      class="pt-1 pb-1 pr-1 pl-1 img-thumb-container"
                      style="text-align: center"
                      v-for="(snapshot, snapshotIndex) in selectedDefectSnapShots"
                      :key="snapshotIndex"
                    >
                      <img
                        class="img-thumb"
                        v-if="selectedImage && selectedImage.defectType && snapshot.name"
                        :src="setSnapshotPath(snapshot.name)+'?'+Math.random()"
                      />
                      <div
                        style="color: white; position: absolute; top: 7px; left: 10px; object-position: center; text-align: center; font-size: 13px;"
                      >
                        <span class="badge badge-pill badge-primary">{{snapshot.position}}</span>
                      </div>

                      <div
                        v-if="snapshot.name"
                        class="thumb-overlay"
                        @click="snapShotClick(snapshot)"
                      ></div>
                      <button
                        v-if="snapshot.name"
                        class="btn btn-danger btn-sm btn-delete-overlay-hover"
                        @click="deleleSnapshot(snapshot)"
                      >
                        <i class="fas fa-trash"></i>
                      </button>
                      <button
                        v-if="snapshot.name"
                        class="btn btn-sm btn-overlay-hover"
                        @click="addScreenShotBox(snapshot)"
                      >Retake</button>

                      <div
                        v-if="!snapshot.name"
                        class="add-img-thumb"
                        @click="addScreenShotBox(snapshot)"
                      >
                        <img src="@/assets/icons8-plus-math-filled-60.png" alt />
                      </div>
                    </div>
                  </div>
                  <!-- </div> -->
                </div>
                <!-- <div class="container-fluid" style="width:100%; position: absolute; bottom: 0px; "> -->
                <!-- <b-alert :variant="setStatusColor(status)" dismissible fade :show="isShowAlert" @dismissed="isShowAlert=false">
                    {{alertMsg}}
                </b-alert>-->
                <!-- </div> -->
              </div>
            </div>
            <div
              class="col-2 inherit-height box-border-full"
              style=" padding-left: 0px;  padding-right: 0px;"
            >
              <div class="small-title" style="cursor: default; font-weight: bold;">
                <span class="ml-2">Defect Details</span>
              </div>
              <div class="fluid-container" style="overflow: scroll;" v-if="selectedDefectList">
                <div class="row">
                  <div class="col-5">
                    <span class="span-light">Component</span>
                  </div>
                  <div class="col-7">
                    <span class="span-bold">{{selectedDefectList.component}}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-5">
                    <span class="span-light">Name</span>
                  </div>
                  <div class="col-7">
                    <span class="span-bold">{{selectedDefectList.name}}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-5">
                    <span class="span-light">Severity</span>
                  </div>
                  <div class="col-7">
                    <span class="span-bold">{{selectedDefectList.severity}}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-5">
                    <span class="span-light">Phase</span>
                  </div>
                  <div class="col-7">
                    <select
                      class="form-control form-control-sm"
                      style="width: 100%;"
                      v-if="defectPhase"
                      @change="changeDefectPhase(selectedDefectPhase)"
                      v-model="selectedDefectPhase"
                    >
                      <option
                        v-for="phase in defectPhase"
                        :key="phase.name"
                        :value="phase"
                      >{{phase.name}}</option>
                    </select>
                  </div>
                </div>
                <div class="p-2 mt-2">
                  <div style="font-size: 12px;">
                    <span class="badge badge-pill badge-primary" style>Remark</span>
                  </div>
                  <div
                    class="text-container p-1"
                    @click="remarkRecommendClick('remark')"
                    style="height: 100%; color: black; overflow: scroll; font-size: 12px; border-radius: 3px;"
                  >
                    <!-- <div v-if="selectedDefectList && selectedDefectList.remark" class="text-container-overlay" ></div> -->
                    <!-- <button class="btn btn-sm btn-overlay-hover">Edit</button> -->
                    <span
                      v-if="selectedDefectList && selectedDefectList.remark"
                    >{{selectedDefectList.remark}}</span>
                  </div>
                </div>
                <div class="p-2 mt-2">
                  <div style="font-size: 12px;">
                    <span class="badge badge-pill badge-primary" style>Analysis &amp; Recommendation</span>
                  </div>
                  <div
                    class="text-container p-1"
                    @click="remarkRecommendClick('recommendation')"
                    style="height: calc(100% - 20px); color: black; overflow: scroll; font-size: 12px;"
                  >
                    <span
                      v-if="selectedDefectList && selectedDefectList.recommendation"
                    >{{selectedDefectList.recommendation}}</span>
                  </div>
                </div>
                <!-- <div class="p-2 mt-2">
                  <button
                    class="btn btn-outline-dark btn-sm"
                    style="float: right;"
                    @click="generateReport()"
                  >Generate Report</button>
                </div>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="selectedSnapShotPath" class="img-view-container">
      <img :src="selectedSnapShotPath" alt />
      <div
        style="position: absolute; top:0; right:0; color: white; font-size: 24px; cursor: pointer;"
        @click="selectedSnapShotPath=null"
      >
        <i class="fas fa-times-circle"></i>
      </div>
    </div>
    <div class="loading-bg" v-if="isLoadingAll">
      <div style="text-align: -webkit-center;">
        <!-- <img src="@/assets/tnbloader.gif" class="loading-icon" alt /> -->
        <h6 v-if="loadingMsg">{{loadingMsg}}</h6>
      </div>
    </div>
    <modal
      v-if="isShowModal"
      :propsImages="imagesForChooseModal()"
      :defect="selectedDefectList"
      @close="close"
      @save="save"
    ></modal>
    <!-- <edit-modal
      v-if="isShowEditModal"
      :type="editDataType"
      :defect="selectedDefectList"
      @save="saveEditModal"
      @close="closeEditModal"
    ></edit-modal>-->
    <custom-defect-modal
      v-if="isShowCustomDefectModal"
      @close="closeCustomModal"
      @save="saveDefectModal"
    ></custom-defect-modal>
    <defect-modal
      v-if="isShowDefectModal"
      @custom="customDefectClick"
      @selected="defectSelected"
      @close="closeDefectModal"
      :compenentsDefects="components"
    ></defect-modal>
  </div>
</template>

<script>
import { fabric } from "fabric";
import axios from "axios";
import path from "path";
import _ from "lodash";
import $ from "jquery";
import capitalize from "capitalize";
import util, { log } from "util";
import fs from "fs";
import sizeOf from "image-size";
import { ExifImage } from "exif";
import request from "request";
import a from "async";
// import sharp from "sharp";
import slugify from "slugify";
import { Promise } from "bluebird";
import moment from "moment";
import Multiselect from "vue-multiselect";
import config from "../config";
import { version } from "../../package.json";
import DBProject from "../db_project";
import DB from "../db";
import API from "../api";
import GenericObject from "../generic_object";
import componentsJson from "../components.json";
import componentsBAREJson from "../components_bare.json";
import componentsThermalJson from "../components_thermal.json";
import Modal from "./choose_image_defect_modal.vue";
import ModalEdit from "./edit_remark_recommendation_modal.vue";
import CustomDefectModal from "./custom_defect_modal.vue";
import DefectModal from "./defect_list_modal.vue";
import Inspection from "./inspection.vue";
import customDialog from "@/components/custom-dialog";
import { async } from "q";
export default {
  name: "defect-marking",
  props: ["defectMarkingProps"],
  components: {
    modal: Modal,
    "edit-modal": ModalEdit,
    "custom-defect-modal": CustomDefectModal,
    "defect-modal": DefectModal,
    "custom-dialog": customDialog,
    Inspection: Inspection,
    Multiselect
  },
  data() {
    return {
      poleNumberImage: null,
      isShowDefectModal: false,
      isShowCustomDefectModal: false,
      editDataType: null,
      isShowEditModal: false,
      selectedInspectionsType: null,
      selectedZones: [],
      inspectionsType: [
        {
          name: "ABC"
        },
        {
          name: "BARE"
        }
      ],
      selectedPoleType: null,
      selectedSpanType: null,
      poleTypes: [
        {
          name: "TT - tower tension"
        },
        {
          name: "TG - tower gantung"
        },
        {
          name: "P1 - single pole"
        },
        {
          name: "P2 - H pole"
        },
        {
          name: "P3 - Angle pole"
        },
        {
          name: "P4 - 2H pole"
        }
      ],
      selectedDefectPhase: null,
      defectPhase: [
        {
          name: "UNKNOWN"
        },
        {
          name: "ALL"
        },
        {
          name: "RED"
        },
        {
          name: "BLUE"
        },
        {
          name: "YELLOW"
        },
        {
          name: "NA"
        }
      ],
      isLoadingAll: false,
      isShowModal: false,
      isCroping: false,
      isEditing: null,
      SCALE_FACTOR: 1.1,
      loadingMsg: null,
      isShowAlert: false,
      status: "",
      isVisual: true,
      isThermal: false,
      canvasImage: null,
      defaultCoor: {
        x: 0,
        y: 0
      },
      selectedPoleVisual: null,
      selectedPoleThermal: null,
      alertMsg: "",
      ratio: 1.0,
      components: null,
      severities: [
        {
          name: "Notify"
        },
        {
          name: "Low"
        },
        {
          name: "Cautious"
        },
        {
          name: "Critical"
        }
      ],
      selectedComponent: {
        component: null,
        defect: null
      },
      selectedEquips: [],
      equips: [
        "Auto Reclosure (AR)",
        "Load Break Switch (LBS)",
        "Switch Link",
        "Drop Out Fuse",
        "Joint",
        "Termination to Underground",
        "Termination to Overhead",
        "Surge Arrestor",
        "Transformer",
        "Potential Tranformer",
        "Busbar Link"
      ],
      equipments: [],
      canvasContainer: {
        width: null,
        height: null
      },
      isShowingDefectList: false,
      isShowingSeverityList: false,
      canvas: null,
      drafts: null,
      imagePath: null,
      projects: null,
      selectedProject: null,
      poles: null,
      selectedPole: null,
      selectedPoleImages: null,
      selectedImage: null,
      texts: [],
      rects: [],
      isLoading: false,
      topDownImage: null,
      group: null,
      imgMarginTop: 0,
      imgMaginLeft: 0,
      selectedBox: null,
      cropBox: null,
      isAllPhotoSelected: false,
      isSaveCropImage: false,
      defects: null,
      selectedDefectList: null,
      images: null,
      selectedDefectSnapShots: [],
      selectedSnapShot: null,
      isCompleted: false,
      selectedSnapShotPath: null,
      projectPath: null,
      polePath: null,
      selectedDefectToDelete: null,
      previousPole: null,
      nextPole: null,
      dialogProps: {
        dialog: false,
        width: "100%"
      }
    };
  },
  computed: {
    canvasWidth() {
      // return window.innerWidth - (window.innerWidth * 0.25) - 54
      return document.getElementsByClassName("p1").offsetWidth;
    },

    canvasHeight() {
      return window.innerHeight - 354;
    },

    versionNumber() {
      return version;
    },

    originalImageURL() {
      return this.selected.section &&
        this.selected.section.getMetadata("original_image_url")
        ? this.selected.section.getMetadata("original_image_url")
        : "";
    },

    zoomifyURL() {
      return this.selected.section &&
        this.selected.section.getMetadata("zoomify_url")
        ? this.selected.section.getMetadata("zoomify_url")
        : "";
    }
  },
  watch: {
    async selectedDefectList() {
      if (this.selectedDefectList) {
        this.selectedDefectPhase = this.defectPhase.find(
          phase => phase.name == this.selectedDefectList.phase
        );
        //console.log(this.selectedDefectPhase);
        var selectedDefectListImages = await DBProject.getDefectsById(
          this.selectedPole.id,
          this.selectedDefectList.id
        );
        //console.log(selectedDefectListImages, "selectedDefectListImages");

        var selectedSnapShots = await DBProject.getSnapShots(
          this.selectedDefectList.id
        );
        var dummySnapshots = [];
        // this.selectedDefectSnapShots = await DBProject.getSnapShots(this.selectedDefectList.id)
        // var emptySnapshotCount = 4 - this.selectedDefectSnapShots.length
        //console.log(selectedSnapShots);
        var numOfss = this.selectedDefectList.type == "visual" ? 4 : 2;
        for (let index = 0; index < numOfss; index++) {
          dummySnapshots.push({
            defect_id: this.selectedDefectList.id,
            name: null,
            position: index + 1
          });
        }
        dummySnapshots.forEach(dummySnapshot => {
          selectedSnapShots.forEach(snapshot => {
            if (snapshot.position == dummySnapshot.position) {
              //console.log(dummySnapshot);
              dummySnapshot.id = snapshot.id;
              dummySnapshot.name = snapshot.name;
            }
          });
        });
        //console.log(dummySnapshots);
        this.selectedDefectSnapShots = dummySnapshots;
        //console.log(this.selectedDefectSnapShots);
        this.selectedPoleImages = selectedDefectListImages.filter(
          image => image.image_id != null
        );

        //console.log("this.selectedPoleImages");
        //console.log(this.selectedPoleImages);
      }
    },
    $route() {
      //console.log(this.$route.params);
      this.initData();
    },
    isEditing() {
      //console.log("this.isEditing");
      // //console.log(this.isEditing)
      if (this.isEditing) {
        // this.canvas.selection = false
        this.canvas.getObjects().forEach(function(o) {
          if (o.defect && o.defect !== "undefined") {
            // o.selection = false
            o.lockMovementX = false;
            o.lockMovementY = false;
          }
        });
        // this.canvas.renderAll();
      } else {
        // this.canvas.selection = true
        this.canvas.getObjects().forEach(function(o) {
          if (o.defect && o.defect !== "undefined") {
            // o.selection = true
            o.lockMovementX = true;
            o.lockMovementY = true;
          }
        });
        // this.canvas.renderAll();
      }
    },
    canvasContainer() {
      this.canvas.setHeight(this.canvasContainer.height);
      this.canvas.setWidth(this.canvasContainer.width);
    },
    async selectedImage() {
      //console.log("selectedImage");
      //console.log(this.selectedImage);
      if (this.selectedImage == null) {
        this.canvas.clear();
        return;
      }

      if (this.selectedBox != null) this.selectedBox = null;

      // this.isEditing = false

      if (this.isCroping) {
        this.cancelCroping();
      } else {
        // if(this.canvas){
        this.drawImage();
        // }
      }

      // //console.log(this.selectedImage)

      if (this.selectedImage.type == "thermal") {
        var dir = path.join(
          this.polePath,
          this.selectedImage.type.toUpperCase()
        );
        // this.readFlirInfo(path.join(dir, this.selectedImage.name)).then(
        //   async data => {
        //     this.selectedImage.thermal_data = data;
        //     var deltaT = data.split(",");
        //     this.selectedImage.thermal_deltaT = deltaT[deltaT.length - 1]
        //       .split(":")[1]
        //       .replace(/[^0-9.]/g, "");
        //     //console.log(deltaT);
        //     // //console.log(this.selectedImage)
        //     await DBProject.updateImageThermalData(this.selectedImage);
        //     // DBProject.updatePoleProgress(this.selectedPole.id, false)
        //     //       .then((data)=>{
        //     //         this.selectedPole.completed = false
        //     //         this.isCompleted = false
        //     //         //console.log(this.selectedPole)
        //     //       })
        //   }
        // );
      }
    },

    selectedProjectId: function() {
      // //console.log(this.selectedProjectId)
    },
    projects: function() {
      // //console.log(this.selectedProjectId)
      // this.selectedProject = this.projects.find(project => project.project_id == this.$route.params[`id`])
      // //console.log(this.selectedProject)
    },
    selectedProject: async function() {
      if (this.selectedProject) {
        // if(this.selectedProject.inspectionType == 'ABC'){
        //   this.components = componentsJson
        // } else {
        //   this.components = componentsBAREJson
        // }
        this.projectPath = path.join(
          path.dirname(localStorage.getItem("projectdb_path")),
          this.selectedProject.sectionName
        );
        //console.log(this.projectPath);
        //console.log(this.$route.params[`pid`], "vgfgsdfg");
        this.selectedPole = await DBProject.getPolesById(
          this.selectedProject.id,
          this.$route.params[`pid`]
        );
        //console.log(this.$route.params[`pid`], "vgfgsdfg");
      }
      // this.poles = this.selectedProject.poles
      // //console.log(this.poles)
    },
    poles: function() {
      if (this.poles) {
        // //console.log(this.poles);
        this.poles.forEach(pole => {
          if (pole.id == this.$route.params[`pid`]) {
            // //console.log(true)
            // this.selectedPole = pole
            this.nextPole = this.poles[this.poles.indexOf(pole) + 1];
            //console.log("nextPole");
            //console.log(this.nextPole);
            this.previousPole = this.poles[this.poles.indexOf(pole) - 1];
            return;
          }
        });
      }
    },
    selectedPole: async function() {
      //console.log("selectedPole", this.selectedPole);
      if (this.selectedPole) {
        this.selectedInspectionsType = this.inspectionsType.find(
          type => type.name == this.selectedPole.inspection_type
        );
        this.selectedSpanType = this.selectedPole.span_type;
        if (this.selectedPole.equipments) {
          let updatedEquips = [];
          this.selectedPole.equipments.split(",").map(id => {
            let equipFound = this.equipments.find(equipment => {
              return id === equipment._id;
            });
            if (equipFound) {
              updatedEquips.push(equipFound);
            }
          });
          this.selectedEquips = updatedEquips;
          //console.log("this.selectedEquips", this.selectedEquips);
        }
        //console.log("this.selectedInspectionsType");
        //console.log(this.selectedInspectionsType);
        this.selectedPoleType = this.poleTypes.find(
          type => type.name == this.selectedPole.pole_type
        );
        //console.log("this.selectedPoleType");
        //console.log(this.selectedPoleType);
        this.polePath = path.join(this.projectPath, this.selectedPole.name);
        this.isVisual = true;
        this.isThermal = false;
        this.images = await DBProject.getImagesByPoleId(this.selectedPole.id);
        // //console.log(this.images, "asdasd");
        this.selectedPoleVisual = this.images.filter(
          image => image.type == "visual"
        );
        this.selectedPoleThermal = this.images.filter(
          image => image.type == "thermal"
        );
        this.selectedPoleImages = this.selectedPoleVisual;

        this.defects = await DBProject.getDefects(this.selectedPole.id);
        // //console.log(this.defects, "111sdfsdf");
      } else {
        this.selectedPoleImages = null;
      }
    },
    "selectedPole.completed": function() {
      if (this.selectedPole) {
        //console.log(this.selectedPole.completed);
        this.isCompleted = this.selectedPole.completed;
        //console.log(this.isCompleted);
      }
    },
    selectedPoleImages: function() {
      //debugger
      //console.log("this.selectedPoleImages");
      if (this.selectedPoleImages && this.selectedPoleImages.length > 0) {
        //console.log(this.selectedPoleImages);
        var sameimage = this.selectedPoleImages.find(image => {
          return image == this.selectedImage;
        });

        if (typeof sameimage === "undefined") {
          this.selectedImage = this.selectedPoleImages[0];
          //console.log(this.selectedImage);
        }

        this.topDownImage = this.selectedPoleImages.find(
          image => image.isTopDownImage == 1
        );

        this.poleNumberImage = this.selectedPoleImages.find(
          image => image.isPoleNumber == 1
        );
        //console.log(this.poleNumberImage);
      } else {
        this.canvas.clear();
      }
    }
  },
  methods: {
    onClickChild() {
      this.dialogProps.dialog = false;
      window.close();
    },
    viewPoleProgress() {
      this.dialogProps.dialog = true;
    },
    showModal() {
      // let item = this.getCurrentItem(_itemName).selected;
      // this.fillItems(_modalName, item);
      this.modal_eq = true;
      this.$modal.show("equipments-modal");
    },
    customLabelEquips(option) {
      return `${option.name}`;
    },
    setPoleName(name) {
      //console.log(name.replace("^", "*"));
      return name.replace("^", "*");
    },
    async getEquipments() {
      //console.log("Getting equipments");
      const eqpmnts = await axios.get(
        `${config.baseAdminAPIDomain}/equipments`
      );
      this.equipments = eqpmnts.data;
      //console.log("equipments", this.equipments);
    },
    formatDate(date) {
      return moment(date).format("LL");
    },

    async generateReport() {
      var reportPath = "";
      var feeder = await DB.getFeederBySectionId(
        this.selectedProject.section_id
      );
      //console.log(reportPath);
      let reportData = {
        dir: "",
        feeder: feeder ? feeder.name : "",
        location: this.selectedProject.sectionName,
        poles: 100,
        pilot: this.selectedProject.pilot,
        copilot: this.selectedProject.coPilot,
        thermographer: this.selectedProject.thermographer,
        report_date: moment().format("DD/MM/YYYY"),
        inspection_type: "",
        data: []
      };

      // var reportData = null

      var defectsForReport = await DBProject.getDefectReportById(
        this.selectedDefectList
      );

      if (defectsForReport) {
        // a.eachSeries(defectsForReport,(defect, callback) =>{
        var defect = defectsForReport;
        if (defect.type == "visual") {
          //console.log(defect.snapshots);
          var dummySnapShots = [];
          for (let index = 0; index < 4; index++) {
            dummySnapShots.push({
              name: null,
              position: index + 1,
              path: null
            });
          }
          dummySnapShots.forEach(dummySnapshot => {
            defect.snapshots.forEach(snapshot => {
              if (snapshot.position == dummySnapshot.position) {
                //console.log(dummySnapshot);
                dummySnapshot.id = snapshot.id;
                dummySnapshot.name = snapshot.name;
                dummySnapshot.path = snapshot.path;
              }
            });
          });
          //console.log(dummySnapShots);
          reportData.data.push({
            type: "visual", //visual or thermal
            pole_no: this.setPoleName(defect.poleName.split(/ (.+)/)[1]),
            date: moment(defect.time).format("DD/MM/YYYY"),
            time: moment(defect.time).format("HH:mm"),
            pole_type:
              defect.pole_type != null
                ? `${defect.inspection_type} (${defect.pole_type})`
                : this.selectedProject.inspectionType,
            voltage: this.selectedProject.feederVoltage,
            temp: defect.temp,
            load:
              this.selectedProject.sectionLoad != ""
                ? this.selectedProject.sectionLoad
                : "UNKNOWN",
            phase: defect.phase,
            humidity: defect.humidity,
            coordinate: defect.poleLat + "," + defect.poleLng,
            severity: defect.severity,
            //component defect
            component: defect.component,
            defect: defect.name,
            recommendation: defect.recommendation,
            remark: defect.remark,
            image_1: dummySnapShots[0].path ? dummySnapShots[0].path : "",
            image_2: dummySnapShots[1].path ? dummySnapShots[1].path : "",
            image_3: dummySnapShots[2].path ? dummySnapShots[2].path : "",
            image_4: dummySnapShots[3].path ? dummySnapShots[3].path : ""
            //end component defect
          });
        } else if (defect.type == "thermal") {
          ////console.log(defect)
          // if(defect)
          dummySnapShots = [];
          for (let index = 0; index < 4; index++) {
            dummySnapShots.push({
              name: null,
              position: index + 1,
              path: null
            });
          }
          dummySnapShots.forEach(dummySnapshot => {
            defect.snapshots.forEach(snapshot => {
              if (snapshot.position == dummySnapshot.position) {
                //console.log(dummySnapshot);
                dummySnapshot.id = snapshot.id;
                dummySnapshot.name = snapshot.name;
                dummySnapshot.path = snapshot.path;
              }
            });
          });
          //console.log(dummySnapShots);
          //console.log(defect);
          var data = _.fromPairs(
            defect.thermalImages[0].thermal_data
              .split(", ")
              .map(s => s.split(": "))
          );
          //console.log(data.DeltaT);
          var detlaT = defect.thermalImages[0].thermal_deltaT;
          //console.log(Math.ceil(detlaT * 100) / 100);
          reportData.data.push({
            type: "thermal", //visual or thermal
            pole_no: this.setPoleName(defect.poleName.split(/ (.+)/)[1]),
            date: moment(defect.time).format("DD/MM/YYYY"),
            time: moment(defect.time).format("HH:mm"),
            pole_type:
              defect.pole_type != "" && defect.pole_type != null
                ? `${defect.inspection_type} (${defect.pole_type})`
                : this.selectedProject.inspectionType,
            voltage: this.selectedProject.feederVoltage,
            temp: defect.temp,
            load:
              this.selectedProject.sectionLoad != ""
                ? this.selectedProject.sectionLoad
                : "UNKNOWN",
            phase: defect.phase,
            humidity: defect.humidity,
            coordinate: defect.poleLat + "," + defect.poleLng,
            severity: defect.severity,
            sp1: Math.ceil(data.SP1 * 100) / 100,
            sp2: Math.ceil(data.SP2 * 100) / 100,
            dt1: Math.ceil(detlaT * 100) / 100,
            //component defect
            component: defect.component,
            defect: defect.name,
            recommendation: defect.recommendation,
            remark: defect.remark,
            image_1: defect.thermalImages[0]
              ? defect.thermalImages[0].path
              : "",
            image_2: dummySnapShots[0] ? dummySnapShots[0].path : "",
            image_3: dummySnapShots[1] ? dummySnapShots[1].path : ""
          });
        }
      } else {
        alert("no defect created today");
      }

      //console.log(reportData);
      if (reportData.data && reportData.data.length > 0) {
        let win = "";

        var CurrentDate = moment().format("DD_MM_YYYY_HH_mm_ss");
        //console.log(CurrentDate);
        const menuTemplate = [
          {
            label: "Report",
            submenu: [
              {
                label: "Save",
                accelerator: "CmdOrCtrl+S",
                click: () => {
                  var self = this;
                  var CurrentDate = moment().format("DD-MM-YYYY-HH-mm-ss");
                  var filename = `${slugify(this.selectedPole.name)}_${slugify(
                    this.selectedProject.sectionName
                  )}_${slugify(this.selectedDefectList.component)}(${slugify(
                    this.selectedDefectList.name
                  )})_${CurrentDate}.pdf`;
                  // dialog.showSaveDialog(win, {
                  //   properties: ['openDirectory'],
                  //   title: 'Save Report',
                  //   defaultPath : filename
                  // }, path => {
                  var saveReportPath = path.join(this.polePath, filename);
                  if (typeof saveReportPath !== "undefined") {
                    //console.log(saveReportPath);
                    win.webContents.printToPDF(this.pdfSettings(), function(
                      err,
                      data
                    ) {
                      if (err) {
                        //do whatever you want
                        alert(err);
                        return;
                      }
                      try {
                        //console.log(saveReportPath);
                        fs.writeFileSync(`${saveReportPath}`, data);
                        alert(`Report saved at ${saveReportPath}`);
                      } catch (err) {
                        //unable to save pdf..
                        alert("unable to save pdf");
                      }
                    });
                  }
                  // })
                }
              },
              {
                label: "Quit",
                accelerator: "CmdOrCtrl+W",
                click: () => {
                  //console.log("Quit");
                  win.close();
                }
              }
            ]
          }
        ];
        // const menu = Menu.buildFromTemplate(menuTemplate);
        // Menu.setApplicationMenu(menu);
        // win.setMenu(menu);
        let options = { root: __dirname };
        // var reportPath = path.join(app.getAppPath(),'report/defect-report-abc.ejs')
        //console.log("report data");
        //console.log(this.selectedPole.inspection_type);
        reportPath = "";

        //console.log(reportPath);
        // ejs.renderFile(reportPath, reportData, options, function(err, str) {
        //   if (err) {
        //     //console.log(err);
        //   }
        //   // Load the rendered HTML to the BrowserWindow.
        //   // //console.log(str)
        //   win.loadURL("data:text/html;charset=utf-8," + encodeURI(str));
        //   win.webContents.on("did-finish-load", function() {});
        //   win.webContents.on("did-finish-load", () => {
        //     //console.log("finish load");
        //   });
        //   // win.webContents.openDevTools()

        //   // ipcRenderer.send('open-report', `data:text/html;charset=utf-8, ${encodeURI(str)}`)
        // });
      }
    },
    pdfSettings() {
      var paperSizeArray = ["A4", "A5"];
      var option = {
        landscape: false,
        marginsType: 0,
        printBackground: true,
        printSelectionOnly: false
        // pageSize: paperSizeArray[settingCache.getPrintPaperSize()-1],
      };
      return option;
    },
    async deleleSnapshot(snapshot) {
      this.$swal({
        title: "Delete Snap Shot",
        text: "Are you sure to Delete Snap Shot?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, keep it"
      }).then(async result => {
        if (result.value) {
          var deletesnapshot = await DBProject.deleteSnapshot(snapshot);
          this.selectedDefectSnapShots.forEach(ss => {
            if (ss.id == snapshot.id) {
              //console.log(ss);
              ss.name = null;
              ss.id = null;
            }
          });
          this.$swal(
            "Deleted!",
            "Your imaginary file has been deleted.",
            "success"
          );
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          this.$swal("Cancelled", "Your imaginary file is safe :)", "error");
        }
      });
    },
    snapShotClick(snapshot) {
      this.selectedSnapShotPath = this.setSnapshotPath(snapshot.name, 1);
      // this.selectedSnapShot = snapshot
      // //console.log(this.selectedSnapShot)
    },
    remarkRecommendClick(type) {
      //console.log(type);
      this.editDataType = type;
      this.isShowEditModal = true;
    },
    async changePoleType(newType) {
      this.selectedPole.pole_type = await DBProject.updatePoleType(
        this.selectedPole,
        newType.name
      );

      //console.log(this.selectedPole.pole_type);
    },
    changeInspectionType(newType) {
      this.$swal({
        title: "Change Pole Inspection Type",
        text: `Do you want to Change Pole Inspection Type?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, Change it!",
        cancelButtonText: "No, keep it"
      }).then(async result => {
        if (result.value) {
          this.selectedPole.inspection_type = await DBProject.updatePoleInspectionType(
            this.selectedPole,
            newType.name
          );
          if (this.selectedPole.inspection_type == "ABC") {
            this.selectedPole.pole_type = await DBProject.updatePoleType(
              this.selectedPole,
              null
            );
          }
          this.$swal("Changed!", " Inspection has been Changed.", "success");
        } else if (result.dismiss === "cancel") {
          this.$swal("Cancelled", "Your Inspection is safe :)", "error");
        }
      });
    },
    changeSpanType(spanType) {
      //console.log(spanType);
      console.log(this.selectedPole);

      this.selectedPole.span_type = DBProject.updatePoleSpanType(
        this.selectedPole,
        this.selectedSpanType
      );
    },
    updateEquips(selectedValues) {
      //console.log("selected equips", selectedValues);
      //console.log("selected equipments", this.selectedEquips);
      let equipIds = selectedValues.map(value => {
        return value._id;
      });
      //console.log("selected equip id", equipIds.toString());
      DBProject.updatePoleEquipments(this.selectedPole, equipIds.toString())
        .then(updatedIDs => {
          //console.log(updatedIDs);
          let updatedEquips = [];
          if (updatedIDs) {
            updatedIDs.split(",").map(id => {
              let equipFound = this.equipments.find(equipment => {
                return id === equipment._id;
              });
              if (equipFound) {
                updatedEquips.push(equipFound);
              }
            });
            this.selectedEquips = updatedEquips;
            //console.log("this.selectedEquips", this.selectedEquips);
          }
        })
        .catch(err => {
          //console.log("Error occurred while updating", err);
        });
    },
    changeDefectPhase(phase) {
      this.$swal({
        title: "Change Defect Phase",
        text: `Do you want to Change Defect Phase to ${phase.name}?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, Change it!",
        cancelButtonText: "No, keep it"
      }).then(async result => {
        if (result.value) {
          this.selectedDefectList.phase = await DBProject.updateDefectPhase(
            this.selectedDefectList.id,
            phase.name
          );
          this.$swal("Changed!", " Defect Phase has been Changed.", "success");
        } else if (result.dismiss === "cancel") {
          this.$swal("Cancelled", "Defect Phase is safe :)", "error");
        }
      });
    },
    previousPoleClick() {
      //console.log(this.$route.params);
      this.$router.push({
        path: `/defect-page/${this.$route.params[`id`]}/poles/${
          this.previousPole.id
        }`
      });
    },
    nextPoleClick() {
      //console.log(this.$route.params);
      this.selectedSpanType = null;
      this.selectedEquips = [];
      this.$router.push({
        path: `/defect-page/${this.$route.params[`id`]}/poles/${
          this.nextPole.id
        }`
      });
      this.$forceUpdate();
    },
    addRemark() {
      //console.log(this.selectedDefectList);
    },
    setIsShowDeleteOption(defect) {
      if (this.selectedDefectToDelete == defect) {
        this.selectedDefectToDelete = null;
      } else {
        this.selectedDefectToDelete = defect;
      }
    },
    imagesForChooseModal() {
      //console.log("imagesForChooseModal");
      //console.log(this.selectedDefectList);
      var allImage =
        this.selectedDefectList.type == "visual"
          ? this.selectedPoleVisual
          : this.images;
      //console.log(allImage);
      var imgs = [];
      allImage.map(img => {
        img.polePath = path.join(this.polePath, img.type.toUpperCase());
        if (
          typeof this.selectedPoleImages.find(
            defectImage => img.id == defectImage.image_id
          ) !== "undefined"
        ) {
          img.isSelected = true;
        } else {
          img.isSelected = false;
        }
      });

      //console.log(allImage);
      return allImage;
    },
    close() {
      this.isShowModal = false;
    },
    closeEditModal() {
      this.editDataType = null;
      this.isShowEditModal = false;
    },
    closeCustomModal() {
      this.isShowCustomDefectModal = false;
    },
    closeDefectModal() {
      this.isShowDefectModal = false;
    },
    async saveDefectModal(defect) {
      //debugger
      this.isShowCustomDefectModal = false;
      defect.image_id = this.selectedImage.image_id;
      defect.pole_id = this.selectedPole.id;
      //console.log(defect);
      // if(!this.isVisual){
      //   //console.log(this.selectedComponent.defect.name)
      //   if(this.selectedComponent.defect.name == 'UNKNOWN' || this.selectedComponent.defect.name == 'ALL' || this.selectedComponent.defect.name == 'RED' ||
      //    this.selectedComponent.defect.name == 'BLUE' || this.selectedComponent.defect.name == 'YELLOW'){
      //     defect.phase = this.selectedComponent.defect.name.toUpperCase()
      //   }
      // }
      this.isVisual ? (defect.type = "visual") : (defect.type = "thermal");
      await this.createDefect(defect);

      this.defects = await DBProject.getDefects(this.selectedPole.id);
    },
    async saveEditModal(newData) {
      //console.log(newData);
      this.isShowEditModal = false;
      // var newDefect = JSON.parse(JSON.stringify(this.selectedDefectList))
      if (newData.type.toLowerCase() == "remark") {
        this.selectedDefectList.remark = newData.newData;
      } else {
        this.selectedDefectList.recommendation = newData.newData;
      }

      await DBProject.updateDefectRemarkRecommendation(this.selectedDefectList);

      // this.selectedDefectList.remark = newDefect.remark
      // this.selectedDefectList.recommendation = newDefect.recommendation
      this.editDataType = null;
      //console.log(this.selectedDefectList);
    },
    save(images) {
      //console.log(images);
      this.isShowModal = false;
      this.selectedPoleImages = images;
      DBProject.updatePoleProgress(this.selectedPole.id, false).then(data => {
        this.selectedPole.completed = false;
        this.isCompleted = false;
        //console.log(this.selectedPole);
      });
    },
    addImages() {
      //console.log("add images to poles");
      // dialog.showOpenDialog(
      //   browserWindow,
      //   {
      //     properties: ["multiSelections"]
      //   },
      //   paths => {
      //     if (typeof paths !== "undefined") {
      //       //console.log(paths);
      //       this.isLoadingAll = true;
      //       this.processAddImage(paths);
      //     }
      //     // this.setFiles(paths[0])
      //   }
      // );
    },

    async processAddImage(images) {
      //console.log(images);
      var imgs = [];
      a.eachSeries(
        images,
        async (imagePath, callback) => {
          //console.log(imagePath);
          var exif = await this.readFileExif(imagePath);
          //console.log(exif.exif);
          // if(exif) {

          // }
          var stats = fs.statSync(imagePath);
          const filename = path.basename(imagePath).replace(".JPG", ".jpg");
          var fileSizeInBytes = stats["size"];

          // //console.log(filename)
          // .replace('.JPG', '.jpg')
          var time = exif
            ? exif.exif.DateTimeOriginal
            : moment().format("YYYY-MM-DD HH:mm");
          //console.log(time);
          //console.log(
          //   `${time.split(" ")[0].replace(/:/g, "-")} ${time.split(" ")[1]}`
          // );
          var img = {
            name: path.basename(imagePath),
            source: imagePath,
            pole_id: this.selectedPole.id,
            isTopDownImage: false,
            image_id: crypto
              .createHash("md5")
              .update(`${fileSizeInBytes}_${filename}`)
              .digest("hex"),
            lat: null,
            lng: null,
            type: this.isVisual ? "visual" : "thermal",
            timestamp: time,
            edited_image_id: null
          };
          if (this.isThermal) {
            var thermaldata = await this.readFlirInfo(imagePath);
            //console.log(thermaldata);
            // .then(async (data) =>{
            // //console.log(data)
            var datas = thermaldata.split(",");
            //console.log(datas);
            img.thermal_data = thermaldata;
            img.thermal_deltaT = datas[datas.length - 1]
              .split(":")[1]
              .replace(/[^0-9.]/g, "");
            imgs.push(img);
            //
            // img.
            // this.selectedImage.thermal_data = data
            // var deltaT = data.split(",");
            // this.selectedImage.thermal_deltaT = (deltaT[deltaT.length - 1]).split(":")[1].replace(/[^0-9.]/g, "")
            // //console.log(deltaT)
            // await DBProject.updateImageThermalData(this.selectedImage)
            // })
          } else {
            imgs.push(img);
          }
          if (callback) {
            callback();
          }
        },
        err => {
          if (err) {
            alert(err.message);
          }
          //console.log(imgs);
          this.saveImages(imgs);
        }
      );
    },
    async saveImages(images) {
      //console.log(images);
      a.eachSeries(
        images,
        async (image, callback) => {
          //console.log(image);
          if (this.isThermal) {
            await DBProject.saveImageThermal(this.selectedPole.id, image);
            var thermalDir = path.join(this.polePath, "THERMAL");
            if (!fs.existsSync(thermalDir)) {
              fs.mkdirSync(thermalDir);
            }
            var imgDir = path.join(thermalDir, image.name);
            //console.log(imgDir);
            //console.log(image.source);

            if (image.source) {
              fs.copyFileSync(image.source, imgDir, err => {
                if (err) {
                  //console.log(err);
                  throw err;
                }
                // //console.log('success');
              });
            }
          } else {
            //console.log(image);
            await DBProject.saveImageVisual(this.selectedPole.id, image);
            var visualDir = path.join(this.polePath, "VISUAL");
            if (!fs.existsSync(visualDir)) {
              fs.mkdirSync(visualDir);
            }
            imgDir = path.join(visualDir, image.name);
            //console.log(imgDir);
            //console.log(image.source);

            if (image.source) {
              fs.copyFileSync(image.source, imgDir, err => {
                if (err) {
                  //console.log(err);
                  throw err;
                }
                // //console.log('success');
              });
            }
          }

          if (callback) {
            callback();
          }
        },
        async err => {
          if (err) alert(err.message);
          //console.log("done add images");
          this.images = await DBProject.getImagesByPoleId(this.selectedPole.id);
          //console.log(this.images);
          this.selectedPoleVisual = this.images.filter(
            image => image.type == "visual"
          );
          this.selectedPoleThermal = this.images.filter(
            image => image.type == "thermal"
          );
          if (this.isVisual) {
            this.selectedPoleImages = this.selectedPoleVisual;
          } else {
            this.selectedPoleImages = this.selectedPoleThermal;
          }
          this.isLoadingAll = false;
        }
      );
    },
    addImageForDefect() {
      //console.log("add images to defect");
      this.isShowModal = true;
    },
    allPhotoClicked() {
      this.isAllPhotoSelected = true;
      this.selectedDefectList = null;
      if (this.isVisual) {
        this.selectedPoleImages = this.selectedPoleVisual;
      } else {
        this.selectedPoleImages = this.selectedPoleThermal;
      }
    },
    async defectListSelected(defect) {
      //console.log(defect);
      this.isAllPhotoSelected = false;
      this.selectedDefectList = defect;
    },
    setPoleCompleteText(isCompleted) {
      return !isCompleted ? "Mark as Completed" : "Completed";
    },
    async setPoleComplete() {
      //console.log("set completed");
      console.log(this.selectedSpanType);

      if (
        this.selectedSpanType !== null &&
        this.selectedSpanType !== undefined
      ) {
        if (this.selectedPole.lat && this.selectedPole.lng) {
          if (this.defects != null && this.defects.length > 0) {
            var complete = true;
            var allDefectsSnapshotExist = true;
            a.eachSeries(
              this.defects,
              async (defect, callback) => {
                var snapShots = await DBProject.getSnapShots(defect.id);
                //console.log(snapShots);
                if (snapShots.length <= 0) {
                  allDefectsSnapshotExist = false;
                }
                // else {
                //   allDefectsSnapshotExist = false
                // }

                if (callback) callback();
              },
              err => {
                if (err) throw err;

                //console.log("allDefectsSnapshotExist");
                //console.log(allDefectsSnapshotExist);
                if (allDefectsSnapshotExist) {
                  complete = true;
                  if (complete) {
                    //console.log(this.selectedPole);
                    DBProject.updatePoleProgress(
                      this.selectedPole.id,
                      true
                    ).then(data => {
                      this.selectedPole.completed = true;
                      this.isCompleted = true;
                      //console.log(this.selectedPole);
                    });
                  }
                } else {
                  complete = false;
                  const options = {
                    buttons: ["Ok"],
                    defaultId: 2,
                    title: "Alert",
                    message: "All defect must have 1 snpashot"
                  };
                  this.$swal(
                    "Alert",
                    "All defect must have 1 snpashot)",
                    "warning"
                  );
                  // dialog.showMessageBox(
                  //   null,
                  //   options,
                  //   (response, checkboxChecked) => {
                  //     //console.log(response);
                  //     //console.log(checkboxChecked);
                  //   }
                  // );
                }
              }
            );
          } else {
            complete = true;
            if (complete) {
              //console.log(this.selectedPole);
              DBProject.updatePoleProgress(this.selectedPole.id, true).then(
                data => {
                  this.selectedPole.completed = true;
                  this.isCompleted = true;
                  //console.log(this.selectedPole);
                }
              );
            }
          }
        } else {
          complete = false;
          const options = {
            buttons: ["Ok"],
            defaultId: 2,
            title: "Alert",
            message: "Please set top down image for pole coordinate"
          };
          this.$swal(
            "Alert",
            "Please set top down image for pole coordinate",
            "warning"
          );
          // dialog.showMessageBox(null, options, (response, checkboxChecked) => {
          //   //console.log(response);
          //   //console.log(checkboxChecked);
          // });
          // complete = true
        }
      } else {
        complete = false;
        const options = {
          buttons: ["Ok"],
          defaultId: 2,
          title: "Alert",
          message: "Please select span type"
        };
        this.$swal("Alert", "Please select span type", "warning");
        // dialog.showMessageBox(null, options, (response, checkboxChecked) => {
        //   //console.log(response);
        //   //console.log(checkboxChecked);
        // });
      }

      // else {

      // }
    },
    drawImage() {
      this.imgMarginTop = 0;
      this.imgMaginLeft = 0;
      this.clearBoxes();
      var HideControls = {
        tl: false,
        tr: false,
        bl: false,
        br: false,
        ml: false,
        mt: false,
        mr: false,
        mb: false,
        mtr: false
      };
      // this.canvas.selection = false
      var self = this;
      var imageName;
      if (this.selectedImage.crop_image_name) {
        imageName = this.selectedImage.crop_image_name;
      } else if (this.selectedImage.imageName) {
        imageName = this.selectedImage.imageName;
      } else {
        imageName = this.selectedImage.name;
      }
      // //console.log(imageName);
      // //console.log(this.selectedImage, "imageName");
      var dir = path.join(this.polePath, this.selectedImage.type.toUpperCase());
      //console.log(this.selectedImage.image_url_large);
      // fabric.Image.fromURL(this.selectedImage.image_url_large,async function(
      //   myImg
      // ) {
      //   var img1 = myImg.set({ left: 0, top: 0, width: 150, height: 150 });
      //   self.canvas.add(img1);
      // });
      fabric.Image.fromURL(
        this.selectedImage.crop_image_name &&
          this.selectedImage.crop_image_name != "null"
          ? this.selectedImage.crop_image_name
          : this.selectedImage.image_url_original
          ? this.selectedImage.image_url_original
          : this.selectedImage.image_url_large,
        // this.selectedImage.image_url_large,
        async image => {
          //console.log(this.canvasContainer, "this.canvasContainer");
          // var r = this.gcd(
          //   this.canvasContainer.width,
          //   this.canvasContainer.height
          // );

          image.setControlsVisibility(HideControls);
          image.scaleToWidth = 4000;
          if (self.canvasImage) {
            self.canvas.remove(self.canvasImage);
            self.canvasImage = null;
          }

          self.canvasImage = image;
          //console.log("this.canvasImage");
          //console.log(self.canvas);
          // //console.log(image)
          var xratio = self.canvasContainer.height / 3;
          if (xratio * 4 > self.canvasContainer.width) {
            self.canvasImage.scaleToWidth(self.canvas.width);
            self.ratio = self.canvas.width / self.canvasImage.width;
          } else {
            self.canvasImage.scaleToHeight(self.canvas.height);
            self.ratio = self.canvas.height / self.canvasImage.height;
          }
          // }

          // this.canvasImage.on('selected', function() {
          //   //console.log('selected Image');
          //   if(!this.isCroping){
          //     // self.selectedBox = null
          //     //console.log(self.selectedBox)
          //   }
          // });

          var zoomPoint = new fabric.Point(
            (self.canvasImage.width * self.ratio) / 2,
            (self.canvasImage.height * self.ratio) / 2
          );
          //console.log(zoomPoint);
          //console.log(self.canvas.getZoom());
          self.canvas.setZoom(1);
          self.canvasImage.top = 0;
          self.canvasImage.left = 0;
          // self.canvas.zoomToPoint(zoomPoint, 1)
          self.canvas.add(self.canvasImage);

          // this.canvas.zoomToPoint(new fabric.Point(this.canvas.width / 2, this.canvas.height / 2), 1);

          self.defaultCoor.x = self.canvasImage.left;
          self.defaultCoor.y = self.canvasImage.top;

          if (this.selectedDefectList) {
            this.getBoxes();
            this.canvasImage.on("mousedblclick", e => {
              //console.log(e);
              self.drawBox(e.pointer);
            });
          }
        },
        {
          selectable: true,
          centeredScaling: true,
          crossOrigin: "anonymous"
        }
      );
    },
    drawImageForSnapshot() {
      return new Promise((resolve, reject) => {
        try {
          this.imgMarginTop = 0;
          this.imgMaginLeft = 0;
          this.clearBoxes();

          var HideControls = {
            tl: false,
            tr: false,
            bl: false,
            br: false,
            ml: false,
            mt: false,
            mr: false,
            mb: false,
            mtr: false
          };
          var self = this;
          var imageName;
          if (this.selectedImage.crop_image_name) {
            imageName = this.selectedImage.crop_image_name;
          } else if (this.selectedImage.imageName) {
            imageName = this.selectedImage.imageName;
          } else {
            imageName = this.selectedImage.name;
          }
          var dir = path.join(
            self.polePath,
            self.selectedImage.type.toUpperCase()
          );
          //console.log("drawImageForSnapshot");
          // console.log(self.setImagePath(dir, imageName),this.selectedImage);
          fabric.Image.fromURL(
            this.selectedImage.crop_image_name &&
              this.selectedImage.crop_image_name != "null"
              ? this.selectedImage.crop_image_name
              : this.selectedImage.image_url_original
              ? this.selectedImage.image_url_original
              : this.selectedImage.image_url_large,
            async image => {
              image.setControlsVisibility(HideControls);
              if (self.canvasImage) {
                self.canvas.remove(self.canvasImage);
                self.canvasImage = null;
              }

              self.canvasImage = image;

              var xratio = self.canvasContainer.height / 3;
              if (xratio * 4 > self.canvasContainer.width) {
                self.canvasImage.scaleToWidth(self.canvasContainer.width);
                self.ratio =
                  self.canvasContainer.width / self.canvasImage.width;
              } else {
                self.canvasImage.scaleToHeight(self.canvasContainer.height);
                self.ratio =
                  self.canvasContainer.height / self.canvasImage.height;
              }
              var zoomPoint = new fabric.Point(
                (self.canvasImage.width * self.ratio) / 2,
                (self.canvasImage.height * self.ratio) / 2
              );

              //console.log(zoomPoint);
              //console.log(self.canvas.getZoom());
              self.canvas.setZoom(1);
              self.canvasImage.top = 0;
              self.canvasImage.left = 0;
              self.canvas.zoomToPoint(zoomPoint, 1);
              self.canvas.add(self.canvasImage);

              self.canvasImage.lockMovementX = true;
              self.canvasImage.lockMovementY = true;
              self.defaultCoor.x = self.canvasImage.left;
              self.defaultCoor.y = self.canvasImage.top;
              self.canvas.sendToBack(self.canvasImage);

              self.canvas.renderAll();
              self.getBoxes();

              resolve();
            },
            {
              selectable: true,
              centeredScaling: false,
              crossOrigin: "anonymous"
            }
          );
        } catch (err) {
          if (err) alert(err);
        }
      });
    },
    drawImageForCroping() {
      return new Promise((resolve, reject) => {
        try {
          this.imgMarginTop = 0;
          this.imgMaginLeft = 0;
          this.clearBoxes();

          var HideControls = {
            tl: false,
            tr: false,
            bl: false,
            br: false,
            ml: false,
            mt: false,
            mr: false,
            mb: false,
            mtr: false
          };
          var self = this;

          var dir = path.join(
            this.polePath,
            this.selectedImage.type.toUpperCase()
          );
          //console.log(this.setImagePath(dir, this.selectedImage.name));
          fabric.Image.fromURL(
            // this.setImagePath(dir, this.selectedImage.name),
            this.selectedImage.crop_image_name &&
              this.selectedImage.crop_image_name != "null"
              ? this.selectedImage.crop_image_name
              : this.selectedImage.image_url_original
              ? this.selectedImage.image_url_original
              : this.selectedImage.image_url_large,
            async image => {
              image.setControlsVisibility(HideControls);
              //console.log(self.canvasImage);
              if (self.canvasImage) {
                self.canvas.remove(self.canvasImage);
                self.canvasImage = null;
              }
              //console.log(self.canvasImage);

              self.canvasImage = image;

              var xratio = self.canvasContainer.height / 3;
              if (xratio * 4 > self.canvasContainer.width) {
                self.canvasImage.scaleToWidth(self.canvasContainer.width);
                self.ratio =
                  self.canvasContainer.width / self.canvasImage.width;
              } else {
                self.canvasImage.scaleToHeight(self.canvasContainer.height);
                self.ratio =
                  self.canvasContainer.height / self.canvasImage.height;
              }
              var zoomPoint = new fabric.Point(
                (self.canvasImage.width * self.ratio) / 2,
                (self.canvasImage.height * self.ratio) / 2
              );

              //console.log(zoomPoint);
              //console.log(self.canvas.getZoom());
              self.canvas.setZoom(1);
              self.canvasImage.top = 0;
              self.canvasImage.left = 0;
              self.canvas.zoomToPoint(zoomPoint, 1);
              self.canvas.add(self.canvasImage);

              self.canvasImage.lockMovementX = true;
              self.canvasImage.lockMovementY = true;
              self.defaultCoor.x = self.canvasImage.left;
              self.defaultCoor.y = self.canvasImage.top;
              self.canvas.sendToBack(self.canvasImage);

              self.canvas.renderAll();

              resolve();
              // this.getBoxes()
            },
            {
              selectable: true,
              centeredScaling: false,
              crossOrigin: "anonymous"
            }
          );
        } catch (err) {
          if (err) alert(err.message);
          reject(err.message);
        }
      });
    },
    clipBySlot(ctx, image, slot) {
      var scaleXTo1 = 1 / image.scaleX;
      var scaleYTo1 = 1 / image.scaleY;

      // Save context of the canvas so it can be restored after the clipping
      ctx.save();

      //ctx.translate(0, 0);
      ctx.rotate(this.degToRad(image.angle * -1));
      ctx.scale(scaleXTo1, scaleYTo1);
      ////console.log("ctx scale", ctx.scaleX, "x", ctx.scaleY);

      ctx.beginPath();

      const boundingRect = image.getBoundingRect();
      ////console.log(`[left] ${image.left} - (${boundingRect.width} / 2)`);

      ctx.rect(
        slot.left - image.left - Math.floor(boundingRect.width / 2),
        slot.top - image.top - Math.floor(boundingRect.height / 2),
        slot.width,
        slot.height
      );
      //ctx.stroke()
      ctx.closePath();

      // Restore the original context.
      ctx.restore();
    },
    degToRad(degrees) {
      return degrees * (Math.PI / 180);
    },
    cancelCroping() {
      this.isCroping = false;
      this.selectedSnapShot = null;
      // this.canvas.remove(this.selectedBox)
      // this.canvas.remove(this.selectedBox.text)
      this.resetCanvas();
      this.selectedBox = null;
      this.canvas.remove(this.cropBox);
      this.cropBox = null;
    },
    saveCropedImage() {
      this.isLoading = true;
      this.canvas.remove(this.cropBox);

      setTimeout(async () => {
        var data = await this.saveBoxAsImage(this.cropBox);
        if (this.cropBox.isCrop) {
          await DBProject.updateImageCroppedPath(
            this.selectedImage.image_id,
            data
          );
          this.cropBox = null;
          this.selectedImage.crop_image_name = data;
          DBProject.updatePoleProgress(this.selectedPole.id, false).then(
            data => {
              this.selectedPole.completed = false;
              this.isCompleted = false;
              //console.log(this.selectedPole);
              this.isLoading = false;
            }
          );
        } else {
          this.selectedSnapShot.name = data;
          //console.log(this.selectedSnapShot);
          this.cropBox = null;
          await DBProject.saveSnapShotPath(this.selectedSnapShot);
          DBProject.updatePoleProgress(this.selectedPole.id, false).then(
            data => {
              this.selectedPole.completed = false;
              this.isCompleted = false;
              //console.log(this.selectedPole);

              this.isLoading = false;
            }
          );
          // this.selectedDefectSnapShots = await DBProject.saveSnapShotPath(this.selectedSnapShot)
          var selectedSnapShots = await DBProject.getSnapShots(
            this.selectedDefectList.id
          );
          var dummySnapshots = [];
          //console.log(selectedSnapShots);
          var numOfss = this.selectedDefectList.type == "visual" ? 4 : 2;
          for (let index = 0; index < numOfss; index++) {
            dummySnapshots.push({
              defect_id: this.selectedDefectList.id,
              name: null,
              position: index + 1
            });
          }
          dummySnapshots.forEach(dummySnapshot => {
            selectedSnapShots.forEach(snapshot => {
              if (snapshot.position == dummySnapshot.position) {
                //console.log(dummySnapshot);
                dummySnapshot.id = snapshot.id;
                dummySnapshot.name = snapshot.name;
              }
            });
          });
          //console.log(dummySnapshots);
          this.selectedDefectSnapShots = dummySnapshots;
        }

        this.selectedSnapShot = null;
        this.isCroping = false;
        this.canvas.remove(this.cropBox);
        this.resetCanvas();
        // });
      }, 500);
    },

    saveBoxAsImage(box) {
      return new Promise(async (resolve, reject) => {
        //console.log("saveBoxAsImage");
        var self = this;
        // var box = this.cropBox
        //console.log(this.cropBox);
        if (box.isCrop) {
          box.top = box.top / this.ratio;
          box.left = box.left / this.ratio;
          box.height = (box.height * box.scaleY) / this.ratio;
          box.width = (box.width * box.scaleX) / this.ratio;
          // //console.log(this.cropBox)

          var dir = path.join(
            this.polePath,
            this.selectedImage.type.toUpperCase()
          );
          const output = path.join(
            dir,
            `${this.selectedImage.name.replace(/\.[^/.]+$/, "")}_croped.jpeg`
          );
          console.log(this.setImagePath(dir, this.selectedImage.name));
          var crop_URL = await API.post("cropimage", {
            spec: {
              left: Math.floor(box.left),
              top: Math.floor(box.top),
              width: Math.floor(box.width),
              height: Math.floor(box.height)
            },
            url: this.selectedImage.image_url_original
              ? this.selectedImage.image_url_original
              : this.selectedImage.image_url_large,
            path: output,
            dir: dir
          });
          debugger;
          resolve(crop_URL.data.url);
          // sharp(this.setImagePath(dir, this.selectedImage.image_url_large))
          //   .extract({
          //     left: Math.floor(box.left),
          //     top: Math.floor(box.top),
          //     width: Math.floor(box.width),
          //     height: Math.floor(box.height)
          //   })
          //   .quality(100)
          //   .toFile(output, function(err) {
          //     if (err) throw err;
          //     resolve(output);
          // Extract a region of the input image, saving in the same format.
          // });
        } else {
          self.isSaveCropImage = false;
          //console.log(this.ratio);
          // this.canvas.getObjects().forEach(obj =>{
          //   if(typeof obj.boxId !== 'undefined'){
          //     //console.log('box before')
          //     //console.log(obj.height)
          //     var fontsize = obj.text.fontSize
          //     obj.height = (obj.height * obj.scaleY) / this.ratio
          //     obj.width = (obj.width * obj.scaleX) / this.ratio
          //     obj.left = obj.left / this.ratio
          //     obj.top = obj.top / this.ratio
          //     obj.strokeWidth = obj.strokeWidth / this.ratio
          //     obj.text.left = obj.left
          //     obj.text.fontSize = fontsize / this.ratio
          //     obj.text.top = obj.top - (fontsize * this.ratio)
          //     //console.log((obj.height))
          //   }
          // })

          var currentWidth = box.width;
          var newWidth = (currentWidth * box.scaleX) / this.ratio;
          //console.log(box.scaleX);
          //console.log(newWidth);
          var newHeight = (currentWidth * box.scaleX) / this.ratio;
          //console.log(newHeight);
          var actBoxHeight = (box.height * box.scaleY) / this.ratio;
          var actBoxWidth = (box.width * box.scaleX) / this.ratio;
          var actBoxTop = box.top / this.ratio;
          var actBoxLeft = box.left / this.ratio;

          var currentscaleX = box.scaleX;
          // box.top = box.top / this.ratio
          // box.left = box.left / this.ratio
          // box.height = (box.height * box.scaleY) / this.ratio
          // box.width = (box.width * box.scaleX) / this.ratio
          // var ratioTo1024 = 1024 / (currentWidth / this.ratio)
          var ratioTo1024 = 1024 / actBoxWidth;
          //console.log("this.cropBox");
          //console.log(box.width);
          //console.log(currentWidth / this.ratio);
          //console.log(actBoxWidth);
          //console.log(ratioTo1024);

          // this.canvas.getObjects().forEach(obj =>{
          //     //console.log('obj')
          //     //console.log(obj)
          //   if(typeof obj.boxId !== 'undefined'){
          //     //console.log('box')
          //     //console.log(obj)
          //     var fontsize = obj.text.fontSize
          //     obj.height = (obj.height * obj.scaleY) * ratioTo1024
          //     obj.width = (obj.width * obj.scaleX) * ratioTo1024
          //     obj.left = obj.left * ratioTo1024
          //     obj.top = obj.top * ratioTo1024
          //     obj.strokeWidth = obj.strokeWidth * ratioTo1024
          //     obj.text.left = obj.left
          //     obj.text.fontSize = fontsize * ratioTo1024
          //     obj.text.top = obj.top - (fontsize * ratioTo1024)
          //   }
          // })

          var object = this.canvas.getObjects();
          for (const obj of object) {
            if (typeof obj.boxId !== "undefined") {
              console.log("box");
              console.log(obj);
              // var fontsize = obj.text.fontSize
              // obj.height = obj.height * ratioTo1024
              // obj.width = obj.width * ratioTo1024
              // obj.left = obj.left * ratioTo1024
              // obj.top = obj.top * ratioTo1024
              // obj.strokeWidth = obj.strokeWidth * ratioTo1024
              // obj.text.left = obj.left
              // obj.text.fontSize = fontsize * ratioTo1024
              // obj.text.top = obj.top - (fontsize * ratioTo1024)

              var fontsize = obj.text.fontSize;
              var strokeWidth = obj.strokeWidth / this.ratio;
              console.log(obj.strokeWidth);
              console.log(strokeWidth);
              obj.height = (obj.height * obj.scaleY) / this.ratio;
              obj.width = (obj.width * obj.scaleX) / this.ratio;
              obj.scaleX = ratioTo1024;
              obj.scaleY = ratioTo1024;
              obj.left = (obj.left / this.ratio) * ratioTo1024;
              obj.top = (obj.top / this.ratio) * ratioTo1024;
              obj.strokeWidth = strokeWidth;
              obj.text.left = obj.left;
              obj.text.fontSize = (fontsize / this.ratio) * ratioTo1024;
              obj.text.top = obj.top - (fontsize / this.ratio) * ratioTo1024;
              console.log(obj);
              console.log(obj.strokeWidth);
            }
          }
          // async.eachSeries(
          //   self.canvas.getObjects(),
          //   (obj, callback) => {
          //     // //console.log('obj')
          //     //   //console.log(obj)
          //     if (typeof obj.boxId !== "undefined") {
          //       //console.log("box");
          //       //console.log(obj);
          //       // var fontsize = obj.text.fontSize
          //       // obj.height = obj.height * ratioTo1024
          //       // obj.width = obj.width * ratioTo1024
          //       // obj.left = obj.left * ratioTo1024
          //       // obj.top = obj.top * ratioTo1024
          //       // obj.strokeWidth = obj.strokeWidth * ratioTo1024
          //       // obj.text.left = obj.left
          //       // obj.text.fontSize = fontsize * ratioTo1024
          //       // obj.text.top = obj.top - (fontsize * ratioTo1024)

          //       var fontsize = obj.text.fontSize;
          //       var strokeWidth = obj.strokeWidth / this.ratio;
          //       //console.log(obj.strokeWidth);
          //       //console.log(strokeWidth);
          //       obj.height = (obj.height * obj.scaleY) / this.ratio;
          //       obj.width = (obj.width * obj.scaleX) / this.ratio;
          //       obj.scaleX = ratioTo1024;
          //       obj.scaleY = ratioTo1024;
          //       obj.left = (obj.left / this.ratio) * ratioTo1024;
          //       obj.top = (obj.top / this.ratio) * ratioTo1024;
          //       obj.strokeWidth = strokeWidth;
          //       obj.text.left = obj.left;
          //       obj.text.fontSize = (fontsize / this.ratio) * ratioTo1024;
          //       obj.text.top = obj.top - (fontsize / this.ratio) * ratioTo1024;
          //       //console.log(obj);
          //       //console.log(obj.strokeWidth);
          //     }

          //     if (callback) callback();
          //   },
          // err => {
          // if (!err) {
          this.canvas.renderAll();
          box.top = actBoxTop * ratioTo1024;
          box.left = actBoxLeft * ratioTo1024;
          box.height = actBoxHeight * ratioTo1024;
          box.width = actBoxHeight * ratioTo1024;
          //console.log(box);

          this.canvasImage.left = 0;
          this.canvasImage.top = 0;
          this.canvasImage.scaleY = ratioTo1024;
          this.canvasImage.scaleX = ratioTo1024;
          this.canvas.setHeight(768);
          this.canvas.setWidth(1024);

          console.log(this.canvasImage);

          var img = this.selectedImage;
          var cvs = this.canvas;
          //console.log(this.selectedSnapShot);

          try {
            const dataURL = cvs.toDataURL({
              top: box.top,
              left: box.left,
              format: "jpeg",
              quality: 0.6
            });

            var dir =
              this.polePath + "/" + this.selectedImage.defectType.toUpperCase();
            const output =
              dir +
              "/" +
              `${slugify(img.component)}_${slugify(img.name)}_${slugify(
                img.severity
              )}_${self.selectedSnapShot.position}_snapshot.jpeg`;

            console.log(output);
            // // const output = path.join(polePath, `${this.selectedImage.name.replace(/\.[^/.]+$/, "")}_croped.jpeg`)
            // const matches = dataURL.match(/^data:.+\/(.+);base64,(.*)$/);
            // const ext = matches[1];
            // const data = matches[2];
            // const buffer = Buffer.from(data, "base64");

            // fs.writeFile(output, buffer, err => {
            //   if (err) {
            //     alert(err.message);
            //     return reject(err);
            //   }
            var data1 = await API.post("writeimage", {
              output: output,
              buffer: dataURL
            });
            //console.log("success crop");
            resolve(data1.data.url);
            // });
          } catch (err) {
            if (err) reject(err);
          }
        }
      });
      // );
      // }
      // });
    },

    // changeImageToActualRatio(){
    //   return new Promise(async(resolve, reject) => {
    //     try {

    //       resolve()
    //     } catch(err){
    //       reject(err)
    //     }
    //   })
    // },
    async setCrop() {
      this.isCroping = true;
      await this.drawImageForCroping();
      var HideControls = {
        tl: true,
        tr: true,
        bl: true,
        br: true,
        ml: false,
        mt: false,
        mr: false,
        mb: false,
        mtr: false
      };

      var width = this.canvasImage.width * this.canvasImage.scaleX * 0.9;
      //console.log("setCrop");
      //console.log(this.canvasImage.width * this.canvasImage.scaleX);
      //console.log(this.selectedImage.type);
      var height =
        this.selectedImage.type == "visual" ? (width / 4) * 3 : (width / 5) * 4;
      const rect = new fabric.Rect({
        fill: "transparent",
        stroke: "red",
        strokeWidth: "3",
        strokeDashArray: [10, 10],
        width: width,
        height: height,
        top: 25,
        left: 25
      });
      rect.isCrop = true;
      // rect.centeredScaling = true
      // //console.log('crop rect')
      rect.setControlsVisibility(HideControls);
      this.cropBox = rect;
      // //console.log(this.cropBox)
      // this.canvas.centerObject(rect)
      this.canvas.add(rect);
      this.canvas.setActiveObject(rect);
    },
    async addScreenShotBox(snapShot) {
      this.isCroping = true;
      this.selectedSnapShot = snapShot;
      //console.log(this.selectedSnapShot);
      await this.drawImageForSnapshot();
      var HideControls = {
        tl: true,
        tr: true,
        bl: true,
        br: true,
        ml: false,
        mt: false,
        mr: false,
        mb: false,
        mtr: false
      };

      var width = this.canvasImage.width * this.canvasImage.scaleX * 0.85;
      //console.log("addScreenShotBox");
      //console.log(this.canvasImage.width * this.canvasImage.scaleX);
      //console.log(width);
      var height =
        this.selectedImage.type == "visual" ? (width / 4) * 3 : (width / 5) * 4;
      //console.log(height);
      // var height = (width / 4) * 3
      const rect = new fabric.Rect({
        fill: "transparent",
        stroke: "red",
        strokeWidth: "3",
        strokeDashArray: [10, 10],
        width: width,
        height: height,
        top: this.canvas.height * 0.01,
        left: 25
      });
      rect.isSnapShot = true;
      // rect.centeredScaling = true
      // //console.log('crop rect')
      rect.setControlsVisibility(HideControls);
      this.cropBox = rect;

      //console.log(this.cropBox);
      // this.canvas.centerObject(rect)
      this.canvas.add(this.cropBox);
      this.canvas.setActiveObject(this.cropBox);
    },
    // async readFlirInfo(imgPath) {
    //   return new Promise(async (resolve, reject) => {
    //     try {
    //       if (imgPath) {
    //         //console.log(path.join(app.getAppPath(), "Release/FlirConsole.exe"));
    //         var flirExePath = path.join(
    //           app.getAppPath(),
    //           "Release/FlirConsole.exe"
    //         );
    //         //console.log(flirExePath);
    //         const flirInfo = execFile(
    //           `${flirExePath}`,
    //           [`${imgPath}`],
    //           (err, stdout, stderr) => {
    //             if (err) {
    //               console.error(`exec error: ${err}`);
    //               return;
    //             }

    //             //console.log(`Number of files ${stdout}`);
    //             if (stdout) {
    //               resolve(stdout.toString().replace("/\r?\n|\r/", ""));
    //             }
    //           }
    //         );
    //       }
    //     } catch (err) {
    //       throw err;
    //     }
    //   });
    // },

    gcd(a, b) {
      // // //console.log(a)
      return b == 0 ? a : this.gcd(b, a % b);
    },

    async setPoleNumberImage() {
      // var dir = path.join(this.polePath, this.selectedImage.type.toUpperCase());
      // var imgDir = path.join(dir, this.selectedImage.name);
      console.log(this.selectedImage);
      var resizedPath = await this.resizeImage(
        this.selectedImage,
        this.polePath
      );
      await DBProject.updateImagePoleNumber(
        this.poleNumberImage,
        this.selectedImage
      );
      //console.log(resizedPath);
      this.selectedPoleImages = this.selectedPoleImages.map(image => {
        if (this.selectedImage.image_id == image.image_id) {
          image.isPoleNumber = true;
        } else {
          image.isPoleNumber = false;
        }
        return image;
      });
    },
    async resizeImage(image, polePath) {
      //console.log(image);
      // return new Promise((resolve, reject) => {
      //   try {
      //     //console.log(image);
      //     var dir = path.join(polePath, image.type.toUpperCase());
      //     //console.log(dir);
      //     var imagePath = image.crop_image_name
      //       ? `${path.join(dir, image.crop_image_name)}`
      //       : `${path.join(dir, image.name)}`;
      //     //console.log(imagePath);
      //     const resize = size =>
      //       sharp(imagePath)
      //         .resize(size, (size / 4) * 3)
      //         .toFile(
      //           `${path.join(dir, image.name.replace(".", `-${size}.`))}`
      //         );

      //     Promise.all([1024, 720, 200].map(resize)).then(err => {
      //       //console.log(`complete ${image.name}`);
      //       // resolve()
      //       var imagePath = {
      //         large: null,
      //         med: null,
      //         thumb: null
      //       };

      //       if (
      //         fs.existsSync(
      //           `${path.join(dir, image.name.replace(".", "-1024."))}`
      //         )
      //       ) {
      //         //console.log("large exist");
      //         imagePath.large = `${path.join(
      //           dir,
      //           image.name.replace(".", "-1024.")
      //         )}`;
      //       }

      //       if (
      //         fs.existsSync(
      //           `${path.join(dir, image.name.replace(".", "-720."))}`
      //         )
      //       ) {
      //         //console.log("med exist");
      //         imagePath.med = `${path.join(
      //           dir,
      //           image.name.replace(".", "-720.")
      //         )}`;
      //       }

      //       if (
      //         fs.existsSync(
      //           `${path.join(dir, image.name.replace(".", "-200."))}`
      //         )
      //       ) {
      //         //console.log("thumb exist");
      //         imagePath.thumb = `${path.join(
      //           dir,
      //           image.name.replace(".", "-200.")
      //         )}`;
      //       }
      //       //console.log(imagePath);
      //       resolve(imagePath);
      //     });
      //     // resolve()
      //   } catch (err) {
      //     if (err) {
      //       alert(err.message);
      //       reject(err);
      //     }
      //   }
      // });
      var datatosend = {
        URL: image.image_url_original
          ? image.image_url_original
          : image.image_url_large,
        name: image.name,
        type: image.type,
        polepath: polePath
      };
      var data = await API.post("resize", datatosend);
      return data.data;
    },
    async setTopDown() {
      var dir = path.join(this.polePath, this.selectedImage.type.toUpperCase());
      var data = await this.readFileExif(
        path.join(dir, this.selectedImage.name)
      );
      // //console.log(data)
      var latlng = {
        lat: this.ConvertDMSToDD(
          data.gps.GPSLatitude[0],
          data.gps.GPSLatitude[1],
          data.gps.GPSLatitude[2],
          data.gps.GPSLatitudeRef
        ),
        lng: this.ConvertDMSToDD(
          data.gps.GPSLongitude[0],
          data.gps.GPSLongitude[1],
          data.gps.GPSLongitude[2],
          data.gps.GPSLongitudeRef
        )
      };
      // //console.log(this.selectedPole)
      this.selectedPole.lat = latlng.lat;
      this.selectedPole.lng = latlng.lng;

      await DBProject.updateTopDown(this.topDownImage, this.selectedImage);
      await DBProject.updatePoleCoordinate(this.selectedPole);
      DBProject.updatePoleProgress(this.selectedPole.id, false).then(data => {
        this.selectedPole.completed = false;
        this.isCompleted = false;
        //console.log(this.selectedPole);
      });

      this.selectedPoleImages = this.selectedPoleImages.map(image => {
        if (this.selectedImage.image_id == image.image_id) {
          image.isTopDownImage = true;
        } else {
          image.isTopDownImage = false;
        }
        return image;
      });
    },
    ConvertDMSToDD(degrees, minutes, seconds, direction) {
      var dd = degrees + minutes / 60 + seconds / (60 * 60);

      if (direction == "S" || direction == "W") {
        dd = dd * -1;
      } // Don't do anything for N or E
      return dd;
    },
    // readFlirInfo(path){
    //   //console.log("fun() start");
    //   //console.log(path)
    //   const flirInfo = spawn('./Release/FlirConsole.exe', [`${path}`]);

    //   flirInfo.stdout.on('data', (data) => {
    //     //console.log(`stdout: ${data}`)
    //   })

    // },
    readFileExif(file) {
      return new Promise((resolve, reject) => {
        try {
          new ExifImage(
            {
              image: file
            },
            (err, data) => {
              if (err) return reject(err);

              resolve(data);
            }
          );
        } catch (err) {
          reject(err);
        }
      });
    },
    setStatusColor(status) {
      var text;
      switch (status) {
        case "success":
          text = "success";
          break;
        case "failed":
          text = "danger";
          break;
        case "":
          text = "warning";
          break;
        default:
          text = "warning";
      }

      return text;
    },
    visualTabClicked() {
      this.isVisual = true;
      this.isThermal = false;
      if (this.selectedProject.inspectionType == "ABC") {
        this.components = componentsJson;
      } else {
        this.components = componentsBAREJson;
      }
      this.isAllPhotoSelected = true;
      this.selectedDefectList = null;
      this.selectedPoleImages = this.selectedPoleVisual;
    },
    thermalTabClicked() {
      this.isThermal = true;
      this.isVisual = false;
      this.components = componentsThermalJson;
      //console.log(this.components);
      this.selectedDefectList = null;
      //console.log(this.selectedPoleThermal);
      // this.selectedPoleThermal.sort((a,b) => (a.thermal_deltaT < b.thermal_deltaT) ? 1 : ((b.thermal_deltaT < a.thermal_deltaT) ? -1 : 0))
      this.selectedPoleImages = this.selectedPoleThermal.sort((a, b) =>
        a.thermal_deltaT < b.thermal_deltaT
          ? 1
          : b.thermal_deltaT < a.thermal_deltaT
          ? -1
          : 0
      );
    },
    setImagePath(folder, filename) {
      return path.join(folder, filename);
    },

    setSnapshotPath(filename, flag) {
      // console.log(this.selectedImage, "ghbgfhgfhfghfgh");
      //console.log(filename);
      // var dir = path.join(
      //   this.polePath,
      //   this.selectedImage.defectType.toUpperCase()
      // );
      // //console.log(path.join(dir, filename));
      if (flag == 1) {
        var replaceable = this.selectedImage.imageName.replace(".", "-200.");
        var dir = `${filename.replace(".", "-1024.")}`;
        //console.log(
        //   this.selectedImage.image_url_thumb.replace(replaceable, dir)
        // );
      } else {
        var replaceable = this.selectedImage.imageName.replace(".", "-200.");
        var dir = `${filename.replace(".", "-200.")}`;
        //console.log(
        //   this.selectedImage.image_url_thumb.replace(replaceable, dir)
        // );
      }
      if (filename.includes("http")) {
        return filename;
      } else {
        var url = this.selectedImage.image_url_thumb.replace(replaceable, dir);
        console.log(url);
        return url;
      }
    },
    setImagePathToShow(image) {
      var name;
      if (image.crop_image_name) {
        name = image.crop_image_name;
      } else if (image.imageName) {
        name = image.imageName;
      } else {
        name = image.name;
      }
      // debugger
      //  image.crop_image_name ? image.crop_image_name : image.name
      // console.log(image);
      // var dir = path.join(this.polePath, image.type.toUpperCase());
      console.log(image);

      return image.crop_image_name && image.crop_image_name != "null"
        ? image.crop_image_name
        : image.image_url_thumb == "null"
        ? image.image_url_original
        : image.image_url_thumb;
      // return image.image_url_thumb;
    },
    setImageThumbPathToShow(image) {
      // //console.log(image, "setImageThumbPathToShow");
      var name;
      if (image.crop_image_name) {
        name = image.crop_image_name;
      } else if (image.imageName) {
        name = image.imageName;
      } else {
        name = image.name;
      }
      //  image.crop_image_name ? image.crop_image_name : image.name
      // //console.log(name)
      var dir = path.join(this.polePath, image.type.toUpperCase());
      console.log(image.crop_image_name, "image.crop_image_name");

      return image.crop_image_name && image.crop_image_name != "null"
        ? image.crop_image_name
        : image.image_url_thumb;
    },

    removeBoxes(rect, text) {
      //console.log("remove object");
      this.canvas.getObjects().forEach(function(o) {
        if (o != rect || o != text) {
          o.set({
            strokeWidth: 0,
            fill: "transparent",
            background: "transparent"
          });
        }
      });
    },

    setBoxStroke(severity) {
      // //console.log(severity)
      var color;
      switch (severity.toLowerCase()) {
        case "notify":
          color = "#FF00FF";
          break;
        case "low":
          color = "blue";
          break;
        case "cautious":
          color = "yellow";
          break;
        case "critical":
          color = "red";
          break;
        default:
          color = "red";
      }
      return color;
    },

    severityClass(severity) {
      // //console.log('severityClass')
      // //console.log(severity.toLowerCase())
      var bagdeClass;
      switch (severity.toLowerCase()) {
        case "low":
          bagdeClass = "badge-primary";
          break;
        case "cautious":
          bagdeClass = "badge-warning";
          break;
        case "critical":
          bagdeClass = "badge-danger";
          break;
        default:
          bagdeClass = "badge-noseverity";
      }
      return bagdeClass;
    },

    async getBoxes() {
      const boxes = await DBProject.getBoxesByImageId(
        this.selectedImage.defects_images_id
      );
      // //console.log(boxes)
      var self = this;
      boxes.forEach(box => {
        // //console.log(box.severity)

        // e.target.actLeft = e.target.left - this.imgMaginLeft
        // e.target.actTop = e.target.top - this.imgMarginTop
        // e.target.left += this.imgMaginLeft
        // e.target.top += this.imgMarginTop
        // //console.log(this.imgMarginTop)
        const rect = new fabric.Rect({
          left: box.x * this.ratio,
          top: box.y * this.ratio,
          fill: "transparent",
          strokeWidth: 3,
          width: box.width * this.ratio,
          height: box.height * this.ratio
        });

        rect.stroke = this.setBoxStroke(box.severity);

        // //console.log(box)

        // const component = this.components.find(c => c.name == box.component)
        rect.component = box.component;

        // const defect = component.defects.find(d => d.name == box.defect)
        rect.defect = box.name;

        const severity = box.severity;
        rect.severity = severity;
        // //console.log(rect)

        const text = new fabric.Text(`${box.component}: ${box.name}`, {
          fill: "white",
          fontFamily: "Arial",
          fontSize: 12,
          left: rect.left,
          top: rect.top - 15
        });

        var colorText =
          box.severity.toLowerCase() == "cautious" ? "black" : "white";
        text.set("fill", colorText);
        // text.set('backgroundColor', `${this.setBoxStroke(this.selectedDefectList.severity)}`)
        text.set("backgroundColor", `${this.setBoxStroke(box.severity)}`);
        text.selectable = false;
        rect.text = text;
        rect.boxId = box.id;
        rect.on("selected", function() {
          //console.log("selected a rectangle");
          self.selectedBox = rect;
          ////console.log(self.selectedBox)
        });
        // rect.on('selection:cleared', function() {
        //   //console.log('selected a rectangle');
        // });
        this.texts.push(text);
        this.canvas.add(text);
        this.rects.push(rect);
        this.canvas.add(rect);
      });

      // this.canvas.getObjects().forEach(function(o) {
      // //console.log(o)
      // if(o.defect && o.defect !== 'undefined'){
      //   o.lockMovementX = true
      //   o.lockMovementY = true
      // }
      // });
    },
    openDeleteDialog(defect) {
      this.$swal({
        title: "Delete Defect",
        text: `Do you want to delete this defect (${defect.name})?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, keep it"
      }).then(async result => {
        if (result.value) {
          await this.deleteDefect(defect);
          this.$swal("Deleted!", "Defect has been deleted.", "success");
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          this.$swal("Cancelled", "Your imaginary file is safe :)", "error");
        }
      });
    },

    async deleteDefect(defect) {
      try {
        this.defects = await DBProject.deleteDefect(
          defect,
          this.selectedPole.id
        );
        this.allPhotoClicked();
        //console.log(this.defects);
      } catch (err) {
        if (err) {
          alert(err.message);
        }
      }
    },

    // detectDefect() {
    //   // //console.log(this.selectedImage)
    //   try {
    //     this.loadingMsg = 'Detecting defect..'
    //     this.isLoading = true
    //     if (this.isOnline()) {
    //       request.post({
    //         url: 'https://ai.aerohub.co/visual',
    //         formData: {
    //           file: fs.createReadStream(path.join(this.selectedImage.directory, this.selectedImage.name))
    //         },
    //       }, (err, response, body) => {
    //         if (err) {
    //           this.loadingMsg = null
    //           this.isLoading = false
    //           this.status = 'failed'
    //           this.alertMsg = `Something when wrong`
    //           this.isShowAlert = true
    //           throw err
    //         }

    //         const data = JSON.parse(body)
    //         // //console.log(data)
    //         var defects = []
    //         if (data.issues.length > 0) {
    //           this.alertMsg = `Defect detected : ${data.issues.length}`
    //           this.status = 'success'
    //           this.isShowAlert = true
    //           data.issues.forEach(issue => {
    //             defects.push({
    //               image_id: this.selectedImage.id,
    //               component: issue.issue,
    //               defect: issue.issue,
    //               severity: issue.issue,
    //               x: issue.x1,
    //               y: issue.y1,
    //               width: issue.width,
    //               height: issue.height
    //             })
    //           })
    //           a.eachSeries(defects, async(defect, callback) => {
    //             await DBProject.saveBox(defect.boxId, defect)
    //             // //console.log(img)
    //             if (callback)
    //               callback()
    //           }, err => {
    //             if (err)
    //               return reject(err)

    //             // resolve()

    //             this.getBoxes()
    //             this.loadingMsg = null
    //             this.isLoading = false
    //           })
    //           // //console.log(defects)
    //         } else {
    //           this.alertMsg = `No defect detected`
    //           this.status = 'success'
    //           this.isShowAlert = true
    //         }

    //         this.loadingMsg = null
    //         this.isLoading = false
    //       })

    //     } else {
    //       this.loadingMsg = null
    //       this.isLoading = false
    //       this.alertMsg = `Please connect to internet`
    //       this.status = 'failed'
    //       this.isShowAlert = true
    //     }
    //   } catch (err) {
    //     console.error(err.message)
    //     alert(err.message)
    //     this.isLoading = false
    //     this.next()
    //   }
    // },
    isOnline() {
      return navigator.onLine;
    },
    selectDefect(component, defect) {
      // for v 3
      this.isShowingSeverityList = true;

      this.selectedComponent.component = component;
      this.selectedComponent.defect = defect;
      // //console.log(this.selectedComponent)

      // for v2
      // this.isShowingDefectList = false
      // this.selectedComponent.defect = defect
      // const rect = new fabric.Rect({
      //   left: 100,
      //   top: 100,
      //   fill: 'transparent',
      //   stroke: 'red',
      //   strokeWidth: 3,
      //   width: 200,
      //   height: 200
      // })

      // const text = new fabric.Text(`${this.selectedComponent.defect.name}`, {
      //   fill: 'white',
      //   fontFamily: 'Arial',
      //   fontSize: 10,
      //   left: 100,
      //   top: 100 - 15
      // })

      // text.set('backgroundColor', 'red')
      // rect.text = text
      // rect.defect = this.selectedComponent.defect

      // this.texts.push(text)
      // this.canvas.add(text)
      // this.rects.push(rect)
      // this.canvas.add(rect)
    },

    drawBox(cursorCoor) {
      //console.log(cursorCoor);
      var topLeft = cursorCoor
        ? { left: cursorCoor.x, top: cursorCoor.y }
        : { left: this.canvasImage.left + 20, top: this.canvasImage.top + 20 };
      //console.log(topLeft);
      const rect = new fabric.Rect({
        left: topLeft.left,
        top: topLeft.top,
        fill: "transparent",
        strokeWidth: 3,
        width: this.canvasContainer.width * 0.1,
        height: this.canvasContainer.width * 0.1,
        noScaleCache: true
      });
      //console.log(rect);
      rect.stroke = this.setBoxStroke(this.selectedDefectList.severity);
      var self = this;
      // rect.on('selected', function() {
      //     // //console.log('selected a rectangle');
      //       self.selectedBox = rect
      //       //console.log(self.selectedBox)
      //   });
      // //console.log(rect)
      const text = new fabric.Text(
        `${this.selectedDefectList.component}: ${this.selectedDefectList.name}`,
        {
          fontFamily: "Arial",
          fontSize: 10,
          left: rect.left,
          top: rect.top - 10
        }
      );
      var colorText =
        this.selectedDefectList.severity.toLowerCase() == "cautious"
          ? "black"
          : "white";
      text.set("fill", colorText);
      text.set(
        "backgroundColor",
        `${this.setBoxStroke(this.selectedDefectList.severity)}`
      );
      rect.text = text;
      rect.defect_id = this.selectedImage.defect_id;
      rect.component = this.selectedDefectList.component;
      rect.defect = this.selectedDefectList.name;
      rect.severity = this.selectedDefectList.severity;

      rect.on("selected", function() {
        this.selectedBox = rect;
      });

      this.texts.push(text);
      this.canvas.add(text);
      this.rects.push(rect);
      this.canvas.add(rect);
      this.canvas.setActiveObject(rect);
    },
    async selectSeverity(severity) {
      //debugger
      this.isShowingDefectList = false;
      this.isShowingSeverityList = false;
      var duration =
        severity.name.toLowerCase() == "low"
          ? "within 6 month"
          : severity.name.toLowerCase() == "cautious"
          ? "within 3 month"
          : severity.name.toLowerCase() == "critical"
          ? "Immediately"
          : "";
      var recommendation = `Recommend for an engineering inspection to be carried out and advisable to repair ${duration}.`;
      var defect = {
        component: this.selectedComponent.component.name,
        name: this.selectedComponent.defect.name,
        severity: severity.name,
        phase: "UNKNOWN",
        remark: `Damaged UNKNOWN phase ${this.selectedComponent.component.name} ${this.selectedComponent.defect.name} problem detected. `,
        recommendation: recommendation,
        image_id: this.selectedImage.image_id,
        pole_id: this.selectedPole.id,
        created_at: moment().format("YYYY-MM-DD HH:mm:ss")
      };
      if (!this.isVisual) {
        //console.log(this.selectedComponent.defect.name);
        if (
          this.selectedComponent.defect.name == "UNKNOWN" ||
          this.selectedComponent.defect.name == "ALL" ||
          this.selectedComponent.defect.name == "RED" ||
          this.selectedComponent.defect.name == "BLUE" ||
          this.selectedComponent.defect.name == "YELLOW"
        ) {
          defect.phase = this.selectedComponent.defect.name.toUpperCase();
        }
      }
      this.isVisual ? (defect.type = "visual") : (defect.type = "thermal");

      await this.createDefect(defect);
      this.defects = await DBProject.getDefects(this.selectedPole.id);
    },

    async defectSelected(e) {
      //debugger
      this.isShowDefectModal = false;
      var selectedDefect = e;
      //console.log(selectedDefect);
      if (!this.isVisual) {
        //console.log(selectedDefect.name);
        //console.log(this.selectedImage);
        if (
          selectedDefect.name.toUpperCase() == "UNKNOWN" ||
          selectedDefect.name.toUpperCase() == "ALL" ||
          selectedDefect.name.toUpperCase() == "RED" ||
          selectedDefect.name.toUpperCase() == "BLUE" ||
          selectedDefect.name.toUpperCase() == "YELLOW"
        ) {
          selectedDefect.phase = selectedDefect.name.toUpperCase();
        }
        selectedDefect.remark = `${
          selectedDefect.severity
        } Abnormal Hotspot (∆T: ${Math.ceil(
          this.selectedImage.thermal_deltaT * 100
        ) / 100} °C) detected at the ${selectedDefect.phase} phases area.`;
      }

      selectedDefect.image_id = this.selectedImage.image_id;
      selectedDefect.pole_id = this.selectedPole.id;
      this.isVisual
        ? (selectedDefect.type = "visual")
        : (selectedDefect.type = "thermal");
      //console.log(selectedDefect);
      await this.createDefect(selectedDefect);
      this.defects = await DBProject.getDefects(this.selectedPole.id);
    },

    async createDefect(defect) {
      //console.log(defect);
      try {
        //debugger
        var defectResponse = await DBProject.addDefect(defect);
        //console.log(defectResponse);
        // var newDefect = defectResponse.allDefect.find(defect => defect.id = defectResponse.newDefectId)
        var newDefect = defectResponse.allDefect;
        //console.log(defectResponse.allDefect);
        //console.log(newDefect);
        //console.log(this.isVisual);

        var dir = this.isVisual
          ? path.join(this.polePath, "VISUAL")
          : path.join(this.polePath, "THERMAL");
        if (this.isVisual) {
          var resizedImagePaths = await this.resizeImage(
            this.selectedImage,
            this.polePath
          );
          //console.log(resizedImagePaths);
          if (resizedImagePaths.large) {
            var snapShotName = `${slugify(newDefect.component)}_${slugify(
              newDefect.name
            )}_${newDefect.severity}_1_snapshot.jpeg`;
            // var dir = this.isVisual ? path.join(this.polePath, 'VISUAL') : path.join(this.polePath, 'THERMAL')
            var outputDir = path.join(dir, snapShotName);
            //console.log(outputDir);
            // var e = await fs.copyFileSync(resizedImagePaths.large, outputDir);
            var e = await API.post("filecopy", {
              from: resizedImagePaths.large,
              to: outputDir
            });
            //console.log(e);
            if (e.data == undefined) {
              //console.log(e);
              alert(e.message);
            } else {
              //console.log("done copy image as snapshot");
              var snapShot_1 = {
                defect_id: defectResponse.newDefectId,
                name: e.data.data,
                position: 1
              };
              //console.log(snapShot_1);
              await DBProject.saveSnapShotPath(snapShot_1);
            }
          }

          var poleNumberImage = await DBProject.getPoleNumberImage(
            this.selectedPole.id
          );
          if (poleNumberImage && poleNumberImage.length > 0) {
            var snapShot4Dir = path.join(
              dir,
              `${slugify(newDefect.component)}_${slugify(newDefect.name)}_${
                newDefect.severity
              }_4_snapshot.jpeg`
            );
            //console.log("poleNumberImage");
            //console.log(poleNumberImage);
            var poleNumberImagePath = path.join(this.polePath, `VISUAL`);
            var poleNumberPath = path.join(
              poleNumberImagePath,
              poleNumberImage[0].name.replace(".", "-Org.")
            );
            //console.log(poleNumberPath);
            // var copySnapshotError = await fs.copyFileSync(
            //   poleNumberPath,
            //   snapShot4Dir
            // );
            var copySnapshotError = await API.post("filecopyfromrepo", {
              from: poleNumberPath,
              to: snapShot4Dir
            });
            //console.log(copySnapshotError);
            if (!copySnapshotError.data) {
              //console.log(copySnapshotError);
              alert(copySnapshotError.message);
            } else {
              //console.log("done copy image as snapshot 4");

              var snapShot_4 = {
                defect_id: defectResponse.newDefectId,
                name: path.basename(snapShot4Dir),
                position: 4
              };
              //console.log(snapShot_4);
              await DBProject.saveSnapShotPath(snapShot_4);
            }
          }
        }
        DBProject.updatePoleProgress(this.selectedPole.id, false).then(data => {
          this.selectedPole.completed = false;
          this.isCompleted = false;
          //console.log(this.selectedPole);
        });
      } catch (err) {
        if (err) throw alert(err.message);
      }
    },

    customDefectClick() {
      // this.isShowingDefectList = false
      // this.isShowingSeverityList = false
      this.isShowDefectModal = false;
      //console.log("customDefectClick");
      this.isShowCustomDefectModal = true;
    },

    addDefectClick() {
      this.isShowDefectModal = true;
      //console.log(this.selectedPole.inspection_type);
      if (this.isVisual) {
        if (this.selectedPole.inspection_type == "ABC") {
          this.components = componentsJson;
        } else {
          this.components = componentsBAREJson;
        }
      }

      // this.isShowingDefectList = !this.isShowingDefectList
      // this.isShowingSeverityList = false
    },

    isSelected(poleId) {
      if (this.selectedPole.pole_id == poleId) {
        return true;
      } else {
        return false;
      }
    },

    isSelectedImage(imageId) {
      if (this.selectedImage.image_id == imageId) {
        return true;
      } else {
        return false;
      }
    },

    selectPole(poleId) {
      this.poles.forEach(pole => {
        // //console.log(poleId)
        if (pole.pole_id == poleId) {
          // //console.log(true)
          this.selectedPole = pole;
          return;
        }
      });
    },
    selectImage(imageId) {
      //console.log(this.selectedPoleImages, "this.selectedPoleImages");
      //debugger
      this.selectedPoleImages.forEach(img => {
        //console.log(imageId, img);
        if (img.image_id == imageId) {
          this.selectedImage = img;
          //console.log(this.selectedImage);
          return;
        }
      });
    },
    logout() {
      localStorage.removeItem("auth");
      window.location = "#/login";
    },

    async refresh() {
      // //console.log("refresh");
      const response = await API.get(
        `inspections/${this.selected.inspection.id}/turbines`
      );
      this.turbines = response.data.map(turbine => new GenericObject(turbine));
      this.selected.turbine = this.turbines[0];
    },

    async saveBox(obj) {
      debugger;
      //console.log("save box");
      //console.log(this.selectedImage);
      const opts = {
        defects_images_id: this.selectedImage.defects_images_id,
        x: obj.actLeft / this.ratio,
        y: obj.actTop / this.ratio,
        width: (obj.width * obj.scaleX) / this.ratio,
        height: (obj.height * obj.scaleY) / this.ratio
      };

      // //console.log(opts)
      const id = await DBProject.saveBox(obj.boxId, opts);
      DBProject.updatePoleProgress(this.selectedPole.id, false).then(data => {
        this.selectedPole.completed = false;
        this.isCompleted = false;
        //console.log(this.selectedPole);
      });
      obj.boxId = id;

      // const dataURL = canvas.toDataURL({
      //   format: 'png',
      //   // quality: 1.0
      // })

      // this.saveBoxAsImage(id, this.selectedImage)
      return true;
    },

    clearBoxes() {
      this.texts.forEach(text => this.canvas.remove(text));
      this.rects.forEach(rect => this.canvas.remove(rect));
      this.texts = [];
      this.rects = [];
    },

    // saveBoxAsImage(id, image) {
    //   return new Promise(async(resolve, reject) => {
    //     const dataURL = this.canvas.toDataURL({
    //       format: 'jpeg',
    //       quality: 0.5
    //     })

    //     const matches = dataURL.match(/^data:.+\/(.+);base64,(.*)$/)
    //     const ext = matches[1]
    //     const data = matches[2]
    //     const buffer = new Buffer(data, 'base64')

    //     const output = `/Users/faredhadi/Desktop/data.jpg`

    //     fs.writeFile(output, buffer, err => {
    //       if (err) {
    //         //console.log(err)
    //         return reject(err)
    //       }

    //       resolve()
    //     })
    //   })
    // },
    // saveBoxAsImage(box) {
    //   return new Promise(async(resolve, reject) => {
    //     //console.log('saveBoxAsImage')
    //     // //console.log(this.selectedBox)
    //     const dataURL = this.canvas.toDataURL({
    //       format: 'jpeg',
    //       quality: 0.5
    //     })

    //     const matches = dataURL.match(/^data:.+\/(.+);base64,(.*)$/)
    //     const ext = matches[1]
    //     const data = matches[2]
    //     const buffer = new Buffer(data, 'base64')
    //     var polePath = path.join(this.selectedProject.projectPath, this.selectedPole.name)
    //     const output = path.join(polePath, `${this.selectedImage.name.replace(/\.[^/.]+$/, "")}_${this.selectedBox.defect.name}_croped.jpeg`)

    //     await DBProject.updateImageCropedPath(this.selectedBox.boxId, output)
    //     fs.writeFile(output, buffer, err => {
    //       if (err) {
    //         //console.log(err)
    //         return reject(err)
    //       }

    //       resolve()
    //     })
    //   })
    // },

    addDeleteBtn(x, y) {
      $(".deleteBtn").remove();
      var btnLeft = x - 10;
      var btnTop = y - 10;
      var deleteBtn =
        '<i class="deleteBtn fal fa-times-circle" style="text-color: #fff; position:absolute;top:' +
        btnTop +
        "px;left:" +
        btnLeft +
        'px;cursor:pointer;width:20px;height:20px;"/>';
      $(".canvas-container").append(deleteBtn);
    },

    async deleteImage() {
      //console.log("delete");
      var dirname = path.join(
        this.polePath,
        this.selectedImage.type.toUpperCase()
      );
      var imagePath = path.join(dirname, this.selectedImage.name);
      //console.log(imagePath);
      if (
        confirm(
          "Are you sure want to delete this image? \n *this action cannot be undone"
        )
      ) {
        //console.log(this.selectedPoleImages.indexOf(this.selectedImage));
        this.selectedPoleImages.splice(
          this.selectedPoleImages.indexOf(this.selectedImage),
          1
        );
        await DBProject.deleteImage(this.selectedImage, this.selectedPole.id);
        // try {
        //   if (fs.existsSync(imagePath)) {
        //     fs.unlinkSync(imagePath);
        //     //console.log(`successfully deleted ${imagePath}`);
        //   } else {
        //     //console.log(`${imagePath} not exist`);
        //   }
        // } catch (err) {
        //   // handle the error
        //   //console.log(err.message);
        // }
      }
    },
    async deleteObjects(obj) {
      var canvas = this.canvas;
      //console.log(obj);
      // var  activeGroup = canvas.getActiveGroup();
      if (obj) {
        if (confirm("Are you sure?")) {
          canvas.remove(obj.text);
          canvas.remove(obj);
          this.selectedBox = null;
          await DBProject.deleteBox(obj);
          DBProject.updatePoleProgress(this.selectedPole.id, false).then(
            data => {
              this.selectedPole.completed = false;
              this.isCompleted = false;
              //console.log(this.selectedPole);
            }
          );
        }
      }
      // else if (activeGroup) {
      //   if (confirm('Are you sure?')) {
      //     var objectsInGroup = activeGroup.getObjects();
      //     canvas.discardActiveGroup();
      //     objectsInGroup.forEach(function(object) {
      //       canvas.remove(object);
      //     });
      //   }
      // }
    },
    zoomToPosition(x, y) {
      // zoomMoveToPosition("top", y);
      // zoomMoveToPosition("left", x);
    },
    zoomInCalcXpos(xPos) {
      xPos *= canvas.getZoom();
      return zoomCalcXpos(canvasWidth / 2 - xPos);
    },
    zoomCalcXpos(xPos) {
      if (xPos > 0) {
        return 0;
      }
      if (xPos + canvas.getWidth() < canvasWidth) {
        return canvasWidth - canvas.getWidth();
      }
      return xPos;
    },

    zoomInCalcYpos(yPos) {
      yPos *= canvas.getZoom();
      return zoomCalcYpos(canvasHeight / 2 - yPos);
    },
    zoomCalcYpos(yPos) {
      if (yPos > 0) {
        return 0;
      }
      if (yPos + canvas.getHeight() < canvasHeight) {
        return canvasHeight - canvas.getHeight();
      }
      return yPos;
    },
    zoomIn() {
      if (this.canvas.getZoom().toFixed(5) > 10) {
        //console.log("zoomIn: Error: cannot zoom-in anymore");
        return;
      }
      this.canvas.zoomToPoint(
        new fabric.Point(0, 0),
        this.canvas.getZoom() * this.SCALE_FACTOR
      );
      this.canvas.renderAll();
    },
    zoomOut() {
      if (this.canvas.getZoom().toFixed(5) <= 0.1) {
        //console.log("zoomOut: Error: cannot zoom-out anymore");
        return;
      }
      this.canvas.zoomToPoint(
        new fabric.Point(0, 0),
        this.canvas.getZoom() / this.SCALE_FACTOR
      );
      this.canvas.renderAll();
    },
    setSelectedBox(box) {
      if (box.defect !== "undefined" && box.boxId !== "undefined") {
        this.selectedBox = object;
      } else {
        this.selectedBox = null;
      }
    },
    resetCanvas() {
      if (this.canvas) {
        this.canvas.setHeight(this.canvasContainer.height);
        this.canvas.setWidth(this.canvasContainer.width);
        this.canvas.clear();
        this.drawImage();
      }
    },
    async initData() {
      this.isLoadingAll = true;
      this.isAllPhotoSelected = true;
      this.selectedProject = null;
      this.poles = null;
      this.selectedPole = null;
      this.selectedDefectList = null;
      this.selectedProject = await DBProject.getProject();
      //console.log(this.selectedProject);
      this.poles = (await DBProject.getPolesByProjectId(
        this.selectedProject.id
      )).sort(
        (a, b) =>
          a.name.replace(/(^\d+)(.+$)/i, "$1") -
          b.name.replace(/(^\d+)(.+$)/i, "$1")
      );
      this.isLoadingAll = false;
    }
  },
  async updated() {
    //console.log("updated");
    // //console.log(document.getElementById('canvasContainer').clientHeight)
    this.canvasContainer.width = document.getElementById(
      "canvasContainer"
    ).clientWidth;
    this.canvasContainer.height = document.getElementById(
      "canvasContainer"
    ).clientHeight;
    if (this.canvas) {
      this.canvas.setHeight(this.canvasContainer.height);
      this.canvas.setWidth(this.canvasContainer.width);
    }
  },
  async mounted() {
    //console.log("poles mounted");
    this.getEquipments();
    // //console.log(this.poles)
    try {
      this.canvas = new fabric.Canvas("canvas", {
        width: this.canvasContainer.width,
        height: this.canvasContainer.height,
        preserveObjectStacking: true
      });
      var localData = null;
      this.initData();
      // //console.log(this.poles)
      // //console.log(this.$route.params[`pid`])

      // setTimeout(() => {

      this.canvas.on("object:moving", e => {
        // if (typeof e.target.component === 'undefined')
        //   return

        //for v3
        var currentObject = e.target;
        if (currentObject === this.canvasImage) return;

        //console.log("cropzone moving");
        // var canvas = this.canvas;
        if (typeof currentObject.defect !== "undefined") {
          currentObject.text.left = currentObject.left;
          currentObject.text.top = currentObject.top - 15;
        }
        var x = currentObject.left,
          y = currentObject.top;
        var w = currentObject.width * currentObject.scaleX,
          h = currentObject.height * currentObject.scaleY;
        var maxX = this.canvasImage.width * this.ratio - w;
        var maxY = this.canvasImage.height * this.ratio - h;
        // //console.log(x + ', ' +y)
        // //console.log(maxX + ', ' +maxY)

        if (x < 0) currentObject.set("left", 0);
        if (y < 0) currentObject.set("top", 0);
        if (x > maxX) currentObject.set("left", maxX);
        if (y > maxY) currentObject.set("top", maxY);
      });
      var self = this;
      this.canvas.on("object:selected", function(event) {
        var object = event.target;
        // //console.log(object)
        if (
          typeof event.target.defect !== "undefined" ||
          typeof event.target.isCrop !== "undefined" ||
          typeof event.target.isSnapShot !== "undefined"
        ) {
          // //console.log('box selected')

          self.canvas.bringToFront(object);
        } else {
          //console.log("image selected");
          this.selectedBox = null;
          self.canvas.sendToBack(object);
          self.canvas.getObjects().forEach(obj => {
            if (obj.defect && typeof obj.defect !== "undefined") {
              self.canvas.bringToFront(obj);
            }
          });
        }
      });

      this.canvas.on("object:scaling", e => {
        if (typeof e.target.defect === "undefined") return;
        e.target.set("strokeWidth", 3);
        e.target.text.left = e.target.left;
        e.target.text.top = e.target.top - 15;
      });

      this.canvas.on("object:moved", async e => {
        if (typeof e.target.defect !== "undefined") {
          var defectMarginLeft = e.target.left - e.transform.original.left;
          var defectMarginTop = e.target.top - e.transform.original.top;
          // e.target.actLeft = e.target.left - this.imgMaginLeft
          // e.target.actTop = e.target.top - this.imgMarginTop
          e.target.actLeft += defectMarginLeft;
          e.target.actTop += defectMarginTop;
          await this.saveBox(e.target);
        } else {
          if (
            typeof e.target.isCrop === "undefined" &&
            typeof e.target.isSnapShot === "undefined"
          ) {
            //console.log("image moved");

            this.imgMaginLeft = e.target.left - e.transform.original.left;
            this.imgMarginTop = e.target.top - e.transform.original.top;
            self.canvas.getObjects().forEach(obj => {
              // //console.log(obj)
              // //console.log(this.imgMarginTop)
              if (typeof obj.defect !== "undefined") {
                obj.left += this.imgMaginLeft;
                obj.top += this.imgMarginTop;
                obj.text.left = obj.left;
                obj.text.top = obj.top - 15;
              }
            });
          }
        }
      });

      this.canvas.on("object:scaled", async e => {
        // if (typeof e.target.component === 'undefined')
        //   return
        //for v3
        // //console.log(e)
        if (typeof e.target.defect === "undefined") {
          e.target.set("strokeWidth", 3);
          if ((e.target = this.cropBox)) {
            // //console.log(e.target)
            // //console.log(this.cropBox)
          }
          return;
        }

        // //console.log(e.target.left)
        var defectMarginLeft = e.target.left - e.transform.original.left;
        var defectMarginTop = e.target.top - e.transform.original.top;
        e.target.actLeft += defectMarginLeft;
        e.target.actTop += defectMarginTop;
        e.target.set("strokeWidth", 3);
        await this.saveBox(e.target);
      });

      this.canvas.on("object:added", async e => {
        // if (e.target.component && typeof e.target.boxId === 'undefined')
        //for v3
        //console.log("added");

        // //console.log( this.canvasImage)
        // //console.log( this.selectedImage)
        e.target.actLeft = e.target.left - this.canvasImage.left;
        e.target.actTop = e.target.top - this.canvasImage.top;
        // //console.log(e.target)

        if (e.target.defect && typeof e.target.boxId === "undefined") {
          // e.target.width = e.target.width / this.ratio
          // e.target.height = e.target.height / this.ratio
          await this.saveBox(e.target);
        }
      });

      this.canvas.on("mouse:dblclick", async e => {
        // if (typeof e.target.component === 'undefined')
        //   return
        //for v3
        if (e.target && typeof e.target.defect !== "undefined") {
          this.deleteObjects(e.target);
        }
        if (e.target == this.canvasWidth) {
          //console.log(e);
        }
        // return
      });

      // this.canvas.on('after:render', async e => {
      //   //console.log('isSaveCropImage')
      //   //console.log(this.isSaveCropImage)
      //   if(this.isSaveCropImage){
      //     //console.log('after render')
      //     this.saveBoxAsImage(this.cropBox).then(async (data) =>{
      //     // this.canvas.remove(this.selectedBox)
      //     // this.canvas.remove(this.selectedBox.text)
      //       this.canvas.remove(this.cropBox)
      //       this.resetCanvas()
      //     })
      //   }
      // });

      this.canvas.on("mousemove", function(e) {
        // //console.log(e)
        if (e.target.defect && typeof e.target.defect === "undefined")
          e.target.set("strokeWidth", 5);

        // e.target.set('fill', 'red');
        // this.canvas.renderAll();
      });

      this.canvas.on("mouseout", function(e) {
        if (e.target.defect && typeof e.target.defect === "undefined")
          e.target.set("strokeWidth", 3);
        // e.target.set('fill', 'green');
        // this.canvas.renderAll();
      });

      // //console.log(this.canvas)

      // //console.log(`canvasWidth: ${this.canvasWidth}`)
      // //console.log(`canvasHeight: ${this.canvasHeight}`)
      // }, 1000)
    } catch (err) {
      alert(err.message);
    }
  }
};
</script>

<style scoped src="@/../public/bootstrap/css/bootstrap.min.css"></style>
<style scoped src="@/../public/css/fontawesome-pro-5.1.1-web/css/all.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
::-webkit-scrollbar {
  display: none;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  border: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

body {
  background: #ebebeb;
}

/* #app, */
#app {
  height: 100vh;
  background: white;
}
.full-height {
  height: 100%;
}

.navbar {
  height: 50px;
  margin-bottom: 0px;
  background: #808368;
}

@media (min-width: 768px) {
  .navbar {
    border-radius: 0px;
  }
}

.logo {
  /* width: 46px;
  height: 46px; */
  object-fit: contain;
  margin-right: 8px;
}

.navbar-brand > img {
  display: inline;
}

.box {
  margin: 0px auto;
  margin-bottom: 40px;
}

.no-margin {
  margin: 0px !important;
}

.img_box {
  margin-bottom: 10px;
}

.img-thumb {
  height: 100%;
  background: rgba(158, 158, 158, 0.25);
  border-width: 2px !important;
  cursor: pointer;
  border-radius: 5px;
  height: 76px;
}

.img-thumb-side {
  width: 100%;
  background: rgba(158, 158, 158, 0.25);
  cursor: pointer;
  /* border-radius: 5px;  */
  border: 2px solid #989898;
}

.img-thumb-container {
  height: 100%;
  float: left;
  position: relative;
}

.img-thumb-side-container {
  width: 100%;
  float: left;
  position: relative;
}

.add-img-thumb {
  font-size: 43px;
  height: 100%;
  border: 2px dashed #cccccc;
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.add-img-thumb img {
  height: 24px;
}

.img-thumb-text-container {
  position: absolute;
  bottom: 4px;
  left: 0px;
  right: 4px;
  background: rgba(25, 25, 25, 0.25);
  font-size: 10px;
  color: white;
  height: 30%;
  white-space: nowrap;
  text-overflow: clip;
  display: flex !important;
  align-items: center !important;
}

.add-img-snapshot {
  font-size: 12px;
  height: 100%;
  border: 1px solid #848282;
  background: #989898;
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
}

.add-img-thumb .far {
  font-size: 24px !important;
}

.add-img-thumb .text {
  font-size: 14px !important;
}

.title-header {
  width: auto;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 24px !important;
}

.title-header span {
  cursor: default;
  font-size: 15px;
  font-weight: normal !important;
}

.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > tbody > tr > td {
  border: none !important;
  color: #5a6169;
  font-size: 10px;
  border-bottom: 1px solid #d7dee6 !important;
}

.table > tbody > tr > td.with-border {
  border: 1px solid #dee2e6 !important;
}

.table > tbody > tr > td.with-border.severity {
  cursor: pointer !important;
}

.table > tbody > tr > td.with-border.severity:active {
  background-color: rgba(26, 25, 25, 0.5) !important;
}

.table > thead > tr > th {
  border: none !important;
  color: #9ba4ae;
  font-size: 10px;
  font-weight: lighter;
}

.border-3 {
  border-width: 5px !important;
}

.table > tbody > tr.is-last {
  border-bottom: 1px solid rgba(255, 255, 255, 0.356) !important;
}

.table-hover tbody tr:hover {
  background-color: rgba(73, 73, 73, 0.175) !important;
}

tbody tr:active {
  background-color: rgba(73, 73, 73, 0.175) !important;
}
tbody tr.with-border:active {
  background: none !important;
}

.table {
  color: #fff !important;
}

.table thead {
  border-bottom: 1px solid #d7dee6 !important;
}

.table td.severity {
  padding: 0.5rem 5px;
}

ul {
  list-style-type: none;
  padding-left: 10px;
  height: calc(100% - 33px);
}

th {
  width: 20%;
}

.input-sm {
  height: 25px !important;
  /* padding: 5px 10px; */
  font-size: 10px !important;
  line-height: 1.5;
  border-radius: 3px;
}

th:nth-child(1),
th:nth-child(2),
th:nth-child(3) {
}

#site-title {
  color: #fff;
  font-size: 14px;
}

::placeholder {
  color: rgba(95, 95, 95, 0.308) !important;
  opacity: 1;
  /* Firefox */
  font-size: 12px;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(95, 95, 95, 0.308);
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgba(95, 95, 95, 0.308);
}

.table-windparks li > a {
  padding: 7px;
  color: #858585;
}

.table-windparks li.active > a {
  font-weight: 600;
  color: #1e87fa;
}

#full-height-table {
  height: 100%;
}

.container {
  margin-left: 0px;
  margin-right: 0px;
  /* height: calc(100% - 54px);
  display: table; */
  /* width: 100%; */
  /* margin-top: -50px;
    padding: 50px 0 0 0; /*set left/right padding according to needs*/
  /* */
  /* box-sizing: border-box; */
  /* padding-left: 0px; */
}

/* .row {
  height: 100%;
  display: table-row;
} */

/* .row .no-float {
  display: table-cell;
  float: none;
} */

.row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.table-responsive {
  margin-bottom: 0px;
}

.table-title {
  background: #efeff4;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-left: 1px solid #abb4bd !important;
  border-right: 1px solid #abb4bd !important;
  border-top: 1px solid #abb4bd !important;
}

.display-inline {
  display: inline;
  margin-right: 14px;
}

textarea {
  resize: none;
}

.box-title {
  font-size: 12px;
  background: #efeff4;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #abb4bd !important;
}

.box-content {
  font-size: 12px;
}

.box-title-no-padding {
  background: #efeff4;
  /* padding-left: 10px;
  padding-right: 10px; */
  border-bottom: 1px solid #abb4bd !important;
}

.box-bulk-edit {
  background: #efeff4;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-bottom: 1px solid #abb4bd !important;
}

.box-bulk-edit span {
  background: #efeff4;
  font-size: 10px;
}

.box-middle {
  overflow: scroll;
  height: calc(100% - 56px);
}

.box-overlay {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  bottom: 0px;
  color: white;
  height: 50px;
  margin-bottom: 24px;
}

tbody tr {
  cursor: pointer;
}

tbody tr.with-border {
  cursor: default;
}

.box-bottom {
  position: absolute;
  bottom: 0px;
  width: 100%;
}

.checkbox-margin {
  margin: 10px;
  font-size: 14px;
  font-weight: bold;
}

.box-title-full-border {
  background: #efeff4;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #abb4bd !important;
  border-top: 1px solid #abb4bd !important;
}

.box-border {
  border-left: 1px solid #abb4bd !important;
  border-right: 1px solid #abb4bd !important;
  border-top: 1px solid #abb4bd !important;
}

.box-border-full {
  border-left: 1px solid #abb4bd !important;
  border-right: 1px solid #abb4bd !important;
  border-top: 1px solid #abb4bd !important;
  border-bottom: 1px solid #abb4bd !important;
  margin-top: -22px;
}

.box-border-no-border-left {
  border-right: 1px solid #abb4bd !important;
  border-top: 1px solid #abb4bd !important;
}

.table-body {
  height: 40%;
  overflow: scroll;
  background: #fff;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-left: 1px solid #abb4bd !important;
  border-right: 1px solid #abb4bd !important;
  border-top: 1px solid #abb4bd !important;
  border-bottom: 1px solid #abb4bd !important;
}

.table-body-no-border-bottom {
  height: 40%;
  overflow: scroll;
  background: #fff;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-left: 1px solid #abb4bd !important;
  border-right: 1px solid #abb4bd !important;
  border-top: 1px solid #abb4bd !important;
}

.inherit-height {
  height: inherit;
}

.main-content {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  height: calc(100vh - 50px);
  padding-left: 0px;
  padding-right: 0px;
}

.sub-main-header {
  background: #f7f8fb;
  /* margin-bottom: 20px; */
  height: 55px;
}

.submain-content {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  height: calc(100% - 55px);
  padding-bottom: 5px;
}

/* .submain-content::-webkit-scrollbar{
  display: inline;
} */

.img_box img {
  width: 200px;
  height: 150px;
  margin: 0px auto;
}

.btn_action {
  margin: 0px auto;
}

.title-box-small {
  /* color: #fff;
  font-family: 'Lato-Bold'; */
  padding: 15px 0px 15px 0px;
  /* text-align: center; */
}

.margin-top-5 {
  margin-top: 5px;
}

.span-light {
  /* padding-left: 15px; */
  font-size: 12px;
  font-weight: lighter;
}

.span-bold {
  word-wrap: break-word;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 2px !important;
}

.main-table {
  background-color: #ffffff;
  border: 1px solid #4d5d7a;
}

.table {
  margin-bottom: 0px;
  padding-bottom: 6px;
}

.btn-add {
  bottom: 0px;
}

.btn-sm {
  font-size: 10px !important;
}

i {
  margin-right: 5px;
}

.left-section {
  padding-left: 0px;
}

.url {
  padding: 10px;
  background: #fff;
  margin-bottom: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
  outline: none;
  height: 59px;
  width: 100%;
  font-size: 12px;
}

.browsed {
  background: transparent;
  padding: 0;
  border: none;
  overflow: hidden;
  width: 100%;
  font-size: 11px;
  margin-bottom: 5px;
  outline: none;
  height: 16px;
}

#overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.87);
  height: 100%;
}

#overlay textarea {
  background: transparent;
  border: none;
  padding: 50px;
  display: block;
  width: 100%;
  height: 100%;
  outline: none;
  color: #fff;
  font-family: monospace;
  font-size: 14px;
}

/* #overlay button {
  position: absolute;
  color: #fff;
  right: 15px;
  top: 15px;
  background: transparent;
  padding: 7px 10px;
  border: 1px solid #fff;
  font-size: 10px;
  border-radius: 3px;
} */

.btn-outline-dark {
  box-shadow: none !important;
}

.form-container {
  margin-top: 20px;
}

.form-container h2 {
  font-weight: normal;
  font-size: 15px;
  margin-bottom: 20px;
  border-bottom: 1px solid #fff;
  padding-bottom: 5px;
}

td ul {
  overflow-y: scroll;
  overflow-x: hidden;
}

.input-search {
  display: inline !important;
  width: fit-content !important;
  height: 32px !important;
  vertical-align: middle;
}

.p1 {
  background: #000;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  margin-top: -12px;
}

/* .p1 img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
} */

.p1 canvas {
  /* width: calc(100vw - 373px); */
  /* background: red; */
  /* height: calc(100vh - 354px); */
}

.add-defect-container {
  position: absolute;
  z-index: 999;
  /*right: 10px;
  bottom: 55px;
  height: auto; */
  /* width: 100%; */
}

#custom-defect-button {
  font-size: 12px;
  background: #007bff;
  width: 100%;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #495057;
  border-left: 1px solid #495057;
  border-right: 1px solid #495057;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

#defects {
  /* position: absolute;
  right: 10px; */
  font-size: 12px;
  width: 100%;
  margin: 0;
  padding: 0;
  /* height: auto; */
  /* display: inline !important; */
  float: right;
  max-height: 150px;
  background: #fff;
  overflow: scroll;
  border: 1px solid #495057;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

#severity {
  position: absolute;
  left: 100%;
  z-index: 99999;
  /* right: 10px; */
  font-size: 12px;
  width: 100%;
  margin-right: 5px;
  padding: 0;
  /* height: auto; */
  /* bottom: 55px; */
  height: auto;
  background: #fff;
  overflow: scroll;
  border: 1px solid #495057;
  border-radius: 4px;
}

#defects .defects-header,
#severity .defects-header {
  background: #eee;
  padding: 5px 10px;
  border-bottom: 1px solid #dedede;
  font-weight: bold;
  display: block;
}

#defects .defect,
#severity .defect {
  border-bottom: 1px solid #eee;
}
#custom-defect-button .custom-defect {
  text-align: center;
  /* border-bottom: 1px solid #eee; */
  padding: 5px 10px;
  /* background: #007bff; */
}

#custom-defect-button .custom-defect a {
  display: block;
  color: white;
  text-decoration: none;
}

#defects .defect a,
#severity .defect a {
  display: block;
  padding: 5px 10px;
  background: #fff;
  color: #444;
  text-decoration: none;
}

#defects .defect a:hover #severity .defect a:hover {
  background-color: #eee;
}

.loading-bg {
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.38);
  /* display: inline; */
  position: absolute;
  top: 0px;
  display: flex;
  align-items: center;
  /* font-size: 4rem; */
  color: white;
}

.loading-log {
}

.loading-bg img {
  display: block;
}
.loading-bg div {
  /* width: 3rem; */
  /* position: absolute;
  left: calc(50% - 2rem);
  top: calc(50% - 2rem); */
  text-align: -webkit-center;
  width: 80%;
  margin: auto;
}

.loading-bg .small {
  left: 50%;

  top: 50%;

  width: 300px;

  margin-left: -150px;

  font-size: 10px;

  line-height: 12px;
}

.loading-bg .small img {
  margin-bottom: 10px;
}

.is-active {
  background: #fdb714 !important;
  color: #fff !important;
}

.is-tab-active {
  background: #c4c3c327 !important;
  color: #fff !important;
}

.is-tab-active a {
  color: #fff !important;
  font-weight: bold;
}

.tabs a:hover {
  color: #fff !important;
}

nav .nav-pills .nav-link {
  border: 1px solid transparent;
  border-radius: 0 !important;
  color: black !important;
  cursor: pointer;
}

.height-full {
  height: 100%;
}

.width-full {
  width: 100% !important;
}

nav .nav-pills .nav-link.active {
  background-color: white;
  border-bottom: 2px solid #ea1863;
  color: black;
  font-weight: bolder;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-mask-big {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* display: table; */
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  height: 100%;
  width: 80%;
  position: relative;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-container-choose-image {
  height: 80vh;
  width: calc(100vw - 80px);
  position: relative;
  margin: 0px auto;
  /* padding: 20px 30px; */
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-container-small {
  /* height: 80%; */
  width: 60%;
  position: relative;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-container-video {
  width: auto;
  margin: 0px auto;
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  text-align: center;
}

.modal-header {
  padding: 0 0 5px 0 !important;
}
.modal-header-choose-image {
  background: #efeff4;
  padding: 10px !important;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  /* border-bottom: 1px solid #444 */
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 5px 0;
}

.modal-default-button {
  float: right;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.modal-button-at-bottom-right-small {
  width: 100%;
  display: inline-table;
  padding-bottom: 15px;
  padding-left: 30px;
  padding-right: 30px;
}

.modal-button-at-bottom-right {
  width: 100%;
  display: inline-table;
  padding-bottom: 15px;
  padding-left: 30px;
  padding-right: 30px;
  position: absolute;
  bottom: 0px;
  left: 0px;
}

.modal-button-at-bottom-right .button.is-save {
  background: #08a86b !important;
  color: #fff;
  margin-right: 5px;
  float: right;
}

.modal-button-at-bottom-right .button.is-cancel {
  background: red !important;
  color: #fff;
  float: right;
}

.select select {
  background-color: transparent !important;
  border: 1px solid #fff;
  border-radius: 5px !important;
  color: #000 !important;
  font-size: 14px !important;
}

.custom-file {
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  z-index: 0 !important;
}

.custom-file-label {
  height: calc(2rem + 2px) !important;
  z-index: 0 !important;
}

.custom-file-label::after {
  height: 2rem !important;
  z-index: 0 !important;
}

.custom-file-input {
  height: calc(2rem + 2px);
  z-index: 0 !important;
}

.height-full {
  height: 100%;
}

.zoom-main-bg {
  position: absolute;
  top: 0px;
  background: #fff;
  border-radius: 3px;
  margin: 5px;
}

.zoom-item {
  width: 24px;
  height: 24px;
  object-position: center;
  text-align: center;
  font-size: 12px;
}

.list-group-item {
  cursor: pointer;
  border-radius: 0 !important;
  padding: 0.55rem 0.5rem 0.55rem 1.25rem !important;
  background: #dfe5ee !important;
  font-size: 12px;
}

.list-group-item.selected {
  background: rgba(2, 130, 250, 0.527) !important;
}

.list-group-item.selected-side-list {
  background: #283c4f !important;
  color: white;
}

.list-group-item-title {
  color: white;
  /* font-weight: bold; */
  /* height: 40px; */
  cursor: default;
  background: #4d5d7a !important;
  font-size: 14px;
  vertical-align: center;
}

.list-group-item-title span {
  vertical-align: middle;
  height: 100%;
}

.selection-col {
  height: 100%;
  width: 250px;
  background: rgb(223, 229, 238);
  display: block;
  border-right: 1px solid rgb(77, 93, 122);
  float: left;
  flex: 0 0 30%;
}

.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 26px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  border: 1px solid white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(22px);
  -ms-transform: translateX(22px);
  transform: translateX(22px);
}

.dark-item {
  background: #283c4f;
  font-size: 14px;
  height: 40px;
  color: white;
  line-height: 40px;
  cursor: pointer;
  font-size: 12px;
}

.light-items {
  font-size: 14px;
  background: #ffffff;
  height: 40px;
  color: black;
  line-height: 40px;
  border-bottom: 1px solid #b3b3b3;
  cursor: pointer;
}

.small-title {
  font-size: 14px;
  background: #f4f4f4;
  height: 40px;
  color: black;
  line-height: 40px;
  border-bottom: 1px solid #b3b3b3;
  font-size: 12px;
}

.img-large {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0.25rem;
  width: 100%;
}

.ripple {
  color: #42a4ff;
  background-position: center;
  transition: background 0.8s;
  cursor: pointer;
  border-bottom: 1px solid #cccccc;
}
.ripple:hover {
  background: #cccccc radial-gradient(circle, transparent 1%, #cccccc 1%)
    center/15000%;
}
.ripple:active {
  background-color: #cccccc;
  background-size: 100%;
  transition: background 0s;
}

.thumb-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  transition: background 0.5s ease;
}

.text-container-overlay {
  /* position: absolute;
  top: 0;
  left: 0; */
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  transition: background 0.5s ease;
}

.text-container:hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.068);
}

.img-thumb-container:hover .thumb-overlay {
  display: block;
  background: rgba(0, 0, 0, 0.068);
  cursor: pointer;
}

.btn-overlay-hover {
  position: absolute;
  width: 100%;
  height: 24px;
  font-size: 12px;
  margin: auto;
  left: 0;
  bottom: 0;
  text-align: center;
  opacity: 0;
  width: 93%;
  margin-left: 4px;
  margin-bottom: 4px;
  transition: opacity 0.35s ease !important;
}

.btn-delete-overlay-hover {
  position: absolute;
  /* width: 100%; */
  height: 24px;
  font-size: 12px;
  margin: auto;
  right: 4px;
  top: 4px;
  text-align: center;
  opacity: 0;
  margin-left: 4px;
  margin-bottom: 4px;
  transition: opacity 0.35s ease !important;
}

.img-thumb-container:hover .btn-delete-overlay-hover {
  opacity: 1;
}

.text-container:hover .btn-overlay-hover,
.img-thumb-container:hover .btn-overlay-hover {
  opacity: 1;
  background: #cccccc;
}

.img-view-container {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.51);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
}

.img-view-container img {
  height: 90%;
  display: block !important;
  margin: auto !important;
}

.badge-noseverity {
  color: #fff;
  background-color: #ff00ff;
}

#delelte-option {
  position: absolute;
  left: 100%;
  top: 0;
  z-index: 99999;
  /* right: 10px; */
  font-size: 12px;
  margin-right: 5px;
  padding: 0;
  /* height: auto; */
  /* bottom: 55px; */
  height: auto;
  background: #fff;
  overflow: scroll;
  border: 1px solid #495057;
  border-radius: 4px;
}

#delelte-option .defects-header {
  background: #eee;
  padding: 5px 10px;
  border-bottom: 1px solid #dedede;
  font-weight: bold;
  display: block;
  color: black;
}

.my-datepicker_calendar {
  width: 100%;
  height: 330px;
}

.dropdown-right {
  float: right;
}

.dropdown-menu-vif {
  display: block !important;
  right: 0;
  left: unset;
}
</style>