import axios from 'axios'
import config from './config'

export default class API {
  static get(module) {
    return new Promise(async (resolve, reject) => {
      try {        
        
        document.getElementById("full-loader").style.display = "block";     
        const opts = this._opts(module, 'get')
        const response = await axios(opts)
        if (response) {
          document.getElementById("full-loader").style.display = "none";
        }
        resolve(response);
      }
      catch (err) {
       
        if (err.response && err.response.status == 401) { 
          document.getElementById("full-loader").style.display = "none";   
          localStorage.removeItem("auth");
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          localStorage.removeItem("userData");
          localStorage.removeItem("sessionActive");                    
          window.location = '#/login'
        }
        reject(err)        
      }
    })    
  }

  static post(module, data) {
    return new Promise(async (resolve, reject) => {
      try {        
        document.getElementById("full-loader").style.display = "block";   
        const opts = this._opts(module, 'post', data)
        const response = await axios(opts)
        if (response) {
          document.getElementById("full-loader").style.display = "none";
        }
        resolve(response);
      }
      catch (err) {
        if (err.response && err.response.status == 401){
          document.getElementById("full-loader").style.display = "none";  
          localStorage.removeItem("auth");
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          localStorage.removeItem("userData");
          localStorage.removeItem("sessionActive"); 
          window.location = '#/login'  
        }  
        
          
        reject(err)        
      }
    })    
  }

  static put(module, data) {
    return new Promise(async (resolve, reject) => {
      try {    
        document.getElementById("full-loader").style.display = "block";    
        const opts = this._opts(module, 'put', data)
        const response = await axios(opts)
        if (response) {
          document.getElementById("full-loader").style.display = "none";
        }
        resolve(response);
      }
      catch (err) {
        if (err.response && err.response.status == 401){
          document.getElementById("full-loader").style.display = "none";  
          localStorage.removeItem("auth");
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          localStorage.removeItem("userData");
          localStorage.removeItem("sessionActive"); 
          window.location = '#/login'  
        } 
          
        reject(err)        
      }
    })    
  }

  static delete(module) {
    return new Promise(async (resolve, reject) => {
      try {   
        document.getElementById("full-loader").style.display = "block";       
        const opts = this._opts(module, 'delete')
        const response = await axios(opts)
        if (response) {
          document.getElementById("full-loader").style.display = "none";
        }
        resolve(response);
      }
      catch (err) {
        if (err.response && err.response.status == 401){
          document.getElementById("full-loader").style.display = "none";  
          localStorage.removeItem("auth");
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          localStorage.removeItem("userData");
          localStorage.removeItem("sessionActive"); 
          window.location = '#/login'  
        } 
          
        reject(err)        
      }
    })    
  }

  static _opts(module, method, data) {
    const opts = {
      method: method,
      url: `${config.baseImgAPIDomain}/${module}`          
    }

    if (localStorage.auth) {
      const auth = JSON.parse(localStorage.auth)
      console.log('auth')      
      console.log(auth)      
      opts.headers = { 'Authorization': `Bearer ${auth.token}` }
    }      

    if (data)
      opts.data = data

    return opts
  }
}