<template>
  <div class="full-height">
    <div class="container-fluid main-content">
      <div class="submain-content">
        <div class="row">
          <div class="col-8 title-header pa-0">
            <span
              :name="`wqt`"
              tag="span"
              v-if="selectedProject"
              :class="['thin']"
              style="margin-left: 0px !important; border-bottom: none !important;"
            >{{selectedProject.name}}</span>
            <!-- <span href="#">Sections</span> -->
          </div>
          <div class="col-4 pa-0">
            <div class="title-box-small">
              <!-- <button class="btn btn-outline-dark btn-sm mr-1" @click="generateDailyReportClick()"><i class="fal fa-file-download" style="margin-right: 10px" ></i>Daily Report</button> -->
              <!-- <button class="btn btn-primary btn-sm" @click="uploadBtnClick()"><i class="fal fa-save" style="margin-right: 10px"></i>Upload Completed Poles</button> -->
              <!-- <div class="dropdown"> -->
              <div class="dropdown dropdown-right" :class="{'open': open}">
                <button class="btn btn-primary btn-sm" @click="uploadBtnClick(true)">
                  Upload
                  <!-- <span class="caret"></span> -->
                </button>
                <div
                  class="dropdown-menu dropdown-menu-vif"
                  v-if="open"
                  aria-labelledby="btnGroupDrop1"
                >
                  <a
                    class="dropdown-item"
                    href="#"
                    @click="uploadBtnClick(false)"
                  >Upload Completed Pole</a>
                  <a
                    class="dropdown-item"
                    href="#"
                    @click="uploadBtnClick(true)"
                  >Reupload All Completed Pole</a>
                </div>
                <!-- <ul class="dropdown-menu dropdown-menu-vif" v-if="open">
                    <li><a @click="handleClick(i)">HTML</a></li>
                    <li><a @click="handleClick(i)">HTML</a></li>
                </ul>-->
              </div>
              <!-- </div> -->
            </div>
          </div>
        </div>
        <div class="container-fluid" style="height: 100%;">
          <div class="row height-full">
            <div
              class="col-8 height-full box-border-full"
              style="padding: 0px;margin: 12.5px 0px 0px 0px;"
            >
              <div class="box-title">
                <span>
                  Pole List
                  <span v-if="poles">({{poles.length}})</span>
                </span>
                <!-- <button v-if="!isEdit" class="btn btn-outline-dark btn-sm" style="font-size: 12px; right:12px; float: right;" @click="isEdit = !isEdit"><i class="fal fa-pencil" style="margin-right:10px;"></i>Edit</button> -->
                <select
                  v-if="!isEdit"
                  class="form-control-sm mr-2 cus-select"
                  style="float: right; height: calc(1.5rem + 2px); font-size: .5rem;"
                  @change="changeListView(selectedListOption)"
                  v-model="selectedListOption"
                >
                  <option
                    v-for="listViewOption in listViewOptions"
                    :key="listViewOption.name"
                    :value="listViewOption"
                  >{{listViewOption.name}}</option>
                </select>
                <div v-if="isEdit" style="right:12px; position: absolute; display: inline;">
                  <!-- <button class="btn btn-outline-dark btn-sm" style="font-size: 12px;" @click="isBulkEdit = !isBulkEdit"><i class="fal fa-pencil" style="margin-right: 10px"></i>Bulk Edit</button> -->
                  <button
                    class="btn btn-outline-dark btn-sm"
                    style="font-size: 12px;"
                    @click="doneEditName()"
                  >
                    <i class="fal fa-check" style="margin-right:10px;"></i>Done
                  </button>
                </div>
              </div>
              <div class="box-bulk-edit" v-if="isEdit && isBulkEdit">
                <div class="row" style="margin-left: 10px; margin-right: 10px;">
                  <div class="col-5 row">
                    <label class="col-xs-5 col-form-label" style="font-size: 13px;">Rename Info</label>
                    <div class="col-sm-7">
                      <input
                        class="form-control input-sm mt-1"
                        v-model="bulkRename.name"
                        style
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="col-5 row">
                    <label class="col-xs-3 col-form-label" style="font-size: 13px;">Start</label>
                    <div class="col-9">
                      <input
                        class="form-control input-sm mt-1"
                        v-model="bulkRename.count"
                        style="display: inline !important"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="col-2">
                    <button
                      class="btn btn-outline-dark btn-sm"
                      style="font-size: 12px; position: absolute; right: 0px"
                      @click="startChangeBulk()"
                    >Start</button>
                  </div>
                </div>
              </div>
              <div v-if="poles" style="overflow: scroll; height: calc(100% - 50px);">
                <table class="table table-striped">
                  <thead>
                    <tr v-if="!isEdit">
                      <th scope="col" style="width: 4%">#</th>
                      <th scope="col" style="width: 50%">POLE NAME</th>
                      <th scope="col" style="width: 10%">POTENTIAL HOTSPOT</th>
                      <th scope="col" style="width: 16%">INSPECTION DATE</th>
                      <th scope="col" style="width: 15%">STATUS</th>
                      <!-- <th scope="col" style="width: 15%">UPLOAD STATUS</th> -->
                    </tr>
                    <tr v-if="isEdit">
                      <th scope="col" style="width: 4%">#</th>
                      <th scope="col" style="width: 50%">POLE NAME</th>
                      <th scope="col" style="width: 10%">POTENTIAL HOTSPOT</th>
                      <th scope="col" style="width: 10%">INSPECTION DATE</th>
                      <th scope="col" style="width: 10%">STATUS</th>
                      <!-- <th scope="col" style="width: 10%">UPLOAD STATUS</th> -->
                      <th scope="col" style="width: 6%"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <!-- <span v-if="poles">{{poles}} </span> -->
                    <router-link
                      v-if="!isEdit && poles.length > 0"
                      v-for="(pole, i) in poles"
                      :key="pole.pole_id"
                      :to="`/defect-page/${selectedProject.id}/poles/${pole.id}`"
                      :name="`poles`"
                      tag="tr"
                    >
                      <th scope="row">{{setPoleAssetId(pole.name)}}</th>
                      <td>
                        <strong>{{setPoleName(pole.name)}}</strong>
                      </td>
                      <td style="font-size: 14px;">
                        <span
                          v-if="pole.highestDeltaT && pole.highestDeltaT > -1"
                          class="badge badge-pill"
                          :class="{'badge-primary':setPotentialHotspot(pole.highestDeltaT) == 'low', 'badge-warning': setPotentialHotspot(pole.highestDeltaT) == 'cautious', 'badge-danger': setPotentialHotspot(pole.highestDeltaT) == 'critical', 'badge-success': setPotentialHotspot(pole.highestDeltaT) == 'normal'}"
                        >{{Math.ceil(pole.highestDeltaT * 100) / 100}} °C</span>
                      </td>
                      <td>{{formatDate(pole.time)}}</td>
                      <td>
                        <span
                          class="badge"
                          :class="{'badge-primary':!pole.completed, 'badge-success': pole.completed}"
                        >{{setCompleteStatus(pole.completed)}}</span>
                      </td>
                      <!-- <td>
                        <span
                          class="badge"
                          :class="{'badge-primary':!pole.uploaded, 'badge-success': pole.uploaded}"
                        >{{setUploadStatus(pole.uploaded)}}</span>
                      </td>-->
                    </router-link>

                    <tr
                      v-if="isEdit"
                      v-for="pole in poles"
                      :key="pole.pole_id"
                      :name="`poles`"
                      tag="tr"
                    >
                      <td scope="row">{{setPoleAssetId(pole.name)}}</td>
                      <td>
                        <strong>{{setPoleName(pole.name)}}</strong>
                      </td>
                      <td style="font-size: 14px;">
                        <span
                          v-if="pole.highestDeltaT && pole.highestDeltaT > -1"
                          class="badge badge-pill"
                          :class="{'badge-primary':setPotentialHotspot(pole.highestDeltaT) == 'low', 'badge-warning': setPotentialHotspot(pole.highestDeltaT) == 'cautious', 'badge-danger': setPotentialHotspot(pole.highestDeltaT) == 'critical', 'badge-success': setPotentialHotspot(pole.highestDeltaT) == 'normal'}"
                        >{{Math.ceil(pole.highestDeltaT * 100) / 100}} °C</span>
                      </td>
                      <td>{{formatDate(pole.time)}}</td>
                      <td>
                        <span
                          class="badge"
                          :class="{'badge-primary':!pole.completed, 'badge-success': pole.completed}"
                        >{{setCompleteStatus(pole.completed)}}</span>
                      </td>
                      <!-- <td>
                        <span
                          class="badge"
                          :class="{'badge-primary':!pole.uploaded, 'badge-success': pole.uploaded}"
                        >{{setUploadStatus(pole.uploaded)}}</span>
                      </td>-->
                      <td>
                        <button class="btn btn-danger btn-sm" @click="delelePole(pole)">
                          <i class="fas fa-trash-alt"></i>
                        </button>
                        <button class="btn btn-info btn-sm mt-1" @click="editPoleName(pole)">
                          <i class="fas fa-pencil"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-4">
              <div style="height: 100%; margin-left: 0px; margin-right: 0px; " class="box-border">
                <div class="box-title">
                  <span>Inspection Details</span>

                  <!-- <button v-if="!isEditPoleDetails" class="btn btn-outline-dark btn-sm" style="font-size: 12px; right:25px; position: absolute;" @click="isEditPoleDetails = true"><i class="fal fa-pencil" style="margin-right:10px;"></i>Edit</button> -->
                </div>
                <div class="box-content mt-2 scrollPoleInfo">
                  <div class="fluid-container" v-if="selectedProject">
                    <div class="row">
                      <div class="col-5">
                        <span class="span-light">Project ID</span>
                      </div>
                      <div class="col-7">
                        <span class="span-bold">{{selectedProject.project_id}}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-5">
                        <span class="span-light">State</span>
                      </div>
                      <div class="col-7">
                        <span class="span-bold">{{selectedProject.state}}</span>
                      </div>
                    </div>
                    <div class="row margin-top-5">
                      <div class="col-5">
                        <span class="span-light">Station</span>
                      </div>
                      <div class="col-7">
                        <span class="span-bold">{{selectedProject.station}}</span>
                      </div>
                    </div>
                    <div class="row margin-top-5">
                      <div class="col-5">
                        <span class="span-light">Source</span>
                      </div>
                      <div class="col-7">
                        <span class="span-bold">{{selectedProject.source}}</span>
                      </div>
                    </div>
                    <div class="row margin-top-5">
                      <div class="col-5">
                        <span class="span-light">Feeder No.</span>
                      </div>
                      <div class="col-7">
                        <span class="span-bold">{{selectedProject.feeder}}</span>
                      </div>
                    </div>
                    <div class="row margin-top-5">
                      <div class="col-5">
                        <span class="span-light">Voltage</span>
                      </div>
                      <div class="col-7">
                        <span class="span-bold">{{selectedProject.feederVoltage}}</span>
                      </div>
                    </div>
                    <div class="row margin-top-5">
                      <div class="col-5">
                        <span class="span-light">Inspection Type</span>
                      </div>
                      <div class="col-7">
                        <span class="span-bold">{{selectedProject.inspectionType}}</span>
                      </div>
                    </div>
                    <div class="row margin-top-5 mb-3">
                      <div class="col-5">
                        <span class="span-light">Section</span>
                      </div>
                      <div class="col-7">
                        <span
                          class="span-bold"
                        >{{selectedProject.sectionFrom}} - {{selectedProject.sectionTo}}</span>
                      </div>
                    </div>
                    <div class="row margin-top-5">
                      <div class="col-5">
                        <span class="span-light">Pilot</span>
                      </div>
                      <div class="col-7">
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          :disabled="!isEditPoleDetails"
                          v-model="selectedProject.pilot"
                        />
                        <!-- <span class="span-bold">{{selectedProject.pilot}}</span> -->
                      </div>
                    </div>
                    <div class="row margin-top-5">
                      <div class="col-5">
                        <span class="span-light">Co Pilot</span>
                      </div>
                      <div class="col-7">
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          :disabled="!isEditPoleDetails"
                          v-model="selectedProject.coPilot"
                        />
                        <!-- <span class="span-bold">{{selectedProject.coPilot}}</span> -->
                      </div>
                    </div>
                    <div class="row margin-top-5">
                      <div class="col-5">
                        <span class="span-light">Thermographer</span>
                      </div>
                      <div class="col-7">
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          :disabled="!isEditPoleDetails"
                          v-model="selectedProject.thermographer"
                        />
                        <!-- <span class="span-bold">{{selectedProject.thermographer}}</span> -->
                      </div>
                    </div>
                    <div class="row margin-top-5">
                      <div class="col-5">
                        <span class="span-light">Section Load</span>
                      </div>
                      <div class="col-7">
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          :disabled="!isEditPoleDetails"
                          v-model="selectedProject.sectionLoad"
                        />
                        <!-- <span class="span-bold">{{selectedProject.sectionLoad}}</span> -->
                      </div>
                    </div>
                    <div class="row margin-top-5">
                      <div class="col-5">
                        <span class="span-light">Inspection Start Date</span>
                      </div>
                      <div class="col-7">
                        <!-- <span class="span-bold">{{selectedProject.inspection_date}}</span> -->
                        <!-- <input type="date" name="bday" v-model> -->
                        <datepicker
                          format="dd MMMM yyyy"
                          calendar-class="my-datepicker_calendar"
                          v-model="selectedProject.isoDate"
                          :disabled="!isEditPoleDetails"
                        ></datepicker>
                      </div>
                    </div>
                    <div class="row margin-top-5">
                      <button
                        v-if="isEditPoleDetails"
                        class="btn btn-primary btn-sm"
                        style="font-size: 12px; right:25px; position: absolute;"
                        @click="saveProjectDetails()"
                      >
                        <i class="fas fa-save" style="margin-right:10px;"></i>Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="loading-bg" v-if="isLoading">
      <div style="text-align: -webkit-center;">
        <!-- <img src="/tnbloader.gif" class="loading-icon" alt /> -->
        <h6 v-if="loadingMsg">{{loadingMsg}}</h6>
        <span v-if="loadingSubMsg">{{loadingSubMsg}}</span>
      </div>
    </div>
    <modal v-if="isShowModal" :project="selectedProject" @save="modalSave" @close="modalClose"></modal>
    <modal-edit
      v-if="isShowEditModal"
      :poleName="toEditPole.name"
      @save="confirmedEditName"
      @close="modalEditClose"
    ></modal-edit>
    <report-modal v-if="isShowReport" @save="generateReport" @close="modalReportClose"></report-modal>
  </div>
</template>

<script>
import { version } from "../../package.json";
import _ from "lodash";
import capitalize from "capitalize";
import API from "../api";
import GenericObject from "../generic_object";
import util from "util";
import path from "path";
import { exec } from "child_process";
import FileServer from "../file_server";
// import fs from 'fs';
// import storage from 'electron-json-storage';
import crypto from "crypto";
import moment from "moment";
// import {
//   throws
// } from 'assert';
import DB from "../db";
// import FileServer from '../file_server'
import DBProject from "../db_project";
// import {
//   ExifImage
// } from 'exif'
import a from "async";
// import Modal from './choose_image_folder_modal.vue'
// import EditModal from './edit_pole_name_modal.vue'
// import ReportModal from './generate_daily_report_modal.vue'
// import klawSync from 'klaw-sync'
// import sharp from 'sharp'
// import ejs from 'ejs'
import slugify from "slugify";
import Datepicker from "vuejs-datepicker";

// const currentWindow = require('electron').remote.getCurrentWindow()
// const {
//   app,
//   dialog,
//   BrowserWindow,
//   Menu
// } = require('electron').remote
// const remote = require('electron').remote
// const {
//   ipcRenderer,
//   shell
// } = require('electron')

export default {
  props: ["dbPath"],
  components: {
    // 'modal': Modal,
    // 'modal-edit': EditModal,
    // 'report-modal': ReportModal,
    datepicker: Datepicker
  },
  computed: {
    versionNumber() {
      return version;
    },

    originalImageURL() {
      return this.selected.section &&
        this.selected.section.getMetadata("original_image_url")
        ? this.selected.section.getMetadata("original_image_url")
        : "";
    },

    zoomifyURL() {
      return this.selected.section &&
        this.selected.section.getMetadata("zoomify_url")
        ? this.selected.section.getMetadata("zoomify_url")
        : "";
    }
  },
  data() {
    return {
      open: false,
      isShowEditModal: false,
      isShowReport: false,
      allPoles: null,
      selectedListOption: null,
      listViewOptions: [
        {
          name: "ALL"
        },
        {
          name: "IN-PROGRESS"
        },
        {
          name: "COMPLETED"
        }
      ],
      listPole: null,
      isEditPoleDetails: false,
      loadingSubMsg: null,
      isLoading: false,
      loadingMsg: null,
      drafts: null,
      imagePath: null,
      awsPath: null,
      projects: null,
      selectedProject: null,
      poles: null,
      totalFiles: "",
      imagesPath: [],
      populatedData: [],
      source: null,
      isSyncWithImage: false,
      populatedImage: null,
      isEdit: false,
      folderPath: null,
      polesName: [],
      isCheckAll: false,
      isBulkEdit: false,
      bulkRename: {
        name: null,
        count: null
      },
      toEditPole: null,
      remoteImages: null,
      isShowModal: false,
      projectPath: null
    };
  },

  watch: {
    projects: function() {
      // console.log(this.projects)
      // this.selectedProject = this.projects.find(project => project.project_id == this.$route.params[`id`])
      // console.log(this.selectedProject)
    },
    selectedProject: async function() {
      console.log(this.selectedProject, "sdfsdf");
      if (this.selectedProject) {
        this.projectPath = this.selectedProject.sectionName;
        this.allPoles = (await DBProject.getPolesByProjectId(
          this.selectedProject.id
        )).sort(
          (a, b) =>
            a.name.replace(/(^\d+)(.+$)/i, "$1") -
            b.name.replace(/(^\d+)(.+$)/i, "$1")
        );
        if (this.allPoles && this.allPoles.length > 0) {
          this.allPoles.forEach(pole => {
            pole.isSelected = false;
          });
        }
        console.log(this.allPoles);
        var polesWithInvalidDate = this.allPoles.filter(
          pole => this.formatDate(pole.time) == "Invalid date"
        );
        if (polesWithInvalidDate.length > 0) {
          await this.setInvalidDate(polesWithInvalidDate);
        }
        console.log(polesWithInvalidDate);
        this.selectedListOption = this.listViewOptions[0];
        this.changeListView(this.selectedListOption);
        console.log(this.selectedListOption);
        console.log(this.poles);

        var imageByProject = await DBProject.getImagesByProjectId(
          this.selectedProject.id
        );

        this.populatedImage = imageByProject.filter(
          image => image.directory != null
        );
        console.log(this.populatedImage);
        this.loadingMsg = null;
        this.isLoading = false;
      }
    },

    isCheckAll: function() {
      console.log(this.isCheckAll);
      if (this.isCheckAll) {
        this.poles.forEach(pole => {
          pole.isSelected = true;
        });
      } else {
        this.poles.forEach(pole => {
          pole.isSelected = false;
        });
      }
    }
  },

  methods: {
    setInvalidDate(poles) {
      return new Promise((resolve, reject) => {
        try {
          console.log(poles);
          a.eachSeries(
            poles,
            async (pole, callback) => {
              console.log(pole);
              this.loadingMsg = `fixing data ${pole.name}`;
              var polePath = path.join(this.projectPath, pole.name);
              var images = await DBProject.getImagesByPoleId(pole.id);
              var updatedImages = await this.setImagesDate(polePath, images);
              // console.log(updatedImages)
              updatedImages.sort(function(a, b) {
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return new Date() - new Date();
              });
              console.log(updatedImages);
              pole.time = "";
              await DBProject.updatePoleTime(pole);
              if (callback) {
                callback();
              }
            },
            err => {
              if (err) {
                reject(err.message);
              }
              resolve(poles);
            }
          );
        } catch (err) {
          if (err) alert(err.message);
        }
      });
    },
    setImagesDate(polePath, images) {
      return new Promise((resolve, reject) => {
        try {
          console.log(images);
          a.eachSeries(
            images,
            async (image, callback) => {
              console.log(image);
              var exif = await this.readFileExif(
                this.setImagePath(polePath, image)
              );
              console.log(exif);
              var exifTime = exif
                ? exif.exif.DateTimeOriginal
                : moment().format("YYYY-MM-DD HH:mm");
              var time = `${exifTime.split(" ")[0].replace(/:/g, "-")} ${
                exifTime.split(" ")[1]
              }`;
              image.timestamp = time;
              await DBProject.updateImageTime(image);
              console.log(time);
              if (callback) {
                callback();
              }
            },
            err => {
              if (err) {
                reject(err.message);
              }
              resolve(images);
            }
          );
        } catch (err) {
          if (err) alert(err.message);
        }
      });
    },
    setImagePath(polePath, image) {
      // console.log(image)
      var name;
      if (image.crop_image_name) {
        name = image.crop_image_name;
      } else if (image.imageName) {
        name = image.imageName;
      } else {
        name = image.name;
      }
      //  image.crop_image_name ? image.crop_image_name : image.name
      // console.log(name)
      var dir = path.join(polePath, image.type.toUpperCase());
      return path.join(dir, name);
    },
    setISODate(date) {
      console.log(date.split("/")[2]);
      console.log(date.split("/")[1]);
      console.log(date.split("/")[0]);
      console.log(
        new Date(
          `${date.split("/")[1]}-${date.split("/")[0]}-${date.split("/")[2]}`
        )
      );
      return new Date(
        `${date.split("/")[1]}-${date.split("/")[0]}-${date.split("/")[2]}`
      );
    },
    async delelePole(pole) {
      console.log(pole);
      if (
        confirm(
          `Are you sure want to delete this ${pole.name}? \n *this action cannot be undone`
        )
      ) {
        await DBProject.deletePole(pole);
        this.poles.splice(this.poles.indexOf(this.pole), 1);
        var polePath = path.join(this.projectPath, pole.name);

        this.deleteFolderRecursive(polePath);
      }
    },
    async editPoleName(pole) {
      console.log(pole);
      this.toEditPole = pole;
      this.isShowEditModal = true;
    },
    async confirmedEditName(name) {
      console.log(name);
      var polePath = path.join(this.projectPath, this.toEditPole.name);
      console.log(polePath);
      try {
        if (fs.existsSync(polePath)) {
          fs.renameSync(polePath, path.join(this.projectPath, name));

          console.log("success change folder name");
          await DBProject.editPoleName(this.toEditPole, name);
          this.poles.map(pole => {
            if (pole == this.toEditPole) {
              return (pole.name = name);
            }
          });
          console.log(this.poles);
        } else {
          alert("pole folder not found");
        }
      } catch (err) {
        if (err) alert(err.message);
      }

      this.isShowEditModal = false;
    },
    deleteFolderRecursive(path) {
      try {
        if (fs.existsSync(path)) {
          var self = this;
          fs.readdirSync(path).forEach(function(file, index) {
            var curPath = path + "/" + file;
            if (fs.lstatSync(curPath).isDirectory()) {
              // recurse
              self.deleteFolderRecursive(curPath);
            } else {
              // delete file
              fs.unlinkSync(curPath);
            }
          });
          fs.rmdirSync(path);
        }
      } catch (err) {
        // handle the error
        console.log(err.message);
      }
    },
    setPoleName(name) {
      // console.log(name.replace('^', '*'))
      return name.replace("^", "*").split(/ (.+)/)[1];
    },
    setPoleAssetId(name) {
      // console.log(name.replace('^', '*'))
      return name.split(/ (.+)/)[0];
    },
    changeListView(type) {
      console.log(type);
      if (type.name == "IN-PROGRESS") {
        this.poles = this.allPoles.filter(pole => pole.completed == false);
      } else if (type.name == "COMPLETED") {
        this.poles = this.allPoles.filter(pole => pole.completed == true);
      } else {
        this.poles = this.allPoles;
      }
    },
    async saveProjectDetails() {
      this.isEditPoleDetails = false;
      // console.log(this.selectedProject)
      this.selectedProject.inspection_date = moment(
        this.selectedProject.isoDate
      ).format("DD/MM/YYYY");
      this.selectedProject.project_id = crypto
        .createHash("md5")
        .update(
          `${this.selectedProject.section_id}_${this.selectedProject.inspection_date}`
            .replace(/\s/g, "")
            .toLowerCase()
        )
        .digest("hex");
      console.log(this.selectedProject);
      await DBProject.editProject(this.selectedProject);
    },
    setPotentialHotspot(deltaT) {
      // console.log(deltaT)
      var potentialDeltaT =
        deltaT >= 1 && deltaT < 3
          ? "low"
          : deltaT >= 3 && deltaT < 15
          ? "cautious"
          : deltaT >= 15
          ? "critical"
          : "normal";
      // console.log(potentialDeltaT)
      return potentialDeltaT;
    },
    formatDate(date) {
      // console.log(moment(date).format('lll'))
      return moment(date).format("lll");
    },
    async generateReport(e) {
      console.log(e);
      this.isShowReport = false;
      var reportPath = path
        .join(app.getAppPath(), "report")
        .replace("/\\/g", "/");
      var feeder = await DB.getFeederBySectionId(
        this.selectedProject.section_id
      );
      console.log(reportPath);
      let reportData = {
        dir: path.join(app.getAppPath(), "report").replace(/\\/g, "/"),
        feeder: feeder ? feeder.name : "",
        location: this.selectedProject.sectionName,
        poles: this.poles.length,
        pilot: this.selectedProject.pilot,
        copilot: this.selectedProject.coPilot,
        thermographer: this.selectedProject.thermographer,
        report_date: moment().format("DD/MM/YYYY"),
        inspection_type: "",
        data: []
      };

      var defectDate = e;
      var defectsForReport = await DBProject.getDefectReport(defectDate);

      if (defectsForReport && defectsForReport.length > 0) {
        a.eachSeries(
          defectsForReport,
          (defect, callback) => {
            if (defect.type == "visual") {
              console.log(defect.snapshots);
              var dummySnapShots = [];
              for (let index = 0; index < 4; index++) {
                dummySnapShots.push({
                  name: null,
                  position: index + 1,
                  path: null
                });
              }
              dummySnapShots.forEach(dummySnapshot => {
                defect.snapshots.forEach(snapshot => {
                  if (snapshot.position == dummySnapshot.position) {
                    console.log(dummySnapshot);
                    dummySnapshot.id = snapshot.id;
                    dummySnapshot.name = snapshot.name;
                    dummySnapshot.path = snapshot.path;
                  }
                });
              });
              console.log(dummySnapShots);
              reportData.data.push({
                type: "visual", //visual or thermal
                pole_no: this.setPoleName(defect.poleName.split(/ (.+)/)[1]),
                date: moment(defect.time).format("DD/MM/YYYY"),
                time: moment(defect.time).format("HH:mm"),
                pole_type:
                  defect.pole_type != null
                    ? `${defect.inspection_type} (${defect.pole_type})`
                    : this.selectedProject.inspectionType,
                voltage: this.selectedProject.feederVoltage,
                temp: defect.temp,
                load:
                  this.selectedProject.sectionLoad != ""
                    ? this.selectedProject.sectionLoad
                    : "UNKNOWN",
                phase: defect.phase,
                humidity: defect.humidity,
                coordinate: defect.poleLat + "," + defect.poleLng,
                severity: defect.severity,
                //component defect
                component: defect.component,
                defect: defect.name,
                recommendation: defect.recommendation,
                remark: defect.remark,
                image_1: dummySnapShots[0].path ? dummySnapShots[0].path : "",
                image_2: dummySnapShots[1].path ? dummySnapShots[1].path : "",
                image_3: dummySnapShots[2].path ? dummySnapShots[2].path : "",
                image_4: dummySnapShots[3].path ? dummySnapShots[3].path : ""
                //end component defect
              });
            } else if (defect.type == "thermal") {
              //console.log(defect)
              // if(defect)
              var dummySnapShots = [];
              for (let index = 0; index < 4; index++) {
                dummySnapShots.push({
                  name: null,
                  position: index + 1,
                  path: null
                });
              }
              dummySnapShots.forEach(dummySnapshot => {
                defect.snapshots.forEach(snapshot => {
                  if (snapshot.position == dummySnapshot.position) {
                    console.log(dummySnapshot);
                    dummySnapshot.id = snapshot.id;
                    dummySnapshot.name = snapshot.name;
                    dummySnapshot.path = snapshot.path;
                  }
                });
              });
              console.log(dummySnapShots);
              console.log(defect.thermalImages[0]);
              var data = _.fromPairs(
                defect.thermalImages[0].thermal_data
                  .split(", ")
                  .map(s => s.split(": "))
              );
              console.log(data.DeltaT.replace(/[^0-9.]/g, ""));
              var detlaT = defect.thermalImages[0].thermal_deltaT;
              reportData.data.push({
                type: "thermal", //visual or thermal
                pole_no: this.setPoleName(defect.poleName.split(/ (.+)/)[1]),
                date: moment(defect.time).format("DD/MM/YYYY"),
                time: moment(defect.time).format("HH:mm"),
                pole_type:
                  defect.pole_type != null
                    ? `${defect.inspection_type} (${defect.pole_type})`
                    : this.selectedProject.inspectionType,
                voltage: this.selectedProject.feederVoltage,
                temp: defect.temp,
                load:
                  this.selectedProject.sectionLoad != ""
                    ? this.selectedProject.sectionLoad
                    : "UNKNOWN",
                phase: defect.phase,
                humidity: defect.humidity,
                coordinate: defect.poleLat + "," + defect.poleLng,
                severity: defect.severity,
                sp1: Math.ceil(data.SP1 * 100) / 100,
                sp2: Math.ceil(data.SP2 * 100) / 100,
                dt1: Math.ceil(detlaT * 100) / 100,
                //component defect
                component: defect.component,
                defect: defect.name,
                recommendation: defect.recommendation,
                remark: defect.remark,
                image_1: defect.thermalImages[0]
                  ? defect.thermalImages[0].path
                  : "",
                image_2: dummySnapShots[0] ? dummySnapShots[0].path : "",
                image_3: dummySnapShots[1] ? dummySnapShots[1].path : ""
                // image_4: dummySnapShots[3].path ? defect.snapshots[3].path : ''

                // image_1: imageUrls[0],
                // image_2: imageUrls[1],
                // image_3: imageUrls[2]
                //end component defect
              });
            }
            if (callback) {
              callback();
            }
          },
          async err => {
            if (err) alert(err);
          }
        );
      } else {
        alert("no defect created today");
      }

      if (reportData.data && reportData.data.length > 0) {
        let win = new BrowserWindow({
          width: 800,
          height: 600,
          parent: remote.getCurrentWindow(),
          modal: true,
          webPreferences: {
            webSecurity: false
          }
        });

        var CurrentDate = moment().format("DD_MM_YYYY_HH_mm_ss");
        console.log(CurrentDate);
        const menuTemplate = [
          {
            label: "Report",
            submenu: [
              {
                label: "Save",
                accelerator: "CmdOrCtrl+S",
                click: () => {
                  var self = this;
                  var filename = `${slugify(
                    this.selectedProject.sectionName
                  )}_${CurrentDate}_daily_report.pdf`;
                  // dialog.showSaveDialog(win, {
                  //   properties: ['openDirectory'],
                  //   title: 'Save Report',
                  //   defaultPath : filename
                  // }, path => {
                  var saveReportPath = path.join(this.projectPath, filename);
                  if (typeof saveReportPath !== "undefined") {
                    console.log(saveReportPath);
                    win.webContents.printToPDF(this.pdfSettings(), function(
                      err,
                      data
                    ) {
                      if (err) {
                        //do whatever you want
                        alert(err);
                        return;
                      }
                      try {
                        console.log(saveReportPath);
                        fs.writeFileSync(`${saveReportPath}`, data);
                        alert(`Report saved at ${saveReportPath}`);
                      } catch (err) {
                        //unable to save pdf..
                        alert("unable to save pdf");
                      }
                    });
                  }
                  // })
                }
              },
              {
                label: "Quit",
                accelerator: "CmdOrCtrl+W",
                click: () => {
                  console.log("Quit");
                  win.close();
                }
              }
            ]
          }
        ];
        const menu = Menu.buildFromTemplate(menuTemplate);
        // Menu.setApplicationMenu(menu);
        win.setMenu(menu);
        let options = { root: __dirname };
        var reportPath =
          this.selectedProject.inspectionType == "ABC"
            ? path.join(app.getAppPath(), "report/defect-report-abc.ejs")
            : path.join(app.getAppPath(), "report/defect-report-bare.ejs");
        console.log(reportData);
        ejs.renderFile(reportPath, reportData, options, function(err, str) {
          if (err) {
            console.log(err);
          }
          // Load the rendered HTML to the BrowserWindow.
          // console.log(str)
          win.loadURL("data:text/html;charset=utf-8," + encodeURI(str));
          win.webContents.on("did-finish-load", function() {});
          win.webContents.on("did-finish-load", () => {
            console.log("finish load");
          });
          // win.webContents.openDevTools()

          // ipcRenderer.send('open-report', `data:text/html;charset=utf-8, ${encodeURI(str)}`)
        });
      }
    },
    async generateDailyReportClick() {
      this.isShowReport = true;
      // this.generateReport()
    },
    pdfSettings() {
      var paperSizeArray = ["A4", "A5"];
      var option = {
        landscape: false,
        marginsType: 0,
        printBackground: true,
        printSelectionOnly: false
        // pageSize: paperSizeArray[settingCache.getPrintPaperSize()-1],
      };
      return option;
    },
    async uploadBtnClick(reuploadall) {
      this.open = false;
      console.log("uploadBtnClick");
      var answer = window.confirm(
        "Are you sure want to upload your correction??"
      );
      if (!answer) {
        //some code
      } else {
        //upload all images
        var completedPoles = await DBProject.getCompletedPolesImagesByProjectId(
          this.selectedProject.id,
          reuploadall
        );
        console.log(completedPoles);
        // debugger;
        if (navigator.onLine) {
          if (completedPoles.length > 0) {
            var uploadImage = null;
            a.eachSeries(
              completedPoles,
              async (pole, callback) => {
                console.log(completedPoles.indexOf(pole));
                this.loadingMsg = `Uploading Pole ${
                  pole.name
                }, ( ${completedPoles.indexOf(pole) + 1} out of ${
                  completedPoles.length
                } )`;

                //allow upload without images
                // debugger;
                uploadImage = await this.uploadImages(pole);

                if (callback) {
                  callback();
                }
              },
              async err => {
                if (err) throw err;
                console.log("complete upload");
                if (!uploadImage) {
                  try {
                    console.log(this.selectedProject);
                    var alldata = await DBProject.getallData();
                    debugger;
                    new FileServer(this.selectedProject.sectionName)
                      .uploadProject(this.selectedProject, alldata)
                      .then(async url => {
                        console.log(url);
                        // await API.post(
                        //   `sections/${this.selectedProject.section_id}/uploadgrid`,
                        //   {
                        //     url: url
                        //   }
                        // ).then(async res => {
                        // console.log(url);
                        // if (url.errors) {
                        //   alert(
                        //     new Error(`Error: ${JSON.stringify(res.data.errors)}`)
                        //   );
                        // }
                        console.log(completedPoles);
                        try {
                          this.poles = await DBProject.getPolesByProjectId(
                            this.selectedProject.id
                          );
                        } catch (err) {
                          if (err) alert(err.message);
                        }
                        this.isLoading = false;
                        this.$emit("clicked", "someValue");
                        // });
                      })
                      .catch(err => {
                        throw err;
                      });
                  } catch (err) {
                    if (err) alert(err);
                  }
                } else {
                  console.log(uploadImage.message);
                  this.isLoading = false;
                }
              }
            );
          } else {
            alert("No pole to upload");
          }
        } else {
          alert("It seems you dont have internet connections");
        }
      }
    },
    uploadImages(pole) {
      return new Promise(async (resolve, reject) => {
        this.isLoading = true;
        try {
          // debugger;
          // console.log(pole.name);
          var polePath = path.join(this.projectPath, pole.name);
          console.log(polePath);
          // var visualImages = await this.uploadVisualImages(
          //   polePath,
          //   pole,
          //   pole.visuals
          // );
          if (pole.defectsVisual && pole.defectsVisual.length > 0) {
            await this.getDefectToUpload(polePath, pole, pole.defectsVisual);
          }

          if (pole.defectsThermal && pole.defectsThermal.length > 0) {
            await this.getDefectToUpload(polePath, pole, pole.defectsThermal);
          }

          resolve();

          console.log("pole done");
        } catch (err) {
          if (err) throw err;
        }
      });
    },
    async uploadVisualImages(polePath, pole, visualImages) {
      for (const key in visualImages) {
        if (visualImages.hasOwnProperty(key)) {
          const element = visualImages[key];
        }
      }
      // return new Promise(async (resolve, reject) => {
      //   var self = this;
      //   console.log(polePath, pole, visualImages);
      //   debugger;
      //   a.eachSeries(
      //     visualImages,
      //     async (image, index, callback) => {
      //       console.log(polePath);

      //       this.loadingSubMsg = `Uploading Visual Images ${pole.name}, ${
      //         image.name
      //       } ( ${visualImages.indexOf(image) + 1} out of ${
      //         visualImages.length
      //       } )`;
      //       var imagepath = await this.resizeImage(image, polePath);
      //       var dir = path.join(polePath, image.type.toUpperCase());
      //       console.log(path.join(dir, image.name));
      //       console.log(image);
      //       console.log(uploadedImages);
      //       var uploadedImages = await new FileServer(
      //         path.join(dir, image.name)
      //       ).upload(self.selectedProject, pole);
      //       var newImage = await DBProject.updateUploadedUrls(
      //         image,
      //         uploadedImages
      //       );
      //       await DBProject.updateImageUploadStatus(image);

      //       if (callback) {
      //         callback();
      //       }
      //     },
      //     async err => {
      //       if (err) {
      //         // console.log(err)
      //         alert(err.message);
      //         this.isLoading = false;
      //         resolve(err);
      //       }
      //       console.log("visual end");

      //       resolve();
      //     }
      //   );
      // });
    },
    async uploadThermalImages(polePath, pole, thermalImages) {
      return new Promise(async (resolve, reject) => {
        var self = this;
        a.eachSeries(
          thermalImages,
          async (image, index, callback) => {
            this.loadingSubMsg = `Uploading Thermal Images ${pole.name}, ${
              image.name
            }  ( ${thermalImages.indexOf(image) + 1} out of ${
              thermalImages.length
            } )`;
            var dir = path.join(polePath, image.type.toUpperCase());
            var uploadedImages = await new FileServer(
              path.join(dir, image.name)
            ).uploadThermal(self.selectedProject, pole);
            await DBProject.updateUploadedUrls(image, uploadedImages);

            await DBProject.updateImageUploadStatus(image);
            // fileServer.uploadThermal(self.selectedProject, pole).then(async (uploadedImages)=>{
            // })
            if (callback) {
              callback();
            }
          },
          async err => {
            if (err) {
              alert(err.message);
              this.isLoading = false;
              resolve(err);
            }
            console.log("thermal end");
            resolve();
          }
        );
      });
    },
    async getDefectToUpload(polePath, pole, defects) {
      return new Promise(async (resolve, reject) => {
        // debugger;
        a.eachSeries(
          defects,
          async (defect, callback) => {
            console.log("getDefectToUpload");
            console.log(defect);
            await this.uploadSnapshot(
              defect.snapshots,
              polePath,
              pole,
              defect.type
            );
            if (callback) {
              callback();
            }
          },
          async err => {
            if (err) throw err;
            console.log("defects end");
            resolve();
          }
        );
      });
    },
    async uploadSnapshot(snapshots, polePath, pole, defectType) {
      return new Promise(async (resolve, reject) => {
        // debugger;
        var self = this;
        a.eachSeries(
          snapshots,
          async (snapshot, callback) => {
            this.loadingMsg = `Uploading defect ${polePath}, ${
              snapshot.name
            } ( ${snapshots.indexOf(snapshot) + 1} out of ${
              snapshots.length
            } )`;
            // var snapshotDir = path.join(polePath, defectType.toUpperCase());
            var imagepath = await this.resizeSnapshot(snapshot, polePath);
            if (imagepath == 1) {
              // debugger;
            } else {
              // debugger;
              var uploadedImages = await new FileServer(
                path.join(polePath, imagepath.type + "/" + imagepath.name)
              ).upload(self.selectedProject, pole);
              // snapshot.name=imagepath.name;
              await DBProject.updateUploadedSnapshotUrls(
                snapshot,
                uploadedImages
              );
            }
            // .then((image)=>{
            //   })
            if (callback) {
              callback();
            }
          },
          async err => {
            if (err) {
              // alert(err.message)
              console.log(err);
              this.isLoading = false;
              resolve(err);
            }
            console.log("defects end");
            resolve();
          }
        );
      });
    },
    async resizeSnapshot(snapshot, snapshotPath) {
      try {
        if (
          snapshot.url_large &&
          snapshot.url_large != "" &&
          snapshot.name.includes("http") == false
        ) {
          return 1;
        } else {
          var str = snapshot.name;
          var res = str.split("/");
          var datatosend = {
            URL: snapshot.name,
            name: res[res.length - 1],
            type: res[res.length - 2],
            polepath: snapshotPath
          };
          var data = await API.post("resize", datatosend);
          return datatosend;
        }
      } catch (err) {
        if (err) {
          alert(err.message);
          console.log(err);
        }
      }
    },
    resizeImage(image, polePath) {
      return new Promise(async (resolve, reject) => {
        try {
          console.log(image);
          var dir = path.join(polePath, image.type.toUpperCase());
          console.log(dir);
          // console.log(path.join(dir, image.crop_image_name))
          var imagePath = image.crop_image_name
            ? `${path.join(dir, image.crop_image_name)}`
            : `${path.join(dir, image.name)}`;
          console.log(imagePath);
          const resize = size =>
            sharp(imagePath)
              .resize(size, (size / 4) * 3)
              .toFile(
                `${path.join(dir, image.name.replace(".", `-${size}.`))}`
              );

          Promise.all([1024, 720, 200].map(resize)).then(err => {
            console.log(`complete ${image.name}`);
            var imagePath = {
              large: null,
              med: null,
              thumb: null
            };

            if (
              fs.existsSync(
                `${path.join(dir, image.name.replace(".", "-1024."))}`
              )
            ) {
              console.log("large exist");
              imagePath.large = `${path.join(
                dir,
                image.name.replace(".", "-1024.")
              )}`;
            }

            if (
              fs.existsSync(
                `${path.join(dir, image.name.replace(".", "-720."))}`
              )
            ) {
              console.log("med exist");
              imagePath.med = `${path.join(
                dir,
                image.name.replace(".", "-720.")
              )}`;
            }

            if (
              fs.existsSync(
                `${path.join(dir, image.name.replace(".", "-200."))}`
              )
            ) {
              console.log("thumb exist");
              imagePath.thumb = `${path.join(
                dir,
                image.name.replace(".", "-200.")
              )}`;
            }
            // console.log(imagePath)
            resolve(imagePath);
          });
          // resolve()
        } catch (err) {
          if (err) reject(err);
        }
      });
    },
    setCompleteStatus(isCompleted) {
      return isCompleted ? "complete" : "in-progress";
    },
    setUploadStatus(uploaded) {
      return uploaded ? "Uploaded" : "not upload";
    },
    showModal() {
      this.isShowModal = true;
    },
    modalClose() {
      this.isShowModal = false;
    },
    modalEditClose() {
      this.isShowEditModal = false;
    },
    modalReportClose() {
      this.isShowReport = false;
    },
    async modalSave(e) {
      this.isShowModal = false;
      this.isLoading = true;
      console.log(e);
      var project = e.project;

      var projectPath = path.join(
        path.dirname(localStorage.getItem("projectdb_path")),
        project.sectionName
      );
      console.log(projectPath);
      // var projectPath = path.join(project.projectPath, project.name.replace(/\>/g,'-'))
      // await DBProject.updateProjectPath(project)

      console.log(project);
      if (!fs.existsSync(projectPath)) {
        fs.mkdirSync(projectPath);
      }
      var newPoles = [];
      a.eachSeries(
        project.poles,
        async (pole, callback) => {
          this.loadingMsg = "Copying images...";
          var poleDir = path.join(projectPath, pole.name);
          if (!fs.existsSync(poleDir)) {
            fs.mkdirSync(poleDir);
          }

          var poleRes = await this.copyVisualImages(poleDir, pole);
          newPoles.push(poleRes);
          console.log("poleRes");
          console.log(poleRes);

          if (callback) callback();
        },
        async err => {
          if (err) {
            throw err;
          }

          project.poles = newPoles;
          console.log(project);
          console.log("done create pole folder");

          this.loadingMsg = "Saving images...";
          // await DBProject.saveProjects(project)
          this.selectedProject = await this.savePoles(project);
          this.poles = await DBProject.getPolesByProjectId(
            this.selectedProject.id
          );
          console.log(this.selectedProject);
          this.isLoading = false;
        }
      );
    },

    async savePoles(project) {
      return new Promise((resolve, reject) => {
        console.log("save pole");
        console.log(project);
        try {
          a.eachSeries(
            project.poles,
            async (pole, callback) => {
              console.log(pole);
              await DBProject.savePoles(project.id, pole);

              if (callback) {
                callback();
              }
            },
            err => {
              if (err) resolve();

              resolve(project);
            }
          );
        } catch (err) {
          throw err;
        }
      });
    },

    // savingImageFromServer(project)  {
    //   const projectDir = project.projectPath
    //   console.log(projectDir)
    //   if (!fs.existsSync(projectDir)) {
    //     console.log(fs.existsSync(projectDir))
    //     fs.mkdirSync(projectDir)
    //   }
    //   var newPoles = []
    //   a.eachSeries(project.poles, async(pole, callback) => {

    //     this.loadingMsg = 'Copying images...'
    //     var poleDir = path.join(projectDir, pole.name)
    //     if (!fs.existsSync(poleDir)) {
    //       fs.mkdirSync(poleDir)
    //     }

    //     var poleRes = await this.copyVisualImages(poleDir, pole)
    //     newPoles.push(poleRes)
    //     console.log(poleRes)

    //     if (callback)
    //       callback()
    //   }, async(err) => {
    //     if (err) {
    //       throw err
    //     }

    //     console.log(newPoles)
    //     project.poles = newPoles
    //     console.log(project)
    //     console.log('done create pole folder')

    //     this.loadingMsg = 'Saving images...'
    //     await DBProject.saveProjects(project)
    //     this.projects = await DBProject.initDB()
    //     this.isLoading = false

    //   })
    // },
    copyVisualImages(poleDir, pole) {
      return new Promise((resolve, reject) => {
        try {
          var visuals = pole.visuals;
          var visualDir = path.join(poleDir, "VISUAL");
          if (!fs.existsSync(visualDir)) {
            fs.mkdirSync(visualDir);
          }
          a.eachSeries(
            visuals,
            async (image, callback) => {
              console.log("visuals");

              var imgDir = path.join(visualDir, image.name);
              console.log(imgDir);
              console.log(image.source);

              if (image.source) {
                fs.copyFileSync(image.source, imgDir, err => {
                  if (err) {
                    console.log(err);
                    throw err;
                  }
                  // console.log('success');
                });

                // image.dest = visualDir
                var stats = fs.statSync(imgDir);
                image.visual_id = crypto
                  .createHash("md5")
                  .update(`${stats["size"]}_${image.name}`)
                  .digest("hex");
                console.log(image);
              }

              if (callback) callback();
            },
            err => {
              if (err) {
                throw err;
                console.log(err);
              }
              console.log("done copy visual image");

              var thermals = pole.thermals;
              var thermalDir = path.join(poleDir, "THERMAL");
              if (!fs.existsSync(thermalDir)) {
                fs.mkdirSync(thermalDir);
              }
              a.eachSeries(
                thermals,
                async (image, callback) => {
                  var imgDir = path.join(thermalDir, image.name);
                  fs.copyFileSync(image.source, imgDir, err => {
                    if (err) throw err;
                    // console.log('success');
                  });

                  // image.dest = thermalDir
                  var stats = fs.statSync(imgDir);
                  image.thermal_id = crypto
                    .createHash("md5")
                    .update(`${stats["size"]}_${image.name}`)
                    .digest("hex");
                  console.log(image);

                  if (callback) callback();
                },
                err => {
                  if (err) {
                    throw err;
                  }
                  resolve(pole);
                  console.log("done copy thermal image");
                }
              );
            }
          );
        } catch (err) {
          reject(err);
        }
      });
    },
    doneEditName() {
      this.isEdit = !this.isEdit;
      console.log(this.poles);
      // this.updatePoleName()
    },
    filesChange(events) {
      let files = events.target.files;
      console.log(files);
    },
    isEmpty(obj) {
      console.log(obj);
      for (var key in obj) {
        if (obj.hasOwnProperty(key)) return false;
      }
      return true;
    },

    async browseImage() {
      // var filesTotal = ''
      dialog.showOpenDialog(
        currentWindow,
        {
          properties: ["openDirectory"]
        },
        paths => {
          if (typeof paths !== "undefined") {
            console.log(paths[0]);
            this.setFiles(paths[0]);
          }
        }
      );
    },

    async setFiles(dirPath) {
      var files = fs
        .readdirSync(dirPath)
        .filter(file => file.includes(".JPG") || file.includes(".jpg"));
      this.imagesPath = [];
      this.populatedData = [];
      console.log(files);
      console.log(dirPath);
      this.remoteImages = await DBProject.getImagesByProjectId(
        this.$route.params[`id`]
      );
      console.log(this.remoteImages);

      const images = (await this.readFiles(dirPath, files)).filter(image => {
        const img = this.remoteImages.find(i => i.image_id == image.image_id);

        if (typeof img !== "undefined") image.destPath = img.path;

        return typeof img !== "undefined";
      });
      console.log(images);
      if (images.length > 0) {
        this.populatedImage = images;
        console.log(this.populatedImage);
      }
      this.isSyncWithImage = true;
    },

    async updateImagePath(images) {
      return new Promise((resolve, reject) => {
        a.eachSeries(
          images,
          async (image, callback) => {
            await DBProject.updateImageDirectory(image);
            // console.log(img)
            if (callback) callback();
          },
          err => {
            if (err) return reject(err);

            resolve();
          }
        );
      });
    },

    readFileExif(file) {
      return new Promise((resolve, reject) => {
        try {
          new ExifImage(
            {
              image: file
            },
            (err, data) => {
              if (err) alert(`${err} ${file}`);
              // return reject(err)

              resolve(data);
            }
          );
        } catch (err) {
          alert(err.message);
          // reject(err)
        }
      });
    },

    readFiles(imgPath, files) {
      return new Promise((resolve, reject) => {
        const images = [];

        a.eachSeries(
          files,
          async (file, callback) => {
            // const data = await this.readFileExif(path.join(imgPath, file))
            var stats = fs.statSync(path.join(imgPath, file));
            const filename = path.basename(file).replace(".JPG", ".jpg");
            // const createDate = `${data.exif.CreateDate.split(' ')[0].replace(/:/g, '-')} ${data.exif.CreateDate.split(' ')[1]}`
            // const timestamp = moment(createDate).format('X')
            // const timestamp = moment(createDate).format('YYYY-MM-DD HH:mm')
            var fileSizeInBytes = stats["size"];

            // console.log(filename)
            // .replace('.JPG', '.jpg')
            images.push({
              image_id: crypto
                .createHash("md5")
                .update(`${fileSizeInBytes}_${filename}`)
                .digest("hex"),
              path: imgPath,
              name: filename,
              fileSizeInBytes: fileSizeInBytes
            });

            if (callback) callback();
          },
          err => {
            if (err) return reject(err);
            resolve(images);
          }
        );
      });
    },

    setPolesDir() {
      var dir = storage.getDefaultDataPath() + "/images/";
      var projectName = this.selectedProject.name;
      var projectDir = dir + projectName;
      console.log(projectDir);
      if (fs.existsSync(storage.getDefaultDataPath() + "/images/")) {
        console.log("dir exist");
        if (!fs.existsSync(projectDir)) {
          fs.mkdirSync(projectDir);
        }
        this.poles.forEach(pole => {
          var poleName = pole.name;
          console.log(projectDir + "/" + poleName);
          if (!fs.existsSync(projectDir + "/" + poleName)) {
            console.log("pole dir not exist");
            fs.mkdirSync(projectDir + "/" + poleName);
          } else {
            console.log("pole dir already exist");
          }
        });
        return projectDir;
      } else {
        throws("image folder not exist");
        return;
      }
    },
    startChangeBulk() {
      if (this.bulkRename.name && this.bulkRename.count) {
        console.log(this.bulkRename);
        a.eachSeries(
          this.poles,
          async (pole, callback) => {
            if (pole.isSelected) {
              pole.name = this.bulkRename.name + " " + this.bulkRename.count;
              this.bulkRename.count++;
            }
            if (callback) callback();
          },
          err => {
            if (err) return err;

            console.log(this.poles);
            this.isBulkEdit = !this.isBulkEdit;
            this.bulkRename = {
              name: null,
              count: null
            };
          }
        );
      }
    },
    async updatePoleName() {
      return new Promise((resolve, reject) => {
        a.eachSeries(
          this.poles,
          async (pole, callback) => {
            await DBProject.updatePoleName(pole);
            console.log(pole);
            if (callback) callback();
          },
          err => {
            if (err) return reject(err);

            resolve();
          }
        );
      });
    }
  },

  async mounted() {
    try {
      var self = this;
      // var path = this.$route.params['dbpath']
      this.isLoading = true;
      this.selectedProject = await DBProject.getProject();
      this.selectedProject.isoDate = this.setISODate(
        this.selectedProject.inspection_date
      );
      console.log(this.selectedProject);
    } catch (err) {
      alert(err.message);
    }
  }
};
</script>
<style scoped>
::-webkit-scrollbar {
  display: none;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  border: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

body {
  background: #ebebeb;
}

/* #app, */
#app {
  height: 100vh;
  background: white;
}
.full-height {
  height: 100%;
}

.navbar {
  height: 50px;
  margin-bottom: 0px;
  background: #808368;
}

@media (min-width: 768px) {
  .navbar {
    border-radius: 0px;
  }
}

.logo {
  /* width: 46px;
  height: 46px; */
  object-fit: contain;
  margin-right: 8px;
}

.navbar-brand > img {
  display: inline;
}

.box {
  margin: 0px auto;
  margin-bottom: 40px;
}

.no-margin {
  margin: 0px !important;
}

.img_box {
  margin-bottom: 10px;
}

.img-thumb {
  height: 100%;
  background: rgba(158, 158, 158, 0.25);
  border-width: 2px !important;
  cursor: pointer;
  border-radius: 5px;
}

.img-thumb-side {
  width: 100%;
  background: rgba(158, 158, 158, 0.25);
  cursor: pointer;
  /* border-radius: 5px;  */
  border: 2px solid #989898;
}

.img-thumb-container {
  height: 100%;
  float: left;
  position: relative;
}

.img-thumb-side-container {
  width: 100%;
  float: left;
  position: relative;
}

.add-img-thumb {
  font-size: 43px;
  height: 100%;
  border: 2px dashed #cccccc;
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.add-img-thumb img {
  height: 24px;
}

.img-thumb-text-container {
  position: absolute;
  bottom: 4px;
  left: 0px;
  right: 4px;
  background: rgba(25, 25, 25, 0.25);
  font-size: 10px;
  color: white;
  height: 30%;
  white-space: nowrap;
  text-overflow: clip;
  display: flex !important;
  align-items: center !important;
}

.add-img-snapshot {
  font-size: 12px;
  height: 100%;
  border: 1px solid #848282;
  background: #989898;
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
}

.add-img-thumb .far {
  font-size: 24px !important;
}

.add-img-thumb .text {
  font-size: 14px !important;
}

.title-header {
  width: auto;
  padding-top: 30px;
  padding-bottom: 0px;
  padding-left: 16px !important;
}

.title-header span {
  cursor: default;
  font-size: 15px;
  font-weight: normal !important;
}

.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > tbody > tr > td {
  border: none !important;
  color: #5a6169;
  font-size: 10px;
  border-bottom: 1px solid #d7dee6 !important;
}

.table > tbody > tr > td.with-border {
  border: 1px solid #dee2e6 !important;
}

.table > tbody > tr > td.with-border.severity {
  cursor: pointer !important;
}

.table > tbody > tr > td.with-border.severity:active {
  background-color: rgba(26, 25, 25, 0.5) !important;
}

.table > thead > tr > th {
  border: none !important;
  color: #9ba4ae;
  font-size: 10px;
  font-weight: lighter;
}

.border-3 {
  border-width: 5px !important;
}

.table > tbody > tr.is-last {
  border-bottom: 1px solid rgba(255, 255, 255, 0.356) !important;
}

.table-hover tbody tr:hover {
  background-color: rgba(73, 73, 73, 0.175) !important;
}

tbody tr:active {
  background-color: rgba(73, 73, 73, 0.175) !important;
}
tbody tr.with-border:active {
  background: none !important;
}

.table {
  color: #fff !important;
}

.table thead {
  border-bottom: 1px solid #d7dee6 !important;
}

.table td.severity {
  padding: 0.5rem 5px;
}

ul {
  list-style-type: none;
  padding-left: 10px;
  height: calc(100% - 33px);
}

th {
  width: 20%;
}

.input-sm {
  height: 25px !important;
  /* padding: 5px 10px; */
  font-size: 10px !important;
  line-height: 1.5;
  border-radius: 3px;
}

th:nth-child(1),
th:nth-child(2),
th:nth-child(3) {
}

#site-title {
  color: #fff;
  font-size: 14px;
}

::placeholder {
  color: rgba(95, 95, 95, 0.308) !important;
  opacity: 1;
  /* Firefox */
  font-size: 12px;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(95, 95, 95, 0.308);
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgba(95, 95, 95, 0.308);
}

.table-windparks li > a {
  padding: 7px;
  color: #858585;
}

.table-windparks li.active > a {
  font-weight: 600;
  color: #1e87fa;
}

#full-height-table {
  height: 100%;
}

.container {
  margin-left: 0px;
  margin-right: 0px;
  /* height: calc(100% - 54px);
  display: table; */
  /* width: 100%; */
  /* margin-top: -50px;
    padding: 50px 0 0 0; /*set left/right padding according to needs*/
  /* */
  /* box-sizing: border-box; */
  /* padding-left: 0px; */
}

/* .row {
  height: 100%;
  display: table-row;
} */

/* .row .no-float {
  display: table-cell;
  float: none;
} */

.row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.table-responsive {
  margin-bottom: 0px;
}

.table-title {
  background: #efeff4;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-left: 1px solid #abb4bd !important;
  border-right: 1px solid #abb4bd !important;
  border-top: 1px solid #abb4bd !important;
}

.display-inline {
  display: inline;
  margin-right: 14px;
}

textarea {
  resize: none;
}

.box-title {
  font-size: 12px;
  background: #efeff4;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #abb4bd !important;
}

.box-content {
  font-size: 12px;
}

.box-title-no-padding {
  background: #efeff4;
  /* padding-left: 10px;
  padding-right: 10px; */
  border-bottom: 1px solid #abb4bd !important;
}

.box-bulk-edit {
  background: #efeff4;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-bottom: 1px solid #abb4bd !important;
}

.box-bulk-edit span {
  background: #efeff4;
  font-size: 10px;
}

.box-middle {
  overflow: scroll;
  height: calc(100% - 56px);
}

.box-overlay {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  bottom: 0px;
  color: white;
  height: 50px;
}

tbody tr {
  cursor: pointer;
}

tbody tr.with-border {
  cursor: default;
}

.box-bottom {
  position: absolute;
  bottom: 0px;
  width: 100%;
}

.checkbox-margin {
  margin: 10px;
  font-size: 14px;
  font-weight: bold;
}

.box-title-full-border {
  background: #efeff4;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #abb4bd !important;
  border-top: 1px solid #abb4bd !important;
}

.box-border {
  border-left: 1px solid #abb4bd !important;
  border-right: 1px solid #abb4bd !important;
  border-top: 1px solid #abb4bd !important;
}

.box-border-full {
  border-left: 1px solid #abb4bd !important;
  border-right: 1px solid #abb4bd !important;
  border-top: 1px solid #abb4bd !important;
  border-bottom: 1px solid #abb4bd !important;
}

.box-border-no-border-left {
  border-right: 1px solid #abb4bd !important;
  border-top: 1px solid #abb4bd !important;
}

.table-body {
  height: 40%;
  overflow: scroll;
  background: #fff;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-left: 1px solid #abb4bd !important;
  border-right: 1px solid #abb4bd !important;
  border-top: 1px solid #abb4bd !important;
  border-bottom: 1px solid #abb4bd !important;
}

.table-body-no-border-bottom {
  height: 40%;
  overflow: scroll;
  background: #fff;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-left: 1px solid #abb4bd !important;
  border-right: 1px solid #abb4bd !important;
  border-top: 1px solid #abb4bd !important;
}

.inherit-height {
  height: inherit;
}

.main-content {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  height: calc(75vh - 50px);
  padding-left: 0px;
  padding-right: 0px;
}

.sub-main-header {
  background: #f7f8fb;
  /* margin-bottom: 20px; */
  height: 55px;
}

.submain-content {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  height: calc(100% - 40px);
  padding-bottom: 5px;
}

/* .submain-content::-webkit-scrollbar{
  display: inline;
} */

.img_box img {
  width: 200px;
  height: 150px;
  margin: 0px auto;
}

.btn_action {
  margin: 0px auto;
}

.title-box-small {
  margin-right: 30px
  /* color: #fff;
  font-family: 'Lato-Bold'; */
  /* padding: 15px 0px 15px 0px; */
  /* text-align: center; */
}

.margin-top-5 {
  margin-top: 5px;
}

.span-light {
  /* padding-left: 15px; */
  font-size: 12px;
  font-weight: lighter;
}

.span-bold {
  word-wrap: break-word;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 2px !important;
}

.main-table {
  background-color: #ffffff;
  border: 1px solid #4d5d7a;
}

.table {
  margin-bottom: 0px;
  padding-bottom: 6px;
}

.btn-add {
  bottom: 0px;
}

.btn-sm {
  font-size: 10px !important;
}

i {
  margin-right: 5px;
}

.left-section {
  padding-left: 0px;
}

.url {
  padding: 10px;
  background: #fff;
  margin-bottom: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
  outline: none;
  height: 59px;
  width: 100%;
  font-size: 12px;
}

.browsed {
  background: transparent;
  padding: 0;
  border: none;
  overflow: hidden;
  width: 100%;
  font-size: 11px;
  margin-bottom: 5px;
  outline: none;
  height: 16px;
}

#overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.87);
  height: 100%;
}

#overlay textarea {
  background: transparent;
  border: none;
  padding: 50px;
  display: block;
  width: 100%;
  height: 100%;
  outline: none;
  color: #fff;
  font-family: monospace;
  font-size: 14px;
}

/* #overlay button {
  position: absolute;
  color: #fff;
  right: 15px;
  top: 15px;
  background: transparent;
  padding: 7px 10px;
  border: 1px solid #fff;
  font-size: 10px;
  border-radius: 3px;
} */

.btn-outline-dark {
  box-shadow: none !important;
}

.form-container {
  margin-top: 20px;
}

.form-container h2 {
  font-weight: normal;
  font-size: 15px;
  margin-bottom: 20px;
  border-bottom: 1px solid #fff;
  padding-bottom: 5px;
}

td ul {
  overflow-y: scroll;
  overflow-x: hidden;
}

.input-search {
  display: inline !important;
  width: fit-content !important;
  height: 32px !important;
  vertical-align: middle;
}

.p1 {
  background: #000;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

/* .p1 img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
} */

.p1 canvas {
  /* width: calc(100vw - 373px); */
  /* background: red; */
  /* height: calc(100vh - 354px); */
}

.add-defect-container {
  position: absolute;
  z-index: 999;
  /*right: 10px;
  bottom: 55px;
  height: auto; */
  /* width: 100%; */
}

#custom-defect-button {
  font-size: 12px;
  background: #007bff;
  width: 100%;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #495057;
  border-left: 1px solid #495057;
  border-right: 1px solid #495057;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

#defects {
  /* position: absolute;
  right: 10px; */
  font-size: 12px;
  width: 100%;
  margin: 0;
  padding: 0;
  /* height: auto; */
  /* display: inline !important; */
  float: right;
  max-height: 150px;
  background: #fff;
  overflow: scroll;
  border: 1px solid #495057;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

#severity {
  position: absolute;
  left: 100%;
  z-index: 99999;
  /* right: 10px; */
  font-size: 12px;
  width: 100%;
  margin-right: 5px;
  padding: 0;
  /* height: auto; */
  /* bottom: 55px; */
  height: auto;
  background: #fff;
  overflow: scroll;
  border: 1px solid #495057;
  border-radius: 4px;
}

#defects .defects-header,
#severity .defects-header {
  background: #eee;
  padding: 5px 10px;
  border-bottom: 1px solid #dedede;
  font-weight: bold;
  display: block;
}

#defects .defect,
#severity .defect {
  border-bottom: 1px solid #eee;
}
#custom-defect-button .custom-defect {
  text-align: center;
  /* border-bottom: 1px solid #eee; */
  padding: 5px 10px;
  /* background: #007bff; */
}

#custom-defect-button .custom-defect a {
  display: block;
  color: white;
  text-decoration: none;
}

#defects .defect a,
#severity .defect a {
  display: block;
  padding: 5px 10px;
  background: #fff;
  color: #444;
  text-decoration: none;
}

#defects .defect a:hover #severity .defect a:hover {
  background-color: #eee;
}

.loading-bg {
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.12);
  /* display: inline; */
  position: absolute;
  top: 0px;
  display: flex;
  left: 0;
  align-items: center;
  /* font-size: 4rem; */
  color: white;
}

.loading-log {
}

.loading-bg img {
  display: block;
}
.loading-bg div {
  /* width: 3rem; */
  /* position: absolute;
  left: calc(50% - 2rem);
  top: calc(50% - 2rem); */
  text-align: -webkit-center;
  width: 80%;
  margin: auto;
}

.loading-bg .small {
  left: 50%;

  top: 50%;

  width: 300px;

  margin-left: -150px;

  font-size: 10px;

  line-height: 12px;
}

.loading-bg .small img {
  margin-bottom: 10px;
}

.is-active {
  background: #fdb714 !important;
  color: #fff !important;
}

.is-tab-active {
  background: #c4c3c327 !important;
  color: #fff !important;
}

.is-tab-active a {
  color: #fff !important;
  font-weight: bold;
}

.tabs a:hover {
  color: #fff !important;
}

nav .nav-pills .nav-link {
  border: 1px solid transparent;
  border-radius: 0 !important;
  color: black !important;
  cursor: pointer;
}

.height-full {
  height: 100%;
}

.width-full {
  width: 100% !important;
}

nav .nav-pills .nav-link.active {
  background-color: white;
  border-bottom: 2px solid #ea1863;
  color: black;
  font-weight: bolder;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-mask-big {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* display: table; */
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  height: 100%;
  width: 80%;
  position: relative;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-container-choose-image {
  height: 80vh;
  width: calc(100vw - 80px);
  position: relative;
  margin: 0px auto;
  /* padding: 20px 30px; */
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-container-small {
  /* height: 80%; */
  width: 60%;
  position: relative;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-container-video {
  width: auto;
  margin: 0px auto;
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  text-align: center;
}

.modal-header {
  padding: 0 0 5px 0 !important;
}
.modal-header-choose-image {
  background: #efeff4;
  padding: 10px !important;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  /* border-bottom: 1px solid #444 */
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 5px 0;
}

.modal-default-button {
  float: right;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.modal-button-at-bottom-right-small {
  width: 100%;
  display: inline-table;
  padding-bottom: 15px;
  padding-left: 30px;
  padding-right: 30px;
}

.modal-button-at-bottom-right {
  width: 100%;
  display: inline-table;
  padding-bottom: 15px;
  padding-left: 30px;
  padding-right: 30px;
  position: absolute;
  bottom: 0px;
  left: 0px;
}

.modal-button-at-bottom-right .button.is-save {
  background: #08a86b !important;
  color: #fff;
  margin-right: 5px;
  float: right;
}

.modal-button-at-bottom-right .button.is-cancel {
  background: red !important;
  color: #fff;
  float: right;
}

.select select {
  background-color: transparent !important;
  border: 1px solid #fff;
  border-radius: 5px !important;
  color: #000 !important;
  font-size: 14px !important;
}

.custom-file {
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  z-index: 0 !important;
}

.custom-file-label {
  height: calc(2rem + 2px) !important;
  z-index: 0 !important;
}

.custom-file-label::after {
  height: 2rem !important;
  z-index: 0 !important;
}

.custom-file-input {
  height: calc(2rem + 2px);
  z-index: 0 !important;
}

.height-full {
  height: 100%;
}

.zoom-main-bg {
  position: absolute;
  top: 0px;
  background: #fff;
  border-radius: 3px;
  margin: 5px;
}

.zoom-item {
  width: 24px;
  height: 24px;
  object-position: center;
  text-align: center;
  font-size: 12px;
}

.list-group-item {
  cursor: pointer;
  border-radius: 0 !important;
  padding: 0.55rem 0.5rem 0.55rem 1.25rem !important;
  background: #dfe5ee !important;
  font-size: 12px;
}

.list-group-item.selected {
  background: rgba(2, 130, 250, 0.527) !important;
}

.list-group-item.selected-side-list {
  background: #283c4f !important;
  color: white;
}

.list-group-item-title {
  color: white;
  /* font-weight: bold; */
  /* height: 40px; */
  cursor: default;
  background: #4d5d7a !important;
  font-size: 14px;
  vertical-align: center;
}

.list-group-item-title span {
  vertical-align: middle;
  height: 100%;
}

.selection-col {
  height: 100%;
  width: 250px;
  background: rgb(223, 229, 238);
  display: block;
  border-right: 1px solid rgb(77, 93, 122);
  float: left;
  flex: 0 0 30%;
}

.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 26px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  border: 1px solid white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(22px);
  -ms-transform: translateX(22px);
  transform: translateX(22px);
}

.dark-item {
  background: #283c4f;
  font-size: 14px;
  height: 40px;
  color: white;
  line-height: 40px;
  cursor: pointer;
  font-size: 12px;
}

.light-items {
  font-size: 14px;
  background: #ffffff;
  height: 40px;
  color: black;
  line-height: 40px;
  border-bottom: 1px solid #b3b3b3;
  cursor: pointer;
}

.small-title {
  font-size: 14px;
  background: #f4f4f4;
  height: 40px;
  color: black;
  line-height: 40px;
  border-bottom: 1px solid #b3b3b3;
  font-size: 12px;
}

.img-large {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0.25rem;
  width: 100%;
}

.ripple {
  color: #42a4ff;
  background-position: center;
  transition: background 0.8s;
  cursor: pointer;
  border-bottom: 1px solid #cccccc;
}
.ripple:hover {
  background: #cccccc radial-gradient(circle, transparent 1%, #cccccc 1%)
    center/15000%;
}
.ripple:active {
  background-color: #cccccc;
  background-size: 100%;
  transition: background 0s;
}

.thumb-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  transition: background 0.5s ease;
}

.text-container-overlay {
  /* position: absolute;
  top: 0;
  left: 0; */
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  transition: background 0.5s ease;
}

.text-container:hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.068);
}

.img-thumb-container:hover .thumb-overlay {
  display: block;
  background: rgba(0, 0, 0, 0.068);
  cursor: pointer;
}

.btn-overlay-hover {
  position: absolute;
  width: 100%;
  height: 24px;
  font-size: 12px;
  margin: auto;
  left: 0;
  bottom: 0;
  text-align: center;
  opacity: 0;
  width: 93%;
  margin-left: 4px;
  margin-bottom: 4px;
  transition: opacity 0.35s ease !important;
}

.btn-delete-overlay-hover {
  position: absolute;
  /* width: 100%; */
  height: 24px;
  font-size: 12px;
  margin: auto;
  right: 4px;
  top: 4px;
  text-align: center;
  opacity: 0;
  margin-left: 4px;
  margin-bottom: 4px;
  transition: opacity 0.35s ease !important;
}

.img-thumb-container:hover .btn-delete-overlay-hover {
  opacity: 1;
}

.text-container:hover .btn-overlay-hover,
.img-thumb-container:hover .btn-overlay-hover {
  opacity: 1;
  background: #cccccc;
}

.img-view-container {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.51);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
}

.img-view-container img {
  height: 90%;
  display: block !important;
  margin: auto !important;
}

.badge-noseverity {
  color: #fff;
  background-color: #ff00ff;
}

#delelte-option {
  position: absolute;
  left: 100%;
  top: 0;
  z-index: 99999;
  /* right: 10px; */
  font-size: 12px;
  margin-right: 5px;
  padding: 0;
  /* height: auto; */
  /* bottom: 55px; */
  height: auto;
  background: #fff;
  overflow: scroll;
  border: 1px solid #495057;
  border-radius: 4px;
}

#delelte-option .defects-header {
  background: #eee;
  padding: 5px 10px;
  border-bottom: 1px solid #dedede;
  font-weight: bold;
  display: block;
  color: black;
}

.my-datepicker_calendar {
  width: 100%;
  height: 330px;
}

.dropdown-right {
  float: right;
}

.dropdown-menu-vif {
  display: block !important;
  right: 0;
  left: unset;
}
.scrollPoleInfo {
  overflow: scroll;
  height: inherit;
}
.col-4 .box-border {
  overflow: scroll;
  border-bottom: 1px solid #abb4bd !important;
  height: calc(67vh - 21.5px) !important;
}
.cus-select {
  border: 1px solid #aab4bc;
  background-color: white;
  color: black;
}
</style>
<style scoped src="@/../public/bootstrap/css/bootstrap.min.css"></style>
<style scoped src="@/../public/css/fontawesome-pro-5.1.1-web/css/all.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>