<template>
  <transition name="modal">
    <div class="modal-mask-big">
      <!-- class="modal-wrapper" -->
      <div class="pt-xl-5 pb-xl-5" style="height: 100%;">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header">
              <h5>Add Defect</h5>
              <button
                class="btn btn-outline-dark btn-sm"
                @click="customClick"
                style="float: right"
              >Custom Defect</button>
              <button class="btn btn-outline-dark btn-sm" @click="close" style="float: right">
                <i class="fas fa-times"></i>
              </button>
            </slot>
          </div>
          <div
            class="modal-body"
            style="height: calc(100% - 58px); overflow: scroll; background-color: white;"
          >
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th
                    class="with-border"
                    scope="col"
                    style="width: 30%; text-align: center;"
                  >COMPONENTS</th>
                  <th
                    class="with-border"
                    scope="col"
                    style="width: 30%; text-align: center;"
                  >DEFECTS</th>
                  <th
                    class="with-border"
                    scope="col"
                    colspan="4"
                    style="width: 40%; text-align: center;"
                  >SEVERITY</th>
                </tr>
              </thead>
              <tbody v-if="compenentsDefects">
                <template v-for="component in compenentsDefects">
                  <tr
                    class="with-border"
                    v-for="(defect, index) in component.defects"
                    :key="`${component.name}_${defect.name}`"
                  >
                    <td
                      class="with-border"
                      v-if="index == 0"
                      scope="col"
                      :rowspan="component.defects.length"
                      style="width: 30%; text-align: center; font-weight: bold;"
                    >{{component.name}}</td>
                    <td
                      class="with-border"
                      scope="col"
                      style="width: 30%; text-align: center; font-weight: bold;"
                    >{{defect.name}}</td>
                    <td class="with-border severity" scope="col" style="width: 10%;">
                      <button
                        @click="defectSelected(component.name, defect, 'Notify')"
                        class="btn btn-sm"
                        style="background:#FF00FF; width: 100%; color: white; text-align: center;"
                      >NOTIFY</button>
                    </td>
                    <td class="with-border severity" scope="col" style="width: 10%;">
                      <button
                        @click="defectSelected(component.name, defect, 'Low')"
                        class="btn btn-sm"
                        style="background:#4082d4; width: 100%; color: white; text-align: center;"
                      >LOW</button>
                    </td>
                    <td class="with-border severity" scope="col" style="10%;">
                      <button
                        @click="defectSelected(component.name, defect, 'Cautious')"
                        class="btn btn-sm"
                        style="background:yellow; width: 100%; color: black; text-align: center;"
                      >CAUTIOUS</button>
                    </td>
                    <td class="with-border severity" scope="col" style=" width: 10%;;">
                      <button
                        @click="defectSelected(component.name, defect, 'Critical')"
                        class="btn btn-sm"
                        style="background:red; width: 100%; color: white; text-align: center;"
                      >CRITICAL</button>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import $ from "jquery";
import Datepicker from "vuejs-datepicker";
import GenericObject from "../generic_object";
import stateData from "../state_data.json";
import fs from "fs";
import path from "path";
import readdir from "recursive-readdir";
import moment from "moment";
// import a from 'async'
// import crypto from 'crypto'
// import klawSync from 'klaw-sync'
import componentsJson from "../components.json";
import componentsBAREJson from "../components_bare.json";
import componentsThermalJson from "../components_thermal.json";
// const browserWindow = require('electron').remote.getCurrentWindow()
// const {
//     dialog
// } = require('electron').remote
export default {
  template: "#modal-template",
  props: ["compenentsDefects"],
  components: {
    datepicker: Datepicker
    // 'vue-good-table': VueGoodTable
  },
  data() {
    return {
      errors: []
    };
  },

  computed: {},

  watch: {},

  methods: {
    defectSelected(component, defect, severity) {
      var duration =
        severity.toLowerCase() == "low"
          ? "within 6 month"
          : severity.toLowerCase() == "cautious"
          ? "within 3 month"
          : severity.toLowerCase() == "critical"
          ? "Immediately"
          : "";
      var recommendation = `Recommend for an engineering inspection to be carried out and advisable to repair ${duration}.`;
      var selectedDefect = {
        component: component,
        name: defect.name,
        severity: severity,
        phase: "UNKNOWN",
        remark: defect.remark ? defect.remark : "",
        recommendation: recommendation,
        created_at: moment().format("YYYY-MM-DD HH:mm:ss")
      };
      console.log(selectedDefect);
      this.$emit("selected", selectedDefect);
    },
    close() {
      this.$emit("close");
    },
    customClick() {
      this.$emit("custom");
    }
  },

  mounted() {
    console.log("modal mounted");
    console.log(this.compenentsDefects);
  }
};
</script>

<style scoped src="@/../public/bootstrap/css/bootstrap.min.css"></style>
<style scoped src="@/../public/css/fontawesome-pro-5.1.1-web/css/all.css"></style>
<style scoped>

.modal-mask-big {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* display: table; */
  transition: opacity 0.3s ease;
}

.modal-container {
  height: 100%;
  width: 80%;
  position: relative;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header {
  padding: 0 0 5px 0 !important;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 5px 0;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>