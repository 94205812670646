<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container-choose-image">
          <div class="modal-header-choose-image">
            <slot name="header">
              <span>
                <strong>Select images for :</strong>
                {{defect.component +' > ' + defect.name + ' > ' + defect.severity }}
              </span>
              <div style="float: right; cursor:pointer;" @click="close()">
                <i class="fas fa-times mr-2"></i>
              </div>
              <input type="file" style="margin: auto; float: right;" @change="addNewImage" />
              <!-- <image-compressor :done="getFiles" :scale="scale" :quality="quality"></image-compressor> -->
              <button
                class="btn btn-sm btn-primary mr-2"
                style="margin: auto; float: right;"
                @click="save()"
              >Save</button>
            </slot>
          </div>
          <div class="modal-body-choose-image" style="height: calc(100% - 50px); overflow: hidden;">
            <div class="container-fluid" style="height: 100%;">
              <div class="row" style="height: 100%;">
                <div class="col-sm-2" style="height: 100%; overflow: hidden;">
                  <div
                    class="mt-2 mb-2"
                    style="height: 100%; overflow: scroll;"
                    v-if="images && images.length > 0"
                  >
                    <div
                      class="pt-1 pb-1 pr-1 pl-1 img-thumb-side-container"
                      v-for="image in images"
                      :key="image.id"
                      @click="selectedImageToShow(image)"
                    >
                      <img class="img-thumb-side" :src="setListImagePath(image)" />
                      <div
                        style="position: absolute; bottom: 4px; left:4px; right:4px; background: rgba(25, 25, 25, 0.25; font-size: 12px; color: white;"
                        class="pl-1 pb-1 pr-1 pt-1"
                      >
                        <button
                          class="btn btn-sm width-full"
                          :class="setSelectBtnClass(image)"
                          style="margin: auto"
                          @click="setSelectedImage(image, $event)"
                        >Select</button>
                      </div>
                      <!-- <div v-if="image.isTopDownImage" style="color: white; position: absolute; top: 7px; left: 10px; object-position: center; text-align: center; font-size: 13px;">
                                                <span class="badge badge-pill badge-primary">Top</span>
                      </div>-->
                    </div>
                  </div>
                </div>
                <div class="col-sm-10">
                  <img
                    v-if="selectedImageToView"
                    class="img-large"
                    :src="setListImagePath(selectedImageToView,1)"
                  />
                </div>
              </div>
            </div>
          </div>

          <!-- <slot name="footer">
                        <div class="modal-button-at-bottom-right-small">
                            <div style="    padding-top: 15px; border-top: 1px solid #e9ecef !important;">
                                <b-button :size="'sm'" :variant="'danger'" style="float:right;" @click="close">
                                    Cancel
                                </b-button>
                                <b-button :size="'sm'" :variant="'success'" class="mr-1" style="float:right;" @click="save">
                                    Save
                                </b-button>
                            </div>
                        </div>
          </slot>-->
        </div>

        <div class="loading-bg" v-if="isLoading">
          <div style="text-align: -webkit-center;">
            <!-- <img src="public/img/tnbloader.gif" class="loading-icon" alt /> -->
            <span v-if="loadingMsg">{{loadingMsg}}</span>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
// import $ from 'jquery'
import path from "path";

// import DB from '../db'
import DBProject from "../db_project";
import API from "../api";
import { log } from "util";
import FileServer from "../file_server";
import slugify from "slugify";
import Compressor from "compressorjs";
// import a from 'async'
// const browserWindow = require('electron').remote.getCurrentWindow()
// const {
//     dialog
// } = require('electron').remote
export default {
  template: "#modal-template",
  components: {},
  props: ["defect", "propsImages"],
  data() {
    return {
      images: null,
      errors: [],
      isLoading: false,
      loadingMsg: null,
      imgToView: null,
      selectedImageToView: null,

      img: "",
      scale: 100,
      quality: 7,
      originalSize: true,
      original: {}
    };
  },

  computed: {},

  watch: {},

  methods: {
    setSelectBtnClass(image) {
      // console.log(image.isSelected)
      if (image.isSelected) {
        return "btn-success";
      } else {
        return "btn-secondary";
      }
    },
    selectedImageToShow(image) {
      this.selectedImageToView = image;
    },
    setSelectedImage(image, e) {
      console.log(e);
      image.isSelected = !image.isSelected;
      if (e.target.classList.contains("btn-secondary")) {
        e.target.classList.remove("btn-secondary");
        e.target.classList.add("btn-success");
      } else {
        e.target.classList.add("btn-secondary");
        e.target.classList.remove("btn-success");
      }
      // console.log(this.images)
      // this.images.map(img => {
      //     if(img.id == image.id){
      //         img.isSelected = !img.isSelected
      //         console.log(this.images)
      //     }
      //     return this.images
      // })

      // this.images[index].isSelected = !this.images[index].isSelected
    },
    close() {
      console.log("close modal");
      console.log(this.images);
      this.$emit("close");
    },
    async save() {
      console.log("save modal");
      var selectedImages = this.images.filter(img => img.isSelected == true);
      var defects = [];
      console.log(selectedImages);
      selectedImages.forEach(image => {
        defects.push({
          defect_id: this.defect.id,
          image_id: image.id
        });
      });
      console.log(defects);
      var saveDefectImages = await DBProject.addDefectImages(
        this.defect.pole_id,
        defects
      );
      // console.log(saveDefectImages)
      this.$emit("save", saveDefectImages);
    },

    async addNewImage(e) {
      console.log("addNewImage");
      // console.log(this.images);
      console.log(this.images.length);
      let _id = Date.now();
      let image = {
        id: _id,
        name: "Image Name",
        isTopDownImage: null,
        image_id: _id,
        lat: "",
        lng: "",
        type: "visual",
        pole_id: this.images[0].pole_id,
        directory: null,
        edited_image_id: null,
        thermal_data: null,
        crop_image_name: null,
        uploaded: 1,
        thermal_deltaT: null,
        isPoleNumber: 0,
        timestamp: new Date(),
        _id: `${_id}`,
        polePath: this.images[0].polePath,
        isSelected: true
      };

      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0], 1)
        .then(org_url => {
          this.createImage(files[0], 1024).then(large_url => {
            this.createImage(files[0], 720).then(medium_url => {
              this.createImage(files[0], 200).then(async small_url => {
                console.log(
                  "Upload Completed - Push the image now then save to pouch",
                  org_url,
                  large_url,
                  medium_url,
                  small_url
                );
                image.name = files[0].name;
                image.image_url_org =
                  "https://medias.vertikaliti.com/tnb/" + org_url;
                image.image_url_large =
                  "https://medias.vertikaliti.com/tnb/" + large_url;
                image.image_url_med =
                  "https://medias.vertikaliti.com/tnb/" + medium_url;
                image.image_url_thumb =
                  "https://medias.vertikaliti.com/tnb/" + small_url;
                this.images.push(image);
                let response = await DBProject.addImage(image);
                console.log(response);                
              });
            });
          });
        })
        .catch(err => {
          console.log(err);
        });
    },

    createImage(file, resolution) {
      return new Promise((re, rx) => {
        var image = new Image();
        var reader = new FileReader();
        let urlSplit = this.images[0].image_url_large.split("/");
        var vm = this;
        new Compressor(file, {
          quality: resolution === 1 ? 0.1 : 1,
          width: resolution,
          height: (resolution / 4) * 3,
          success(result) {
            console.log("Quality - " + resolution === 1 ? 0.1 : 1);

            console.log(result);
            //  console.log(new File(result, result.name));

            reader.onload = e => {
              vm.image = e.target.result;
            };
            reader.onloadend = () => {
              console.log(vm.image);
              let fileServer = new FileServer("");
              let Key = "";
              let filename = slugify(file.name.toUpperCase()).replace(
                ".",
                `-${resolution === 1 ? "Org." : resolution}.`
              );
              Key = "dev/" + urlSplit[4] + "/" + urlSplit[5] + "/" + filename;
              console.log(Key);
              fileServer._uploadImage(Key, vm.image).then(url => {
                re(url);
              });
            };
            reader.readAsDataURL(result);
          }
        });
      });
    },

    setListImagePath(image, flag) {
      // console.log(image)
      var name = image.crop_image_name ? image.crop_image_name : image.name;
      // console.log(name)

      // var dir = path.join(image.polePath, image.type.toUpperCase())
      if (flag == 1) {
        return image.image_url_original;
      } else {
        return image.image_url_thumb;
      }
      // return path.join(image.polePath, name)
    }
  },

  mounted() {
    console.log("modal choose image for defect mounted");
    // console.log(this.propsImages)
    this.images = this.propsImages.sort((a, b) => b.isSelected - a.isSelected);
  }
};
</script>

<style scoped src="@/../public/bootstrap/css/bootstrap.min.css"></style>
<style scoped src="@/../public/css/fontawesome-pro-5.1.1-web/css/all.css"></style>
<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  height: 100%;
  width: 80%;
  position: relative;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-container-choose-image {
  height: 80vh;
  width: calc(100vw - 80px);
  position: relative;
  margin: 0px auto;
  /* padding: 20px 30px; */
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header {
  padding: 0 0 5px 0 !important;
}
.modal-header-choose-image {
  background: #efeff4;
  padding: 10px !important;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  /* border-bottom: 1px solid #444 */
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 5px 0;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.img-thumb-side-container {
  width: 100%;
  float: left;
  position: relative;
}
.width-full {
  width: 100% !important;
}
</style>