import AWS from 'aws-sdk'
import fs from "fs";
import path from "path";
import a from "async";
import slugify from "slugify";
import API from "./api";
import AdminApi from "./services/admin_api";

export default class FileServer {
  constructor(fullRespath) {
    this.endpoint = "http://52.221.118.205:9000";
    this.secureEndpoint = "https://medias.vertikaliti.com";
    this.fullRespath = fullRespath;
    console.log(this.fullRespath);
    this.fakeId = `${Math.round(
      new Date().getTime() / 1000
    )}${this.getRandomInt()}`;

    this.s3 = new AWS.S3({
      accessKeyId: 'R9EZEUQGH70VFYQELQ3M',
      secretAccessKey: 'VhQe7hzZEqc9TtQSEpndsbGBH3xIMYd59+0vNczt',
      endpoint: this.endpoint,
      s3ForcePathStyle: true,
      signatureVersion: 'v4'
    })
  }

  getRandomInt() {
    const min = 1000;
    const max = 9999;
    return Math.floor(Math.random() * (max - min + 1)) + min;

  }

  upload(project, pole) {
    return new Promise((resolve, reject) => {
      //   debugger;
      console.log(this.fullRespath);
      var filename = path.basename(this.fullRespath);
      console.log(filename);
      var pathName = path.dirname(this.fullRespath);
      console.log(pathName);
      const images = {
        full: path.join(pathName, filename.replace(".", "-1024.")),
        medium: path.join(pathName, filename.replace(".", "-720.")),
        thumb: path.join(pathName, filename.replace(".", "-200."))
      };
      const urls = {
        full: null,
        medium: null,
        thumb: null
      };

      console.log(project);
      console.log(images);
      var awsPath = `${slugify(project.section_id)}/${slugify(
        project.inspection_date
      )}`;
      console.log("aws path");
      console.log(awsPath);
      a.eachSeries(
        Object.keys(images),
        async (key, callback) => {
          console.log(images[key]);
          //   debugger;
          const url = await this._upload(awsPath, pole, images[key]);
          urls[key] = url;

          if (callback) callback();
        },
        err => {
          if (err) return reject(err);

          resolve(urls);
        }
      );
    });
  }

  uploadThermal(project, pole) {
    return new Promise((resolve, reject) => {
      const images = {
        full: this.fullRespath
      };

      const urls = {
        full: null,
        medium: null,
        thumb: null
      };
      console.log(project);
      var awsPath = `${slugify(project.section_id)}/${slugify(
        project.inspection_date
      )}`;
      console.log("aws path");
      console.log(awsPath);
      a.eachSeries(
        Object.keys(images),
        async (key, callback) => {
          const url = await this._upload(awsPath, pole, images[key]);
          urls[key] = url;

          if (callback) callback();
        },
        err => {
          if (err) return reject(err);

          resolve(urls);
        }
      );
    });
  }

  uploadProject(project, alldata) {
    return new Promise(async (resolve, reject) => {
      // const images = {
      //     full: this.fullRespath
      // }

      const grid = this.fullRespath;

      console.log(project);
      var awsPath = `${slugify(project.section_id)}/${slugify(
        project.inspection_date
      )}`;
      console.log("aws path");
      console.log(awsPath);
      // a.eachSeries(Object.keys(images), async (key, callback) => {
      const url = await this._uploadProject(
        awsPath,
        grid,
        alldata,
        project.section_id
      );
      //     urls[key] = url

      //     if (callback)
      //         callback()
      // }, err => {
      //     if (err)
      //         return reject(err)

      resolve(url);
      // })
    });
  }

  async _upload(dir, pole, image) {
    try {
      var datatosend = {
        Key: `${dir}/${slugify(pole.name)}_${path.basename(image)}`,
        image: image
      };
      var data = await API.post("image_upload", datatosend);
      return data.data.url;
    } catch (error) {
      throw error;
    }

    // const body = fs.readFileSync(image);
    // console.log(image);
    // const params = {
    //   Bucket: "tnb",
    //   Key: `${dir}/${slugify(pole.name)}_${path.basename(image)}`,
    //   Body: body,
    //   ACL: "public-read",
    //   ContentType: "image/jpeg"
    // };

    // this.s3.putObject(params, (err, data) => {
    //   if (err) return reject(err);

    //   resolve(`${this.secureEndpoint}/${params.Bucket}/${params.Key}`);
    // });
  }

  async _upload(dir, pole, image) {
    try {
      var datatosend = {
        Key: `${dir}/${slugify(pole.name)}_${path.basename(image)}`,
        image: image
      };
      var data = await API.post("image_upload", datatosend);
      return data.data.url;
    } catch (error) {
      throw error;
    }

    // const body = fs.readFileSync(image);
    // console.log(image);
    // const params = {
    //   Bucket: "tnb",
    //   Key: `${dir}/${slugify(pole.name)}_${path.basename(image)}`,
    //   Body: body,
    //   ACL: "public-read",
    //   ContentType: "image/jpeg"
    // };

    // this.s3.putObject(params, (err, data) => {
    //   if (err) return reject(err);

    //   resolve(`${this.secureEndpoint}/${params.Bucket}/${params.Key}`);
    // });
  }


  async _uploadImage(Key, body) {
    return new Promise((re, rx) => {
      let buffer = new Buffer(body.replace(/^data:image\/\w+;base64,/, ""), 'base64');
      const params = {
        Bucket: "tnb",
        Key: Key,
        Body: buffer,
        ACL: "public-read",
        ContentEncoding: 'base64',
        ContentType: "image/jpeg"
      };
      this.s3.putObject(params, (err, data) => {
        console.log(data);
        if (err) return rx(err);
        re(Key);
      });
    })
  }

  async _uploadProject(dir, project, alldata, section_id) {
    try {
      var datatosend = {
        sectionId: section_id,
        fileName: slugify(project),
        urlString: dir,
        gridData: alldata
      };
      var data = await AdminApi.post("jsonToGridFile", datatosend);
      debugger;
      return data.data.url;
    } catch (error) {
      throw error;
    }
  }
}
