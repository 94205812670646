<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container-small">
          <div class="modal-header">
            <slot name="header">
              <h5>Custom Defect</h5>
            </slot>
          </div>
          <div class="modal-body" style="height: calc(100% - 110px);overflow: hidden;">
            <div
              class="container-fluid"
              style="width:100%; position: absolute; top: 0px; z-index: 9999;"
            >
              <div class="alert alert-danger" v-if="errors && errors.length > 0">
                <span v-for="error in errors" :key="error">{{error}}</span>
              </div>
            </div>
            <div style="height: 100%; overflow: auto;">
              <div class="form-row">
                <div class="col-md-12">
                  <label class="col-form-label col-form-label-sm">Component</label>
                  <select
                    class="form-control form-control-sm"
                    style="width: 100%;"
                    v-model="selectedComponent"
                  >
                    <option :value="null" disabled>Select Component</option>
                    <option
                      v-for="component in components"
                      :key="component.name"
                      :value="component"
                    >{{component.name}}</option>
                  </select>
                </div>
                <div class="col-md-12">
                  <label class="col-form-label col-form-label-sm">Defect</label>
                  <!-- <textarea class="form-control form-control-sm" rows="3" v-model="dataToEdit"></textarea> -->
                  <input
                    type="Remark"
                    class="form-control form-control-sm"
                    v-model="insertedDefect"
                    placeholder="Defect"
                  />
                </div>
                <div class="col-md-12">
                  <label class="col-form-label col-form-label-sm">Severity</label>
                  <select
                    class="form-control form-control-sm"
                    style="width: 100%;"
                    v-model="selectedSeverity"
                  >
                    <option :value="null" disabled>Select Severity</option>
                    <option v-for="s in severity" :key="s.name" :value="s">{{s.name}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="container-fluid" style="width:100%; position: absolute; top: 0px;">
              <div
                class="alert alert-danger"
                v-if="isShowAlert"
              >Image source and destination is required</div>
            </div>
          </div>

          <slot name="footer">
            <div class="modal-button-at-bottom-right-small">
              <div style="    padding-top: 15px; border-top: 1px solid #e9ecef !important;">
                <button
                  class="btn btn-danger btn-sm"
                  :size="'sm'"
                  :variant="'danger'"
                  style="float:right;"
                  @click="close"
                >Cancel</button>
                <button class="btn btn-primary btn-sm mr-1" style="float:right;" @click="save">Save</button>
              </div>
              <!-- <button class="button is-cancel" @click="close"><i class="fas fa-save"></i>Cancel</button> -->

              <!-- <button class="button is-save" @click="save"><i class="fas fa-save"></i>Save</button> -->
            </div>
          </slot>
        </div>

        <!-- <div class="loading-bg" v-if="isLoading">
                    <div style="text-align: -webkit-center;" class="">
                        <img src="public/img/tnbloader.gif" class="loading-icon" alt="">
                        <h6 v-if="loadingMsg">{{loadingMsg}}</h6>
                        <span v-if="loadingSubMsg">{{loadingSubMsg}}</span>
                    </div>
        </div>-->
      </div>
    </div>
  </transition>
</template>

<script>
// import $ from 'jquery'
// import DB from '../db'
// const browserWindow = require('electron').remote.getCurrentWindow()
// const {
//     app,
//     dialog
// } = require('electron').remote
import moment from "moment";
export default {
  template: "#modal-template",
  components: {},
  data() {
    return {
      errors: [],
      isShowAlert: false,
      selectedComponent: null,
      components: [
        {
          name: "Others"
        },
        {
          name: "Non Compliance"
        }
      ],
      insertedDefect: null,
      selectedSeverity: null,
      severity: [
        {
          name: "Low"
        },
        {
          name: "Cautious"
        },
        {
          name: "Critical"
        }
      ]
    };
  },

  computed: {},

  watch: {},

  methods: {
    checkForm() {
      this.errors = [];
      if (
        this.selectedComponent &&
        this.selectedSeverity &&
        this.insertedDefect
      ) {
        return true;
      }

      if (!this.selectedComponent) {
        this.errors.push("Component required.");
      }

      if (!this.selectedSeverity) {
        this.errors.push("Severity required.");
      }
      if (!this.insertedDefect) {
        this.errors.push("Defect required.");
      }

      // if (!this.imagesPath) {
      //     this.errors.push('Images folder required.');
      // }
      return false;
    },
    save() {
      if (this.checkForm()) {
        console.log(`${this.selectedSeverity} ${this.selectedComponent}`);
        var duration =
          this.selectedSeverity.name.toLowerCase() == "low"
            ? "within 6 month"
            : this.selectedSeverity.name.toLowerCase() == "coutious"
            ? "within 3 month"
            : "Immediately";
        var recommendation = `Recommend for an engineering inspection to be carried out and advisable to repair ${duration}.`;
        var defect = {
          component: this.selectedComponent.name,
          name: this.insertedDefect,
          severity: this.selectedSeverity.name,
          phase: "UNKNOWN",
          remark: `${this.selectedComponent.name} ${this.insertedDefect} problem detected. `,
          recommendation: recommendation,
          created_at: moment().format("YYYY-MM-DD HH:mm:ss")
        };
        // this.isVisual ? defect.type = 'visual' : defect.type = 'thermal'
        this.$emit("save", defect);
      }
    },
    close() {
      this.$emit("close");
    }
  },

  mounted() {
    console.log("modal mounted");
  }
};
</script>

<style scoped src="@/../public/bootstrap/css/bootstrap.min.css"></style>
<style scoped src="@/../public/css/fontawesome-pro-5.1.1-web/css/all.css"></style>
<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  height: 100%;
  width: 80%;
  position: relative;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-container-small {
  /* height: 80%; */
  width: 60%;
  position: relative;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header {
  padding: 0 0 5px 0 !important;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 5px 0;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.modal-button-at-bottom-right-small {
  width: 100%;
  display: inline-table;
  padding-bottom: 15px;
  padding-left: 30px;
  padding-right: 30px;
}

.modal-button-at-bottom-right {
  width: 100%;
  display: inline-table;
  padding-bottom: 15px;
  padding-left: 30px;
  padding-right: 30px;
  position: absolute;
  bottom: 0px;
  left: 0px;
}

.modal-button-at-bottom-right .button.is-save {
  background: #08a86b !important;
  color: #fff;
  margin-right: 5px;
  float: right;
}

.modal-button-at-bottom-right .button.is-cancel {
  background: red !important;
  color: #fff;
  float: right;
}
</style>