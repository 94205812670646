<template>
  <!--
    Due to flow change the auto filter Query Droped, the following were commented
  -->
  <div id="defect-page">
    <v-container fluid>
      <!-- loader indeterminate -->
      <v-progress-circular
        v-show="loader"
        :size="150"
        :width="10"
        class="loader-indetermine"
        color="appcolor"
        indeterminate
      ></v-progress-circular>
      <!--
        poles auto filter dosen't work the pole list ui component show
      -->
      <!-- <defect-pole-filter
        v-if="!poleFound && !loader"
        :defectPoleFilterProps="defectPoleFilterProps"
        @selected_pole="poleSelected"
      ></defect-pole-filter>
      -->
      <!--
      defect marking component
      -->
      <defect-marking v-if="!loader"></defect-marking>
    </v-container>
  </div>
</template>

<script>
import Mixins from "@/mixin";
// import AdminAPI from "@/services/admin_api";

// import customDialog from "@/components/custom-dialog";
import defectMarking from "@/components/defect-marking";
// import defectPoleFilter from "@/components/defect-pole-filter";

export default {
  name: "defect-page",
  mixins: [Mixins],
  components: {
    defectMarking
    // defectPoleFilter
  },
  data() {
    return {
      loader: true,
      // toggle pole list or defect marking ui
      poleFound: false

      // // variable to hold query data from url
      // section: null,
      // inspection: null,
      // pole: null,
      // pole_name: null,

      // // to hold defect json from serve
      // defect_data: null,
      // filtered_data: null,
      // defectMarkingProps: null,
      // defectPoleFilterProps: []
    };
  },
  methods: {
    // // get querya data form url
    // getQueryData() {
    //   let _query = this.$route.query;
    //   this.section = _query.section;
    //   this.inspection = _query.inspection;
    //   this.pole = _query.pole;
    //   this.pole_name = _query.pole_name;
    // },
    // // Pole auto filter
    // filterPole() {
    //   return this.defect_data.poles.find(obj => obj.name == this.pole_name);
    // },
    // // filter pole based data
    // filterPoleData() {
    //   this.defectMarkingProps["defects"] = this.defect_data.defects.filter(
    //     obj => obj.pole_id == this.filtered_data.id
    //   );
    //   this.defectMarkingProps["images"] = this.defect_data.images.filter(
    //     obj => obj.pole_id == this.filtered_data.id
    //   );
    //   this.defectMarkingProps[
    //     "defects_images"
    //   ] = this.defect_data.defects_images.filter(
    //     obj => obj.defect_id == this.filtered_data.id
    //   );
    //   this.defectMarkingProps["defects"] = this.defect_data.defects.filter(
    //     obj => obj.pole_id == this.filtered_data.id
    //   );
    //   this.defectMarkingProps["defects"] = this.defect_data.defects.filter(
    //     obj => obj.pole_id == this.filtered_data.id
    //   );
    // },
    // // frame defect marking props
    // setDefectMarkingProps() {
    //   this.loader = true;
    //   this.defectMarkingProps["poles"] = this.filtered_data;
    //   this.defectMarkingProps["boxes"] = this.defect_data.boxes;
    //   this.defectMarkingProps["mongo_pole_id"] = this.pole;
    //   this.defectMarkingProps["section_id"] = this.section;
    //   this.defectMarkingProps["inspection_id"] = this.inspection;
    //   this.poleFound = true;
    //   this.loader = false;
    //   console.log(this.defectMarkingProps, this.defect_data);
    // },
    // // frame defect pole filter props
    // setDefectPoleFilterProps() {
    //   this.loader = true;
    //   this.defectPoleFilterProps = this.defect_data.poles;
    //   this.poleFound = false;
    //   this.loader = false;
    // },
    // // after pole selected form list
    // poleSelected(pole) {
    //   this.loader = true;
    //   this.filtered_data = pole;
    //   this.setDefectMarkingProps();
    // },
    // // fetch defect data from serve
    // async getDefectData() {
    //   let response = await AdminAPI.get(
    //     `inspectionGridDetails/${this.inspection}`
    //   );
    //   response = response.data.status ? response.data.gridData : null;
    //   return response;
    // }
  },
  async mounted() {
    this.loader = false;
    // await this.getQueryData();
    // this.defect_data = await this.getDefectData();
    // // this.loader = false;
    // if (this.defect_data == null) {
    //   this.alert("error", "Some unexcepted error, No Data Found");
    //   setTimeout(() => {
    //     window.close();
    //   }, 1500);
    //   return;
    // }
    // this.filtered_data = await this.filterPole();
    // if (this.filtered_data != null) this.setDefectMarkingProps();
    // else this.setDefectPoleFilterProps();
    // sessionStorage.setItem("defect", this.pole);
  }
};
</script>

<style scoped>
.loader-indetermine {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>

