<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container-small">
    
                    <div class="modal-header">
                        <slot name="header">
                            <h5>Edit {{type}}</h5>
                        </slot>
                    </div>
                    <div class="modal-body" style="height: calc(100% - 110px);overflow: hidden;">
                        <div class="container-fluid" style="width:100%; position: absolute; top: 0px; z-index: 9999;">
                            <div class="alert alert-danger" v-if="errors && errors.length > 0">
                                 <span v-for="error in errors" :key="error">{{error}} </span> 
                            </div>
                        </div>
                        <div style="height: 100%; overflow: auto;">
                            <div class="form-row">
                                <div class="col-md-12">
                                    <label class="col-form-label col-form-label-sm">{{type}}</label>
                                    <textarea class="form-control form-control-sm" rows="3" v-model="dataToEdit"></textarea>
                                    <!-- <input type="Remark" class="form-control form-control-sm" v-model="dataToEdit" placeholder="Remark"> -->
                                </div>
                            </div>
                        </div>
                        <div class="container-fluid" style="width:100%; position: absolute; top: 0px;">
                            <div class="alert alert-danger" v-if="isShowAlert">
                                 Image source and destination is required
                            </div>
                        </div>
                    </div>
    
                    <slot name="footer">
                        <div class="modal-button-at-bottom-right-small">
                            <div style="    padding-top: 15px; border-top: 1px solid #e9ecef !important;">
                                <button class="btn btn-danger btn-sm" :size="'sm'" :variant="'danger'" style="float:right;" @click="close">
                                    Cancel
                                </button>
                                <button class="btn btn-primary btn-sm mr-1" style="float:right;" @click="save">
                                    Save
                                </button>
                            </div>
                            <!-- <button class="button is-cancel" @click="close"><i class="fas fa-save"></i>Cancel</button> -->
    
                            <!-- <button class="button is-save" @click="save"><i class="fas fa-save"></i>Save</button> -->
                        </div>
                    </slot>
                </div>
    
                <!-- <div class="loading-bg" v-if="isLoading">
                    <div style="text-align: -webkit-center;" class="">
                        <img src="public/img/tnbloader.gif" class="loading-icon" alt="">
                        <h6 v-if="loadingMsg">{{loadingMsg}}</h6>
                        <span v-if="loadingSubMsg">{{loadingSubMsg}}</span>
                    </div>
                </div> -->
            </div>
        </div>
    </transition>
</template>

<script>
    import $ from 'jquery'
    // import DB from '../db'
    // const browserWindow = require('electron').remote.getCurrentWindow()
    // const {
    //     app,
    //     dialog
    // } = require('electron').remote
    export default {
        template: '#modal-template',
        components: {

        },
        props: ['type', 'defect'],
        data() {
            return {
                errors : [],
                isShowAlert: false,
                dataToEdit: null
            }
        },
    
        computed: {},
    
        watch: {
            
        },
    
        methods: {
            save(){
                console.log(this.dataToEdit)
                var data = {
                    type: this.type,
                    newData: this.dataToEdit
                }
                this.$emit('save',data);
            },
            close(){
                this.$emit('close');
            }
        },
    
        mounted() {
            console.log('modal mounted')
            console.log(this.type)
            console.log(this.defect)
            if(this.type.toLowerCase() == 'remark'){
                this.dataToEdit = this.defect.remark
            } else {
                this.dataToEdit = this.defect.recommendation
            }
           
        }
    
    };
</script>