export default class GenericObject {
	constructor(props) {
		this.id = props.id
		this.type = props.type
		this.name = props.name

		if (props.metadata)
			this.metadata = props.metadata

		if (props.refs)
			this.refs = props.refs

		if (props.created_at)
			this.created_at = props.created_at

		if (props.updated_at)
			this.updated_at = props.updated_at

		if (props.collections) {
			this.collections = props.collections
			
			this.collections.forEach((collection) => {
				collection.items = collection.items.map((item) => new GenericObject(item))
			})
		}
	}		

	getCollection(name) {
		const collection = this.collections.find((c) => c.name == name)	

		if (collection)
			return collection.items

		return []
	}

	addToCollection(name, obj) {
		this.getCollection(name).push(obj)
	}

	getMetadata(key) {
		const metadata = this.metadata.find((m) => m.key == key)

		if (metadata)
			return metadata.value

		return null
	}

	setMetadata(key, value) {
		let metadata = this.metadata.find((m) => m.key == key)

		if (metadata) {
			metadata.value = value
		}
		else {
			metadata = { 
				key: key, 
				value: value 
			}
			
			this.metadata.push(metadata)
		}			

		return metadata
	}

	static getRandomString() {
		let text = ''
		const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"

		for (var i = 0; i < 6; i++)
			text += possible.charAt(Math.floor(Math.random() * possible.length))	 

		return text
	}
}