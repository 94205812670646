import { render, staticRenderFns } from "./defect-marking.vue?vue&type=template&id=ef5e0628&scoped=true&"
import script from "./defect-marking.vue?vue&type=script&lang=js&"
export * from "./defect-marking.vue?vue&type=script&lang=js&"
import style0 from "@/../public/bootstrap/css/bootstrap.min.css?vue&type=style&index=0&id=ef5e0628&scoped=true&lang=css&"
import style1 from "@/../public/css/fontawesome-pro-5.1.1-web/css/all.css?vue&type=style&index=1&id=ef5e0628&scoped=true&lang=css&"
import style2 from "vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=2&lang=css&"
import style3 from "./defect-marking.vue?vue&type=style&index=3&id=ef5e0628&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ef5e0628",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VBtn})
