import { render, staticRenderFns } from "./defect_list_modal.vue?vue&type=template&id=6b63e542&scoped=true&"
import script from "./defect_list_modal.vue?vue&type=script&lang=js&"
export * from "./defect_list_modal.vue?vue&type=script&lang=js&"
import style0 from "@/../public/bootstrap/css/bootstrap.min.css?vue&type=style&index=0&id=6b63e542&scoped=true&lang=css&"
import style1 from "@/../public/css/fontawesome-pro-5.1.1-web/css/all.css?vue&type=style&index=1&id=6b63e542&scoped=true&lang=css&"
import style2 from "./defect_list_modal.vue?vue&type=style&index=2&id=6b63e542&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b63e542",
  null
  
)

export default component.exports